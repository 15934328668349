import { FC } from 'react';
import { TokenUsageResponseType } from '../../../models/balanceToken';
import { adaptData } from './adaptData';
import styled from 'styled-components';
import { Descriptions } from 'antd';

const Wrapper = styled.div`
  border-bottom: #dae6f2 1px solid;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 400px;
`;
const TypeWrapper = styled.span<{ type: string }>`
  font-size: 16px;
  font-weight: bold;
  color: ${({ type }) => (type === 'Top up' ? 'blue' : 'gray')};
  margin-bottom: 4px;
`;
interface Props {
  data: TokenUsageResponseType;
}
export const UsageItem: FC<Props> = ({ data }) => {
  const { tokenCount, type, date } = adaptData(data);
  return (
    <Wrapper>
      <TitleWrapper>
        <TypeWrapper type={type}>{type}</TypeWrapper>
        <span>{tokenCount}</span>
      </TitleWrapper>
      <Descriptions size={'small'} layout={'horizontal'} column={1}>
        <Descriptions.Item label={'created date'}>{date}</Descriptions.Item>
      </Descriptions>
    </Wrapper>
  );
};
