import { FC, useEffect, useState } from 'react';
import { Outlet, useBlocker } from 'react-router-dom';
import { toggleConfig } from '../providers/toggle-provider/config';

export const RouteGuard: FC = () => {
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    setRedirectUrl(nextLocation.pathname + nextLocation.search);
    const searchParams = new URLSearchParams(currentLocation.search);
    return toggleConfig.some((configItem) => searchParams.has(configItem.name));
  });

  const getPermanentParams = (search: string): string => {
    const searchParams = new URLSearchParams(search);
    let resultStr = '';
    searchParams.forEach((value, name) => {
      if (toggleConfig.find((configItem) => configItem.name === name)) {
        resultStr += `&${name}=${value}`;
      }
    });
    return resultStr;
  };

  const composeUrlWithParams = (origin: string, params: string): string => {
    return origin.includes('?')
      ? `${origin}${params}`
      : `${origin}${'?' + params.slice(1)}`;
  };

  useEffect(() => {
    if (blocker.state === 'blocked' && redirectUrl) {
      const permanentParams = getPermanentParams(window.location.search);
      const targetUrl = composeUrlWithParams(redirectUrl, permanentParams);
      blocker.reset();
      window.location.assign(targetUrl);
    }
  }, [blocker, redirectUrl]);
  return <Outlet />;
};
