import { client } from './client';
import { STATISTICS } from '../../const/apiPath';
import {
  GetLetterUsageMetricsResponseType,
  GetInvitedUserMetricsResponseType,
  getMetricsCommonInputType,
  GetPaidUserMetricResponseType,
  GetTopTenUsersMetricResponseType,
} from '../models';

export const getEngagementMetrics = async ({
  frequency,
  start,
  end,
}: getMetricsCommonInputType): Promise<GetPaidUserMetricResponseType> => {
  try {
    const { data } = await client.post(`${STATISTICS}/engagement`, {
      frequency,
      start,
      end,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTopTenUsersMetrics = async ({
  start,
  end,
}: Omit<
  getMetricsCommonInputType,
  'frequency'
>): Promise<GetTopTenUsersMetricResponseType> => {
  try {
    const { data } = await client.post(`${STATISTICS}/topTenUsers`, {
      start,
      end,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUsageMetrics = async ({
  frequency,
  start,
  end,
}: getMetricsCommonInputType): Promise<GetLetterUsageMetricsResponseType> => {
  try {
    const { data } = await client.post(`${STATISTICS}/usages`, {
      frequency,
      start,
      end,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getInvitedUsersMetrics = async ({
  frequency,
  start,
  end,
}: getMetricsCommonInputType): Promise<GetInvitedUserMetricsResponseType> => {
  try {
    const { data } = await client.post(`${STATISTICS}/invitedUsers`, {
      frequency,
      start,
      end,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
