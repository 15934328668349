import { FC } from 'react';
import { UploadFile } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
`;
interface Props {
  file: UploadFile;
  onDelete: (file: UploadFile) => void;
}
export const FileItem: FC<Props> = ({ file, onDelete }) => {
  return (
    <Wrapper>
      <span>{file.name}</span>
      <DeleteOutlined
        onClick={() => {
          onDelete(file);
        }}
      />
    </Wrapper>
  );
};
