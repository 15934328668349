import { FC, useState } from 'react';
import { Button, Form, FormInstance, Input, Select } from 'antd';
import {
  QualificationsList,
  QualificationType,
} from '../profile/qualification-list';
import { FooterWrapper, FormSectionWrapper, PageTitle } from './styled';
import { Speciality, specialtyOptions } from '../../../const/view';
import styled from 'styled-components';

const SpecialtySelectorWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

interface Props {
  form: FormInstance<unknown>;
  qualifications: QualificationType[];
  onQualificationChange: (qualifications: QualificationType[]) => void;
  goNext: () => void;
  goBack: () => void;
}
export const ProfessionalInfoPage: FC<Props> = ({
  form,
  qualifications,
  goBack,
  goNext,
  onQualificationChange,
}) => {
  const [specialty, setSpecialty] = useState<Speciality>();
  const handleValidate = async () => {
    try {
      await form.validateFields();
      goNext();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <PageTitle>Provide your professional information</PageTitle>
      <FormSectionWrapper>
        <Form.Item label={'Title'} name={'title'} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <SpecialtySelectorWrapper>
          <Form.Item
            label={'Speciality'}
            name={'speciality'}
            rules={[{ required: true }]}
            style={{ flex: 1 }}
          >
            <Select options={specialtyOptions} onSelect={setSpecialty} />
          </Form.Item>
          {specialty === Speciality.OTHER && (
            <Form.Item
              label={'What is your speciality?'}
              name={'specialityDescription'}
              rules={[{ required: true }]}
              style={{ flex: 4 }}
            >
              <Input
                placeholder={
                  "i.e. If you specialise in neurology, please write 'Neurologist'"
                }
              />
            </Form.Item>
          )}
        </SpecialtySelectorWrapper>

        <Form.Item
          label={'Provider number'}
          name={'providerId'}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <div>
          <QualificationsList
            qualifications={qualifications}
            onChange={onQualificationChange}
          />
        </div>
      </FormSectionWrapper>
      <FooterWrapper>
        <Button size={'large'} onClick={goBack}>
          Back
        </Button>
        <Button size={'large'} type={'primary'} onClick={handleValidate}>
          Next
        </Button>
      </FooterWrapper>
    </>
  );
};
