import React, { FC } from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';

const Wrapper = styled.div`
  h1,
  h2,
  h3,
  h4 {
    display: inline-block;
    width: 100%;
    color: #0552f0;
    font-weight: bolder;
  }
`;
const Container = styled.div`
  background-color: #f3f3f6;
  padding: 24px 10%;
`;
const Title = styled.h4`
  font-size: 22px;
  margin: 0 auto;
`;
const CenterTitle = styled.h4`
  font-size: 22px;
  margin: 0 auto;
  text-align: center;
`;
const Text = styled.div`
  color: #8385a9;
`;
const Section = styled.div`
  margin: 16px 0;
  color: #8385a9;
  ol li,
  ul li {
    margin: 16px 0;
    ol li {
      list-style-type: none;
    }
  }
`;
const SubSection = styled.div`
  margin: 16px 0;
`;
const SubOrderList = styled.ol`
  padding-left: 0;
  li {
    margin: 16px 0;
    list-style-type: none;
    &:before {
      content: attr(data-number) ' ';
    }
    li {
      margin: 0;
      list-style-type: none;
    }
  }
`;

export const TermsAndConditions: FC = () => {
  return (
    <Wrapper>
      <h1 style={{ textAlign: 'center' }}>
        AutoTranscribe Platform Terms and Conditions
      </h1>
      <Container>
        <Section>
          Welcome to AutoTranscribe! We provide a cloud-based platform
          (Platform) where you can automatically generate a draft letter based
          on audio or text files you input into the Platform.
        </Section>
        <Section>
          In these Terms, when we say you or your, we mean both you and any
          entity you are authorised to represent (such as your employer). When
          we say we, us, or our, we mean VESC Health Pty Ltd (ACN 668744054).
        </Section>
        <Section>
          These Terms form our contract with you, and set out our obligations as
          a service provider and your obligations as a customer. You cannot use
          our Services unless you agree to these Terms.{' '}
        </Section>
        <Section>
          Some capitalised words in these Terms have defined meanings, and each
          time that word is used in these Terms it has the same meaning. You can
          find a list of the defined words and their meaning at the end of these
          Terms.
        </Section>
        <Section>
          For questions about these Terms, or to get in touch with us, please
          email: jason.liu@telecare.com.au
        </Section>
        <Divider />
        <CenterTitle>DISCLAIMER</CenterTitle>
        <Section>
          AutoTranscribe is a software as a service platform whereby you may
          input notes you have taken during a consultation with a patient,
          whether in audio or text format, for the purposes of generating a
          clinical letter. Any notes you input in the Platform must be
          de-identified in accordance with the Privacy Act and Office of the
          Australian Information Commissioner (OAIC) guidelines. The notes
          should only contain information where there is no reasonable
          likelihood of re-identification occurring. Once your letter has been
          generated using our large-language-model API (LLM-API), you may insert
          the name of the patient before finalising the letter.
        </Section>
        <Section>
          By using the Platform, you acknowledge and agree that nothing on the
          Platform (and anything provided therein) may be taken to be medical
          advice, or advice about appropriate treatments, medications or
          prescriptions. We provide the Platform as a software as a service
          platform provider for the sole purposes of being an administrative
          support tool for healthcare professionals.
        </Section>
        <Section>
          We do not in any way seek to provide you confirmation that the letters
          generated by the Platform are accurate, correct or complete. You
          acknowledge and agree that it is your responsibility to verify, and
          where necessary, correct, any letters generated by the Platform.
        </Section>
        <Section>
          You acknowledge and agree that we use third party LLM-API to provide
          the Services and the LLM-API provider may be located overseas.
        </Section>
        <Divider />
        <CenterTitle>OUR DISCLOSURES</CenterTitle>
        <Section>
          Please read these Terms carefully before you accept. We draw your
          attention to the fact that:
          <ul>
            <li>
              we will handle your personal information in accordance with our
              privacy policy, available on our website;
            </li>
            <li>
              subject to your Consumer Law Rights:
              <ul>
                <li>
                  where our Services are not ordinarily acquired for personal,
                  domestic or household use or consumption) in respect of any
                  failure by us to comply with relevant Consumer Law Rights, our
                  Liability is limited (at our discretion) to supplying the
                  Services again or paying the cost of having the Services
                  supplied again;
                </li>
                <li>
                  our aggregate liability to you for any Liability arising from
                  or in connection with these Terms will be limited to the fees
                  paid by you for the Services;
                </li>
                <li>
                  we will not be liable for Consequential Loss or delays or
                  failures in performance caused or contributed to by an event
                  or circumstance beyond our reasonable control;
                </li>
              </ul>
            </li>
            <li>
              we may amend these Terms at any time, by providing written notice
              to you, and by clicking “I accept” or continuing to use our
              Platform after the notice or 30 days after notification (whichever
              date is earlier), you agree to the amended Terms. If you do not
              agree to the amendment, you may close your Account with effect
              from the date of the change in these Terms by providing written
              notice to us;
            </li>
            <li>
              you are liable for, and agree to indemnify us and hold us harmless
              in respect of any Liability that we may suffer, incur or otherwise
              become liable for arising from or in connection with your breach
              of the warranties or privacy clause, or your failure to verify the
              accuracy of the consultation notes generated by the Platform;{' '}
            </li>
            <li>
              where you engage third parties to operate alongside our Services
              (for example, any third-party software systems you wish to
              integrate with our Platform), those third parties are independent
              of us and you are responsible for (meaning we will not be liable
              for) the goods or services they provide, unless we expressly agree
              otherwise under clause 2.3; and
            </li>
            <li>
              we may receive a benefit (which may include a referral fee or a
              commission) should you visit certain third party websites through
              a link on our Platform, or for featuring certain goods or services
              on our Platform.
            </li>
          </ul>
        </Section>
        <Section>
          These Terms do not intend to limit your rights and remedies at law,
          including any of your Consumer Law Rights.{' '}
        </Section>
        <Divider />
        <Section>
          <Title>1. Engagement and Term </Title>
          <SubOrderList>
            <li data-number={'1.1'}>
              These Terms apply from when you sign up for an Account, until the
              date on which your Account is terminated in accordance with these
              Terms. We grant you a right to use our Services for this period of
              time only (which may be suspended or revoked in accordance with
              these Terms).
            </li>
            <li data-number={'1.2'}>
              To use our Platform, you must:
              <Text>(A) be at least 18 years old; and</Text>
              <Text>
                (B) have a current and valid registration with AHPRA.{' '}
              </Text>
            </li>
            <li data-number={'1.3'}>
              Variations: We may amend these Terms at any time, by providing
              written notice to you. By clicking “I accept” or continuing to use
              our Platform after the notice or 30 days after notification
              (whichever date is earlier), you agree to the amended Terms. If
              you do not agree to the amendment, you may close your Account with
              effect from the date of the change in these Terms by providing
              written notice to us. If you close your Account, you will no
              longer be able to access our Services (including our Platform) on
              and from the date of cancellation.
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>2. Our Services</Title>
          <SubOrderList>
            <li data-number={'2.1'}>
              We provide the following services to you:
              <Text>(A) ccess to our Platform (the Services).</Text>
            </li>
            <li data-number={'2.2'}>
              We will not be responsible for any other services unless expressly
              set out on in these Terms or on our Platform.
            </li>
            <li data-number={'2.3'}>
              Additional Services: If you require additional services, we may,
              in our sole discretion, provide such additional services (to be
              scoped and priced in a separate contract provided by us).
            </li>
            <li data-number={'2.4'}>
              hird Party Products or Services: Where you engage third parties to
              operate alongside our Services (for example, any third-party
              software systems you wish to integrate with our Platform), those
              third parties are independent of us and you are responsible for
              (meaning we will not be liable for) the goods or services they
              provide, unless we expressly agree otherwise under clause 2.3.
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>3. Account</Title>
          <SubOrderList>
            <li data-number={'3.1'}>
              You must sign up for an Account in order to access and use our
              Platform.
            </li>
            <li data-number={'3.2'}>
              You may invite Authorised Users to access and use our Services
              under your Account. Each of your Authorised Users will require a
              login (which is linked to your Account), in order to access and
              use our Platform. You are responsible for ensuring that your
              Authorised Users comply with these Terms. You may change who your
              Authorised Users are at any time through your Account, and what
              access rights or permissions they have when using our Platform.
              Any limitations on the number of Authorised Users you can have
              will be set out in your Account or on our Platform.
            </li>
            <li data-number={'3.3'}>
              While you have an Account with us, you agree to (and to ensure
              your Authorised Users agree to):
              <Text>
                (A) keep your information up-to-date (and ensure it remains
                true, accurate and complete);
              </Text>
              <Text>
                (B) keep usernames and passwords secure and confidential, and
                protect them from misuse or being stolen; and
              </Text>
              <Text>
                (C) notify us if you become aware of, or have reason to suspect,
                any unauthorised access to your Account or any logins linked to
                your Account.
              </Text>
            </li>
            <li data-number={'3.4'}>
              We may suspend your access to our Services where we reasonably
              believe there has been any unauthorised access to or use of our
              Services (such as the unauthorised sharing of login details for
              our Platform). If we suspend your access to our Services, we will
              let you know within a reasonable time of doing so, and we will
              work with you to resolve the matter, or if it cannot be resolved,
              then we may terminate your Account and your access to our Services
              will end.
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>4. Payments</Title>
          <SubOrderList>
            <li data-number={'4.1'}>
              You must pay the fee for the Services as set out on the Platform.
            </li>
            <li data-number={'4.2'}>
              You must not pay, or attempt to pay, any fees due under these
              Terms or as a result of your use of our Services by fraudulent or
              unlawful means. If you make payment by debit or credit card, you
              must be the authorised card holder. If payment is made by direct
              debit, by providing your bank account details and accepting these
              Terms, you authorise our nominated third-party payment processor
              to debit your bank account, and you confirm that you are either
              the holder or an authorised signatory of that bank account.
            </li>
            <li data-number={'4.3'}>
              Late Payments: If any fees due under these Terms or as a result of
              your use of our Services are not paid on time, we may:
              <Text>
                (A) uspend your access to our Services (including access to our
                Platform); and
              </Text>
              <Text>
                (B) charge interest on any overdue payments at a rate equal to
                the Reserve Bank of Australia’s cash rate, from time-to-time,
                plus 2% per annum, calculated daily and compounding monthly.
              </Text>
            </li>
            <li data-number={'4.4'}>
              Taxes: You are responsible for paying any levies or taxes
              associated with your use of our Services, for example sales taxes,
              value-added taxes or withholding taxes (unless we are required by
              law to collect these on your behalf).
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>5. Platform Licence</Title>
          <SubOrderList>
            <li data-number={'5.1'}>
              While you have an Account, we grant you and your Authorised Users
              a right to use our Platform (which may be suspended or revoked in
              accordance with these Terms). This right cannot be passed on or
              transferred to any other person.
            </li>
            <li data-number={'5.2'}>
              You must not (and you must ensure that your Authorised Users do
              not):
              <Text>
                (A) nput any audio or text files onto the Platform that have not
                been de-identified in accordance with the Privacy Act and OAIC
                guidelines. The notes should only contain information where
                there is no reasonable likelihood of re-identification
                occurring;
              </Text>
              <Text>
                (B) access or use our Platform in any way that is improper or
                breaches any laws, infringes any person's rights (for example,
                intellectual property rights and privacy rights), or gives rise
                to any civil or criminal liability;
              </Text>
              <Text>
                (C) nterfere with or interrupt the supply of our Platform, or
                any other person’s access to or use of our Platform;
              </Text>
              <Text>
                (D) introduce any viruses or other malicious software code into
                our Platform;
              </Text>
              <Text>
                (E) use any unauthorised or modified version of our Platform,
                including but not limited to for the purpose of building similar
                or competitive software or for the purpose of obtaining
                unauthorised access to our Platform;
              </Text>
              <Text>
                (F) attempt to access any data or log into any server or account
                that you are not expressly authorised to access;
              </Text>
              <Text>
                (G) use our Platform in any way that involves service bureau
                use, outsourcing, renting, reselling, sublicensing, concurrent
                use of a single user login, or time-sharing;
              </Text>
              <Text>
                (H) circumvent user authentication or security of any of our
                networks, accounts or hosts or those of any third party; or
              </Text>
              <Text>
                (I) access or use our Platform to transmit, publish or
                communicate material that is, defamatory, offensive, abusive,
                indecent, menacing, harassing or unwanted.
              </Text>
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>6. Availability, Disruption and Downtime</Title>
          <SubOrderList>
            <li data-number={'6.1'}>
              While we strive to always make our Services available to you, we
              do not make any promises that these will be available 100% of the
              time. Our Services may be disrupted during certain periods,
              including, for example, as a result of scheduled or emergency
              maintenance.
            </li>
            <li data-number={'6.2'}>
              Our Services (including our Platform) may interact with, or be
              reliant on, products or services provided by third parties, such
              as cloud hosting service providers. To the maximum extent
              permitted by law, we are not liable for disruptions or downtime
              caused or contributed to by these third parties.
            </li>
            <li data-number={'6.3'}>
              We will try to provide you with reasonable notice, where possible,
              of any disruptions to your access to our Services.
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>7. Intellectual Property and Data</Title>
          <SubOrderList>
            <li data-number={'7.1'}>
              We own all intellectual property rights in our Services (including
              our Platform). This includes how our Platform looks and functions,
              as well as our copyrighted works, trademarks, inventions, designs
              and other intellectual property. You agree not to copy or
              otherwise misuse our intellectual property without our written
              permission (for example, to reverse engineer or discover the
              source code of our intellectual property), and you must not alter
              or remove any confidentiality, copyright or other ownership notice
              placed on our Platform.
            </li>
            <li data-number={'7.2'}>
              We may use any feedback or suggestions that you give us in any
              manner which we see fit (for example, to develop new features),
              and no benefit will be owed to you as a result of any use by us of
              your feedback or suggestions.
            </li>
            <li data-number={'7.3'}>
              We do not own any of Your Data, but when you enter or upload any
              of Your Data into our Platform, you grant us the right to access,
              analyse, backup, copy, store, transmit, and otherwise use Your
              Data while you have an Account with us (and for a reasonable
              period of time afterwards). We may use Your Data (or disclose it
              to third party service providers) to:
              <Text>
                (A) supply our Services to you and your Authorised Users (for
                example, to enable you and your Authorised Users to access and
                use our Services), and otherwise perform our obligations under
                these Terms;
              </Text>
              <Text>(B) diagnose problems with our Services;</Text>
              <Text>(C) improve, develop and protect our Services;</Text>
              <Text>
                (D) send you information we think may be of interest to you
                based on your marketing preferences;
              </Text>
              <Text>
                (E) perform analytics for the purpose of remedying bugs or
                issues with our Platform; or
              </Text>
              <Text>
                (F) perform our obligations under these Terms (as reasonably
                required).
              </Text>
            </li>
            <li data-number={'7.4'}>
              You acknowledge and agree that because of the nature of the
              internet, the processing and transmission of Your Data by us may
              occur over various networks, and may be transferred unencrypted.
            </li>
            <li data-number={'7.5'}>
              You are responsible for (meaning we are not liable for):
              <Text>
                (A) the integrity of Your Data on your systems, networks or any
                device controlled by you or your Authorised Users; and
              </Text>
              <Text>(B) backing up Your Data.</Text>
            </li>
            <li data-number={'7.6'}>
              When you use our Services, we may create anonymised statistical
              data from Your Data and usage of our Services (for example,
              through aggregation). Once anonymised, we own that data and may
              use it for our own purposes, such as to provide and improve our
              Services, to develop new services or product offerings, to
              identify business trends, and for other uses we communicate to
              you. This may include making such anonymised data publicly
              available, provided it is not compiled using a sample size small
              enough to make underlying portions of Your Data identifiable.
            </li>
            <li data-number={'7.7'}>
              If you do not provide Your Data to us, it may impact your ability
              to receive our Services.
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>8. Warranties</Title>
          <SubOrderList>
            <li data-number={'8.1'}>
              You represent, warrant and agree that:
              <Text>
                (A) you will comply with all applicable codes, laws, standards
                and regulations, including but not limited to:
                <ol>
                  <li data-number={'(1)'}>
                    the Health Practitioner National Law;
                  </li>
                  <li data-number={'(2)'}>
                    the Medical Board of Australia Code of Conduct; and
                  </li>
                  <li data-number={'(3)'}>
                    any relevant State and Territory Health Records Acts;
                  </li>
                </ol>
              </Text>
              <Text>
                (B) you hold all necessary licenses with relevant regulatory
                boards and/or authorities necessary to enable you to legally
                provide health advice to patients;
              </Text>
              <Text>
                (C) you will promptly notify us of any changes to any of your
                Professional Registration Details;
              </Text>
              <Text>
                (D) you will maintain all insurance policies which a reasonably
                prudent healthcare professional in your position would maintain,
                including but not limited to professional indemnity cover and
                public liability insurances;
              </Text>
              <Text>
                (E) you are solely responsible for the de-identification of any
                audio or text files inputting by you onto the Platform;
              </Text>
              <Text>
                (F) you are solely responsible for verifying the accuracy of any
                letters generated by the Platform; and
              </Text>
              <Text>
                (G) you are solely responsible for obtaining your patient’s
                written informed consent to use the Platform.
              </Text>
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>9. Confidential Information</Title>
          <SubOrderList>
            <li data-number={'9.1'}>
              While using our Services, you may share confidential information
              with us, and you may become aware of confidential information
              about us. You agree not to use our confidential information, and
              to take reasonable steps to protect our confidential information
              from being disclosed without our permission, and we agree to do
              the same for your confidential information. This also means making
              sure that any Authorised Users, employees, contractors,
              professional advisors or agents of ours or yours only have access
              to confidential information on a ‘need-to-know basis’ (in other
              words, the disclosure is absolutely necessary), and that they also
              agree to not misuse or disclose such confidential information.
            </li>
            <li data-number={'9.2'}>
              However, either you or we may share confidential information with
              legal or regulatory authorities if required by law to do so.
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>10. Privacy</Title>
          <SubOrderList>
            <li data-number={'10.1'}>
              We collect, hold and disclose and use any Personal Information you
              provide to us in accordance with our privacy policy, available on
              our website, and applicable privacy laws.
            </li>
            <li data-number={'10.2'}>
              You must only disclose Personal Information to us if you have the
              right to do so (such as having the individual’s express, written
              informed consent).
            </li>
            <li data-number={'10.3'}>
              You acknowledge and agree that the consultation notes you input
              into the Platform will be processed by a third party LLM-API
              provider that may be located overseas.
            </li>
            <li data-number={'10.4'}>
              Each Party agrees to comply with the legal requirements of the
              Australian Privacy Principles as set out in the Privacy Act 1988
              (Cth) (Privacy Act) (as if it were an “APP entity” as defined in
              the Privacy Act) and any other applicable Privacy Laws that may
              apply to the use of the Platform. Each Party must not (and must
              procure that its personnel do not) do anything which may cause the
              other Party to be in breach of Privacy Laws.
            </li>
            <li data-number={'10.5'}>
              With respect to any Personal Information that you have access to,
              or share with us (including via the Platform), you must:
              <Text>
                (A) obtain valid, written informed consent from your patient to
                use the Platform during your consultation, including informing
                your patients that the Platform will store the patient’s
                consultation details;
              </Text>
              <Text>
                (B) only use the Personal Information in accordance with your
                patient’s instructions and for the sole purpose for which it was
                disclosed;
              </Text>
              <Text>
                (C) at all times comply with your professional obligations with
                respect to the Personal Information of patients that you receive
                and share; and
              </Text>
              <Text>
                (D) no later than 1 Business Day upon our request, provide us
                with evidence to satisfy your compliance with this clause 10.5.
              </Text>
            </li>
            <li data-number={'10.6'}>
              We may need to disclose Personal Information to third parties,
              such as our related companies or our service providers (for
              example, IT and administrative service providers and our
              professional advisors).
            </li>
            <li data-number={'10.7'}>
              In respect of any Security Incident, you must (and procure that
              your personnel must):
              <Text>
                (A) notify us within 2 business days of becoming aware of the
                Security Incident;
              </Text>
              <Text>(B) comply with your obligations under Privacy Laws;</Text>
              <Text>
                (C) provide us with all information we reasonably request;
              </Text>
              <Text>
                (D) assist and fully cooperate with us, at your cost, in
                investigating and remedying the Security Incident; and
              </Text>
              <Text>
                (E) take any other action, at your cost, that we reasonably deem
                necessary in connection with the Security Incident.
              </Text>
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>11. Consumer Law Rights</Title>
          <SubOrderList>
            <li data-number={'11.1'}>
              In some jurisdictions, you may have guarantees, rights or other
              remedies provided by law (Consumer Law Rights), and these Terms do
              not restrict your Consumer Law Rights. We will only be bound by
              your Consumer Law Rights and the express wording of these Terms.
              Our liability for a breach of your Consumer Law Rights is, unless
              the laws of your jurisdiction state otherwise, limited to either
              resupplying our Services, or paying the cost of having our
              Services resupplied.
            </li>
            <li data-number={'11.2'}>
              Subject to your Consumer Law Rights, we do not provide a refund
              for a change of mind or change in circumstance.
            </li>
            <li data-number={'11.3'}>
              If you accept these Terms in Australia, nothing in these Terms
              should be interpreted to exclude, restrict or modify the
              application of, or any rights or remedies you may have under, any
              part of the Australian Consumer Law (as set out in Schedule 2 of
              the Competition and Consumer Act 2010 (Cth)).
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>12. Liability</Title>
          <SubOrderList>
            <li data-number={'12.1'}>
              To the maximum extent permitted by law, we will not be liable for,
              and you release us from liability for, any Liability caused or
              contributed to by, arising from or in connection with:
              <Text>
                (A) your computing environment (for example, your hardware,
                software, information technology and telecommunications services
                and systems); or
              </Text>
              <Text>
                (B) any use of our Services by a person or entity other than you
                or your Authorised Users;
              </Text>
              <Text>
                (C) your failure to verify the accuracy of any letter generated
                by the Platform; or
              </Text>
              <Text>
                (D) your failure to obtain consent to share a patient’s Personal
                Information with us, including via the Platform.
              </Text>
            </li>
            <li data-number={'12.2'}>
              Regardless of whatever else is stated in these Terms, to the
              maximum extent permitted by law:
              <Text>
                (A) neither we or you are liable for any Consequential Loss;
              </Text>
              <Text>
                (B) a party’s liability for any Liability under these Terms will
                be reduced proportionately to the extent the relevant Liability
                was caused or contributed to by the actions (or inactions) of
                the other party, including any failure by the other party to
                mitigate its loss;
              </Text>
              <Text>
                (C) (where our Services are not ordinarily acquired for
                personal, domestic or household use or consumption) in respect
                of any failure by us to comply with relevant Consumer Law
                Rights, our Liability is limited (at our discretion) to
                supplying the Services again or paying the cost of having the
                Services supplied again; and
              </Text>
              <Text>
                (D) our aggregate liability to you for any Liability arising
                from or in connection with these Terms will be limited to
                AU$1,000.
              </Text>
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>13. Indemnity</Title>
          <SubOrderList>
            <li data-number={'13.1'}>
              Despite anything to the contrary, to the maximum extent permitted
              by law, you are liable for, and agree to indemnify us and hold us
              harmless in respect of any Liability that we may suffer, incur or
              otherwise become liable for, arising from or in connection with:
              <Text>
                (A) any breach by you or your personnel of clauses 8, 9 or 10;
              </Text>
              <Text>
                (B) any claim by a patient that their personal information has
                been inputted into the Platform;
              </Text>
              <Text>
                (C) your failure to verify the accuracy of the letters generated
                by the Platform.
              </Text>
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>14. Termination</Title>
          <SubOrderList>
            <li data-number={'14.1'}>
              We may terminate these Terms (meaning you will lose access to our
              Services, including access to your Account) if:
              <Text>
                (A) you or your Authorised Users breach these Terms and do not
                remedy that breach within 14 days of us notifying you of that
                breach;
              </Text>
              <Text>
                (B) you or your Authorised Users breach these Terms and that
                breach cannot be remedied; or
              </Text>
              <Text>
                (C) you experience an insolvency event (including but not
                limited to bankruptcy, receivership, voluntary administration,
                liquidation, or entering into creditors’ schemes of
                arrangement).
              </Text>
            </li>
            <li data-number={'14.2'}>
              You may terminate these Terms if:
              <Text>
                (A) we breach these Terms and do not remedy that breach within
                14 days of you notifying us of that breach; or
              </Text>
              <Text>
                (B) we breach these Terms and that breach cannot be remedied.
              </Text>
            </li>
            <li data-number={'14.3'}>
              You may also terminate these Terms at any time by notifying us
              through your Account or to our email for notices (as set out in
              clause 15.8), and termination will take effect immediately.
            </li>
            <li data-number={'14.4'}>
              Upon termination of these Terms, we will retain Your Data
              (including copies) as required by law or regulatory requirements.
            </li>
            <li data-number={'14.5'}>
              Termination of these Terms will not affect any other rights or
              liabilities that we or you may have.
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>15. General</Title>
          <SubOrderList>
            <li data-number={'15.1'}>
              Assignment: You may not transfer or assign these Terms (including
              any benefits or obligations you have under these Terms) to any
              third party without our prior written consent. We may assign or
              transfer these Terms to a third party, or transfer any debt owed
              by you to us to a debt collector or other third party.
            </li>
            <li data-number={'15.2'}>
              Disputes: Neither we or you may commence court proceedings
              relating to any dispute, controversy or claim arising from, or in
              connection with, these Terms (including any question regarding its
              existence, validity or termination) (Dispute) unless we and you
              first meet (in good faith) to resolve the Dispute. Nothing in this
              clause will operate to prevent us or you from seeking urgent
              injunctive or equitable relief from a court of appropriate
              jurisdiction. If the Dispute is not resolved at that initial
              meeting:
              <Text>
                (A) where you are resident or incorporated in Australia, refer
                the matter to mediation, administered by the Australian Disputes
                Centre in accordance with Australian Disputes Centre Guidelines
                for Commercial Mediation; or
              </Text>
              <Text>
                (B) where you are not resident or incorporated in Australia,
                refer the matter to arbitration administered by the Australian
                Centre for International Commercial Arbitration, with such
                arbitration to be conducted in Melbourne, Victoria, before one
                arbitrator, in English and in accordance with the ACICA
                Arbitration Rules.
              </Text>
            </li>
            <li data-number={'15.3'}>
              Events Outside Our Control: We will not be liable for any delay or
              failure to perform our obligations (including our Services), if
              such delay or failure is caused or contributed to by an event or
              circumstance beyond our reasonable control.
            </li>
            <li data-number={'15.4'}>
              Governing law: These Terms are governed by the laws of Victoria,
              and any matter relating to these Terms is to be determined
              exclusively by the courts in Victoria and any courts entitled to
              hear appeals from those courts.
            </li>
            <li data-number={'15.5'}>
              Illegal Requests: We reserve the right to refuse any request for
              or in relation to our Services that we deem inappropriate,
              unethical, unreasonable, illegal or otherwise non-compliant with
              these Terms.
            </li>
            <li data-number={'15.6'}>
              Marketing: You agree that we may send you electronic
              communications about our products and services. You may opt-out at
              any time by using the unsubscribe function in our electronic
              communications.
            </li>
            <li data-number={'15.7'}>
              Nature of Legal Relationship: These Terms do not create, and
              should not be interpreted so as to create, a partnership, joint
              venture, employment or agency relationship between us and you.
            </li>
            <li data-number={'15.8'}>
              Notices: Any notice you send to us must be sent to the email set
              out at the beginning of these Terms. Any notice we send to you
              will be sent to the email address registered against your Account.
            </li>
            <li data-number={'15.9'}>
              Survival: Clauses 7 to 13 will survive the termination or expiry
              of these Terms.
            </li>
            <li data-number={'15.10'}>
              Third Party Sites: Our Platform may contain links to websites
              operated by third parties. Unless we tell you otherwise, we do not
              control, endorse or approve, and are not responsible for, the
              content on those websites. We recommend that you make your own
              investigations in relation to the suitability of those websites.
              If you purchase goods or services from a third party website
              linked from our Platform, those goods or services are being
              provided by that third party, not us. We may receive a benefit
              (which may include a referral fee or a commission) should you
              visit certain third party websites through a link on our Platform,
              or for featuring certain goods or services on our Platform. We
              will make it clear by notice to you which (if any) goods or
              services, or website links, we receive a benefit from by featuring
              them on our Platform.
            </li>
          </SubOrderList>
        </Section>
        <Section>
          <Title>16. Definitions</Title>
          <SubOrderList>
            <li data-number={'16.1'}>
              In these Terms:
              <SubSection>
                Account means an account accessible to the individual or entity
                who signed up to our Services, under which Authorised Users may
                be granted with access.
              </SubSection>
              <SubSection>
                AHPRA means the Australian Health Practitioner Regulation
                Agency.
              </SubSection>
              <SubSection>
                Consequential Loss includes any consequential loss, special or
                indirect loss, real or anticipated loss of profit, loss of
                benefit, loss of revenue, loss of business, loss of goodwill,
                loss of opportunity, loss of savings, loss of reputation, loss
                of use and/or loss or corruption of data, whether under statute,
                contract, equity, tort (including negligence), indemnity or
                otherwise. However, your obligation to pay us any amounts for
                access to or use of our Services (including our Platform) will
                not constitute “Consequential Loss”.
              </SubSection>
              <SubSection>
                Health Practitioner National Law means the legislation that was
                enacted in each state and territory of Australia, being the
                Health Practitioner Regulation National Law (Victoria) Act 2009
                in Victoria.
              </SubSection>
              <SubSection>
                Health Records Act means the relevant state and territory
                legislation which establishes a framework to protect the privacy
                of individuals’ health information, being the Health Records Act
                2001 in Victoria.
              </SubSection>
              <SubSection>
                Liability means any expense, cost, liability, loss, damage,
                claim, notice, entitlement, investigation, demand, proceeding or
                judgment (whether under statute, contract, equity, tort
                (including negligence), indemnity or otherwise), howsoever
                arising, whether direct or indirect and/or whether present,
                unascertained, future or contingent and whether involving a
                third party or us or you or otherwise.
              </SubSection>
              <SubSection>
                Medical Board of Australia Code of Conduct means the code of
                conduct established by the Australian Medical Association which
                establishes the professional standards of doctors.
              </SubSection>
              <SubSection>
                Personal Information means any information or opinion about an
                identified individual, or an individual who is reasonably
                identifiable, whether the information or opinion is true or not,
                and whether the information or opinion is recorded in a tangible
                form or not.
              </SubSection>
              <SubSection>
                Platform means our cloud-based platform that we provide you with
                access to as part of the Services.
              </SubSection>
              <SubSection>
                Privacy Laws means the Privacy Act 1988 (Cth) and any other
                applicable laws relating to privacy.
              </SubSection>
              <SubSection>
                Security Incident means the actual or likely occurrence of any
                of the following in respect of the Personal information that you
                (or your personnel) receive or have access to in connection with
                this Agreement, or otherwise share on the Platform:
                <Text>
                  (A) a breach by you (or your personnel) of clause 10.4 or
                  10.5;
                </Text>
                <Text>
                  (B) an ‘eligible data breach’ (as that term is defined in the
                  Privacy Act) caused or contributed to by you (or your
                  personnel).
                </Text>
              </SubSection>
              <SubSection>
                Services means the services we provide to you, as detailed in
                clause 2.1.
              </SubSection>
              <SubSection>
                Your Data means the information, materials, logos, documents,
                qualifications and other intellectual property or data supplied
                by you and your Authorised Users when receiving our Services or
                stored by or generated by your use of our Services, including
                any Personal Information collected, used, disclosed, stored or
                otherwise handled in connection with our Services. Your Data
                does not include any data or information that is generated as a
                result of your usage of our Services that is a back-end or
                internal output or an output otherwise generally not available
                to users of our Services.
              </SubSection>
            </li>
          </SubOrderList>
        </Section>
      </Container>
    </Wrapper>
  );
};
