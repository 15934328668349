import { letterResponseType } from '../../../models';

type GpInfoCardViewType = {
  title: string;
  name: string;
  email: string;
  clinicName: string;
  address: string;
} | null;
export const adaptData = (data?: letterResponseType): GpInfoCardViewType => {
  if (data?.gp) {
    const title =
      data.letter.letterType === 'REFERRAL_LETTER' ? 'Referral' : 'GP';
    return {
      title,
      name: data.gp.firstName + ' ' + data.gp.lastName,
      email: data.gp.email,
      clinicName: data.gp.clinicName,
      address: data.gp.addressLine1 + ' ' + data.gp.addressLine2,
    };
  }
  return null;
};
