import { FC, useState } from 'react';
import { Form, message, Steps } from 'antd';
import { UserRole } from '../../../models';
import { QualificationType } from '../../profile/qualification-list';
import { FormPageWrapper, VisibilityWrapper } from '../styled';
import { RoleSelectionPage } from '../RoleSelectionPage';
import { PersonalInfoPage } from '../PersonalInfoPage';
import { ProfessionalInfoPage } from '../ProfessionalPageInfo';
import { ReferralCodePage } from '../ReferralCodePage';
import { PrivacyPolicyPage } from '../PrivacyPolicyPage';
import { TermsAndConditionsPage } from '../TermsAndConditionsPage';
import { PracticeInfoPage } from '../PracticeInfoPage';
import { createOrg } from '../../../client';
import { transformCreateUserInputData } from './adaptData';
import { createUser } from '../../../client/user';

const basicStepItems = [
  { title: 'Practice Info' },
  { title: 'Role Selection' },
  { title: 'Personal Info' },
  { title: 'Referral Code' },
  { title: 'Privacy Agreement' },
  { title: 'T&Cs' },
];
const wholeStepItems = [
  { title: 'Privacy Agreement' },
  { title: 'T&Cs' },
  { title: 'Practice Info' },
  { title: 'Personal Info' },
  { title: 'Professional Info' },
  { title: 'Referral Code' },
];
interface Props {
  afterSubmit?: () => void;
  onExit: () => void;
}
export const PracticeRegisterForm: FC<Props> = ({ afterSubmit, onExit }) => {
  const [step, setStep] = useState<number>(0);
  const [formStep, setFormStep] = useState<number>(0);
  const [stepItems, setStepItems] = useState(wholeStepItems);
  const [qualifications, setQualifications] = useState<QualificationType[]>([
    { id: 0, value: '' },
  ]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [orgForm] = Form.useForm();
  const formRoles = Form.useWatch('roles', form) ?? [];
  const generateStepItemsByRole = (role: UserRole) => {
    const updatedStepItems =
      role === UserRole.DOCTOR ? wholeStepItems : basicStepItems;
    setStepItems(updatedStepItems);
  };
  const handleGoNextPage = () => {
    setStep((currentStep) => currentStep + 1);
  };
  const handleGoPreviousPage = () => {
    setStep((currentStep) => currentStep - 1);
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    const orgData = orgForm.getFieldsValue(true);
    const userFormData = form.getFieldsValue(true);
    try {
      const organizationId = await createOrg(orgData);
      const userData = transformCreateUserInputData({
        data: userFormData,
        qualifications,
        organizationId: organizationId,
      });
      await createUser(userData);
      messageApi.success('Submit succeed, redirecting to patients page.');
      afterSubmit && afterSubmit();
    } catch {
      messageApi.error(
        'Something went wrong, please resubmit the survey or refresh the page.'
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <FormPageWrapper>
      {contextHolder}
      <Steps current={step} items={stepItems} style={{ marginBottom: 40 }} />
      <VisibilityWrapper isVisible={step === 0}>
        <PrivacyPolicyPage
          showBackButton
          goNext={handleGoNextPage}
          goBack={onExit}
        />
      </VisibilityWrapper>
      <VisibilityWrapper isVisible={step === 1}>
        <TermsAndConditionsPage
          goNext={handleGoNextPage}
          goBack={handleGoPreviousPage}
        />
      </VisibilityWrapper>
      <Form layout={'vertical'} form={orgForm}>
        <VisibilityWrapper isVisible={step === 2}>
          <PracticeInfoPage
            form={orgForm}
            goNext={handleGoNextPage}
            goBack={handleGoPreviousPage}
          />
        </VisibilityWrapper>
      </Form>
      <Form layout={'vertical'} form={form}>
        {/*<VisibilityWrapper isVisible={formStep === 1}>
          <RoleSelectionPage
            form={form}
            isRootUser={true}
            goNext={() => {
              setStep((currentStep) => currentStep + 1);
              setFormStep(2);
            }}
            goBack={() => {
              setStep((currentStep) => currentStep - 1);
              setFormStep(0);
            }}
            onRoleChange={generateStepItemsByRole}
          />
        </VisibilityWrapper>*/}
        <VisibilityWrapper isVisible={step === 3}>
          <PersonalInfoPage
            form={form}
            goNext={handleGoNextPage}
            goBack={handleGoPreviousPage}
          />
        </VisibilityWrapper>
        <VisibilityWrapper isVisible={step === 4}>
          <ProfessionalInfoPage
            form={form}
            qualifications={qualifications}
            onQualificationChange={setQualifications}
            goNext={handleGoNextPage}
            goBack={handleGoPreviousPage}
          />
        </VisibilityWrapper>
        <VisibilityWrapper isVisible={step === 5}>
          <ReferralCodePage
            isLoading={isSubmitting}
            goNext={handleSubmit}
            goBack={handleGoPreviousPage}
          />
        </VisibilityWrapper>
      </Form>
    </FormPageWrapper>
  );
};
