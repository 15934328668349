import { FC, useState } from 'react';
import { Button, Checkbox } from 'antd';
import { TermsAndConditions } from '../common/terms-and-conditions';
import { FooterWrapper } from './styled';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
interface Props {
  goBack: () => void;
  goNext: () => void;
}
export const TermsAndConditionsPage: FC<Props> = ({ goNext, goBack }) => {
  const [checked, setChecked] = useState(false);
  return (
    <Wrapper>
      <TermsAndConditions />
      <Checkbox
        style={{ marginTop: '12px' }}
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked);
        }}
      >
        I have read and agree to the Privacy Policy.
      </Checkbox>
      <FooterWrapper>
        <Button size={'large'} onClick={goBack}>
          Back
        </Button>
        <Button
          size={'large'}
          disabled={!checked}
          type={'primary'}
          onClick={goNext}
        >
          Next
        </Button>
      </FooterWrapper>
    </Wrapper>
  );
};
