import axios from 'axios';
import { ACCESS_TOKEN, REFRESH_TOKEN, USER_ID } from '../const/localStorageKey';
import { UNAUTHORIZED_CODE } from '../const/network';
import { reauthenticate } from './index';

export const client = axios.create({
  baseURL: process.env.REACT_APP_SPECIALIST_LETTER_API_URL,
});
client.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (UNAUTHORIZED_CODE.includes(error.response.status)) {
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(USER_ID);
      const refreshToken = localStorage.getItem(REFRESH_TOKEN) ?? '';
      const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID ?? '';
      return reauthenticate({
        refreshToken,
        clientId,
      })
        .then((res) => {
          const originalRequest = error.config;
          const { accessToken, idToken } = res;
          localStorage.setItem(ACCESS_TOKEN, accessToken);
          localStorage.setItem(USER_ID, idToken);
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
          return axios.request(originalRequest);
        })
        .catch((error) => {
          if (UNAUTHORIZED_CODE.includes(error.response.status)) {
            localStorage.removeItem(REFRESH_TOKEN);
            window.location.href = `/signIn`;
            return Promise.reject(error);
          } else {
            return Promise.reject(error);
          }
        });
    } else {
      return Promise.reject(error);
    }
  }
);
