import React, { FC, useEffect, useState } from 'react';
import { useTokenBalanceContext } from '../../providers/TokenBalanceProvider';
import styled from 'styled-components';
import {
  Descriptions,
  Empty,
  message,
  Pagination,
  Statistic,
  Table,
  TableProps,
  Tooltip,
} from 'antd';
import { BalanceType, TokenUsageResponseType } from '../../models/balanceToken';
import { getUsageHistory } from '../../client';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { dateFormat } from '../../../utils';
import { ProductCard } from '../../components/common/charge-modal/ProductCard';
import { useLoaderData } from 'react-router-dom';
import { ProductResponseType } from '../../models/payment';
import { UsageItem } from '../../components/balance/usage-item';
import { TopUpAlert } from '../../components/balance/TopUpAlert';

const Wrapper = styled.div`
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  display: flex;
  align-items: baseline;
`;
const Description = styled.div`
  margin-top: 8px;
  color: #171d4d73;
  font-size: 14px;
  display: flex;
  align-items: center;
`;
const Subtitle = styled.div`
  font-size: 20px;
  display: flex;
  column-gap: 16px;
  margin-bottom: 8px;
`;
const ContentWrapper = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 12px;
`;
const BalanceItemWrapper = styled.div`
  display: inline-block;
  padding: 12px;
  background-color: #f0f3f7;
  border-radius: 12px;
  width: 220px;
  &:not(:first-child) {
    margin-left: 22px;
  }
`;
const BalanceDetailSwitch = styled.span`
  margin-left: 12px;
  font-size: 20px;
  cursor: pointer;
`;
const ProductsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  column-gap: 32px;
  overflow-x: auto;
  padding: 4px 24px 4px 0;
`;
const UsageItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;
const BalanceTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
`;
const BalanceTypeText = styled.span`
  font-size: 16px;
  padding-right: 12px;
`;
const MainContentWrapper = styled.div`
  width: calc(100vw - 616px);
`;
const SecondContentWrapper = styled.div`
  padding-left: 48px;
  border-left: #dae6f2 1px solid;
  width: 420px;
  flex-shrink: 0;
`;
const CustomTooltip = styled(Tooltip)`
  font-size: 18px;
  margin-left: 8px;
`;
const IconImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 4px;
`;
const IconImageSm = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;
const PricingWrapper = styled.div`
  margin-top: 32px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

type PriceTableDataType = {
  level: string;
  wordLimit: string;
  letter: string;
};

const titleDescription = (
  <ul style={{ paddingInlineStart: 20 }}>
    <li>cost 1 for letter smaller or equal to 350 characters</li>
    <li>cost 2 for letter smaller or equal to 500 characters</li>
    <li>cost 3 for letter larger than 500 characters</li>
  </ul>
);
const priceData = [
  {
    level: 'Standard',
    wordLimit: '350',
    letter: '1',
  },
  {
    level: 'Medium',
    wordLimit: '351 - 500',
    letter: '2',
  },
  {
    level: 'Long',
    wordLimit: '501+',
    letter: '3',
  },
];

export const Balance: FC = () => {
  const products = useLoaderData() as ProductResponseType[];

  const [showAlert, setShowAlert] = useState(false);
  const {
    balanceToken: { totalBalance, balances },
  } = useTokenBalanceContext();
  useEffect(() => {
    setShowAlert(!totalBalance);
  }, [totalBalance]);
  const [usageHistoryData, setUsageHistoryData] = useState<
    TokenUsageResponseType[]
  >([]);
  const [usageHistoryTotalcount, setUsageHistoryTotalcount] = useState(0);
  const pageSize = 10;
  const updateUsageHistoryWithPagination = (page = 1, limit = pageSize) => {
    getUsageHistory(page, limit).then((res) => {
      setUsageHistoryData(res.data);
      setUsageHistoryTotalcount(res.count);
    });
  };
  useEffect(() => {
    updateUsageHistoryWithPagination();
  }, []);
  const [isBalanceDetailShown, setIsBalanceDetailShown] =
    useState<boolean>(false);
  const toggleBalanceDetail = () => {
    setIsBalanceDetailShown((prevState) => !prevState);
  };
  const formatBalanceType = (type: BalanceType): string =>
    type === 'top_up' ? 'top up' : type;
  const ArrowSwitcher = (
    <BalanceDetailSwitch onClick={toggleBalanceDetail}>
      {isBalanceDetailShown ? <CaretDownOutlined /> : <CaretRightOutlined />}
    </BalanceDetailSwitch>
  );
  const priceTableColumns: TableProps<PriceTableDataType>['columns'] = [
    {
      dataIndex: 'level',
      render: (text: string) => (
        <div style={{ fontWeight: 'bold' }}>{text}</div>
      ),
      align: 'center',
    },
    {
      dataIndex: 'wordLimit',
      title: 'Word limit',
      align: 'center',
    },
    {
      dataIndex: 'letter',
      title: 'Letter required',
      align: 'center',
    },
  ];
  return (
    <Wrapper>
      <TopUpAlert initialState={showAlert} />
      <MainContentWrapper>
        <Title>Letter Balance</Title>
        <Description>
          Every 350 words costs 1{' '}
          <IconImageSm src={'/tokenIcon.svg'} style={{ marginLeft: 4 }} />{' '}
          Letter
        </Description>
        <Statistic
          value={totalBalance}
          valueStyle={{ fontSize: '40px' }}
          prefix={<IconImage src={'/tokenIcon.svg'} />}
          suffix={balances.length > 0 && ArrowSwitcher}
        />
        {isBalanceDetailShown && (
          <ContentWrapper>
            {balances.map((balance, index) => (
              <BalanceItemWrapper key={index}>
                <BalanceTypeText>
                  {formatBalanceType(balance.type)}
                </BalanceTypeText>
                <BalanceTitle>{balance.remainTokenCount}</BalanceTitle>
                <Descriptions size={'small'} layout={'horizontal'} column={1}>
                  <Descriptions.Item label={'expired till'}>
                    {dateFormat(balance.expiryDate)}
                  </Descriptions.Item>
                </Descriptions>
              </BalanceItemWrapper>
            ))}
          </ContentWrapper>
        )}
        <ProductsWrapper>
          {products.map((product) => (
            <ProductCard key={product.id} data={product} />
          ))}
        </ProductsWrapper>
        {/*<PricingWrapper>
          <Title>
            Pricing
            <CustomTooltip
              title={titleDescription}
              placement={'bottomLeft'}
              arrow={{ pointAtCenter: true }}
              overlayInnerStyle={{ width: 400 }}
            >
              <InfoCircleOutlined />
            </CustomTooltip>
          </Title>
          <Table
            dataSource={priceData}
            columns={priceTableColumns}
            pagination={false}
          ></Table>
        </PricingWrapper>*/}
      </MainContentWrapper>
      <SecondContentWrapper>
        <Subtitle>
          Usage history
          {usageHistoryTotalcount > 1 && (
            <Pagination
              defaultCurrent={1}
              total={usageHistoryTotalcount}
              pageSize={pageSize}
              onChange={(page) => {
                updateUsageHistoryWithPagination(page);
              }}
            />
          )}
        </Subtitle>

        {usageHistoryData.length > 0 ? (
          <UsageItemWrapper>
            {usageHistoryData.map((usage) => (
              <UsageItem key={usage.id} data={usage} />
            ))}
          </UsageItemWrapper>
        ) : (
          <Empty />
        )}
      </SecondContentWrapper>
    </Wrapper>
  );
};
