import { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, message, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  AustraliaState,
  CreateUserInput,
  DoctorProfileInput,
} from '../../models';
import { useUserProfileContext } from '../../providers/UserProfileProvider';
import { ClauseModal } from '../../components/common/clause-modal';
import { createOrg } from '../../client';
import { PmsCreateOrganizationDto } from '../../models/pms-create-organization.dto';

const Wrapper = styled.div`
  display: flex;
  column-gap: 48px;
  height: calc(100vh - 146px);
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;
const FormWrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;
  padding-right: 64px;
`;
const SubmitButton = styled(Button)`
  margin: 16px 0 48px;
  width: 200px;
`;
const FormSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
`;

type ProfilePageFormDataType = Omit<CreateUserInput, 'doctor'> &
  DoctorProfileInput;

const stateOptions = Object.values(AustraliaState).map((stateStr) => ({
  label: stateStr,
  value: stateStr,
}));
export const Organization: FC = () => {
  const { userProfile, refreshUserProfile } = useUserProfileContext();
  const isFirstLoggedIn = useRef<boolean>(!userProfile);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleFinish = async (data: ProfilePageFormDataType) => {
    setIsLoading(true);
    try {
      const input = {} as PmsCreateOrganizationDto;
      await createOrg(input);
      messageApi.success('Submit succeed, redirecting to dashboard.');

      messageApi.success('Submit succeed, redirecting to patients page.');
      navigate('/patients');
    } catch (e) {
      messageApi.error(
        'Something went wrong, please resubmit the survey or refresh the page.'
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Wrapper>
      {contextHolder}

      <FormWrapper>
        <Form layout={'vertical'} onFinish={handleFinish}>
          <SectionWrapper>
            <FormSectionWrapper>
              <Form.Item label={'Organization Name'} name={'name'}>
                <Input />
              </Form.Item>
              <Form.Item label={'Organization Email'} name={'email'}>
                <Input />
              </Form.Item>
              <Form.Item
                label={'organization phone number'}
                name={'phoneNumber'}
              >
                <Input />
              </Form.Item>
              <Form.Item label={'Fax Number'} name={'faxNumber'}>
                <Input />
              </Form.Item>
              <Form.Item label={'Address1'} name={'address1'}>
                <Input />
              </Form.Item>
              <Form.Item label={'Address2'} name={'address2'}>
                <Input />
              </Form.Item>
              <Form.Item label={'Suburb'} name={'suburb'}>
                <Input />
              </Form.Item>
              <Form.Item
                label={'State'}
                name={'state'}
                rules={[{ required: true }]}
              >
                <Select options={stateOptions} />
              </Form.Item>
              <Form.Item label={'Postcode'} name={'postcode'}>
                <Input />
              </Form.Item>
            </FormSectionWrapper>

            <SubmitButton
              loading={isLoading}
              size={'large'}
              type={'primary'}
              htmlType={'submit'}
            >
              Submit
            </SubmitButton>
          </SectionWrapper>
        </Form>
      </FormWrapper>
      <ClauseModal initialState={isFirstLoggedIn.current} />
    </Wrapper>
  );
};
