import { FC } from 'react';
import { Button, Form, FormInstance, Input, Select } from 'antd';
import { genderOptions, stateOptions } from '../../../const/view';
import { FooterWrapper, FormSectionWrapper, PageTitle } from './styled';

interface Props {
  form: FormInstance<unknown>;
  goNext: () => void;
  goBack: () => void;
}
export const PracticeInfoPage: FC<Props> = ({ form, goNext, goBack }) => {
  const handleValidate = async () => {
    try {
      await form.validateFields([
        'name',
        'phoneNumber',
        'email',
        'address1',
        'suburb',
        'state',
        'postcode',
      ]);
      goNext();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <PageTitle>Tell us a bit about your practice</PageTitle>
      <FormSectionWrapper>
        <Form.Item
          label={'Practice Name'}
          name={'name'}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Practice Mobile'}
          name={'phoneNumber'}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Practice Email'}
          name={'email'}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Address line 1'}
          name={'address1'}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={'Address line 2'} name={'address2'}>
          <Input />
        </Form.Item>
        <Form.Item
          label={'Suburb'}
          name={'suburb'}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={'State'} name={'state'} rules={[{ required: true }]}>
          <Select options={stateOptions} />
        </Form.Item>
        <Form.Item
          label={'Postcode'}
          name={'postcode'}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </FormSectionWrapper>
      <FooterWrapper>
        <Button size={'large'} onClick={goBack}>
          Back
        </Button>
        <Button size={'large'} type={'primary'} onClick={handleValidate}>
          Next
        </Button>
      </FooterWrapper>
    </div>
  );
};
