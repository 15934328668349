import {
  TokenHistoryType,
  TokenUsageResponseType,
} from '../../../models/balanceToken';
import { dateFormat } from '../../../../utils';

export type UsageItemViewDataType = {
  tokenCount: string;
  type: string;
  date: string;
};

const getTypeString = (type: TokenHistoryType): string => {
  switch (type) {
    case 'TOP_UP':
      return 'Top up';
    case 'USAGE':
      return 'Usage';
    default:
      return '';
  }
};
const getTokenCount = (data: TokenUsageResponseType): string => {
  return data.type === 'USAGE' ? `-${data.tokenCount}` : `${data.tokenCount}`;
};
export const adaptData = (
  data: TokenUsageResponseType
): UsageItemViewDataType => ({
  tokenCount: getTokenCount(data),
  type: getTypeString(data.type),
  date: dateFormat(data.createdDate),
});
