import React, { FC, useState } from 'react';
import { PatientProfileType, PmsGender, UserRole } from '../../../models';
import { Button, Divider } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { NotesUploadModal } from '../notes-upload-modal';
import { AudioUploadModal } from '../audio-upload-modal';
import { useRevalidator } from 'react-router-dom';
import { useUserProfileContext } from '../../../providers/UserProfileProvider';
import { CreateOrUpdatePatientModal } from '../../patients/create-or-update-patient-modal';
import { ChargeModal } from '../../common/charge-modal';
import { useTokenBalanceContext } from '../../../providers/TokenBalanceProvider';

const ButtonGroupWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  margin-top: 24px;
`;
const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`;
const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-size: 14px;
  font-weight: 500;
  strong {
    display: inline-block;
    width: 76px;
    margin-right: 16px;
  }
  span {
    color: #7c81a8;
  }
`;
const AudioUploadIcon = styled.img.attrs({ src: '/audio-upload-icon.svg' })`
  width: 14px;
  height: 14px;
`;
const NoteUploadIcon = styled.img.attrs({ src: '/note-upload-icon.svg' })`
  width: 14px;
  height: 14px;
`;
const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  patient: PatientProfileType;
}

export const PatientInfoCard: FC<Props> = ({ patient }) => {
  const {
    userProfile: { roles },
  } = useUserProfileContext();
  const {
    balanceToken: { totalBalance },
    refreshBalance,
  } = useTokenBalanceContext();
  const isDoctor = roles?.includes(UserRole.DOCTOR);
  const revalidator = useRevalidator();
  const handleAfterUpdate = () => {
    setIsNoteUploadModalOpen(false);
    setIsAudioUploadModalOpen(false);
    setIsUpdatePatientModalOpen(false);
    refreshBalance();
    revalidator.revalidate();
  };
  const [isNoteUploadModalOpen, setIsNoteUploadModalOpen] =
    useState<boolean>(false);
  const [isAudioUploadModalOpen, setIsAudioUploadModalOpen] =
    useState<boolean>(false);
  const [isUpdatePatientModalOpen, setIsUpdatePatientModalOpen] =
    useState<boolean>(false);
  const [isChargeModalOpen, setIsChargeModalOpen] = useState<boolean>(false);
  const getPatientGender = (gender: PmsGender): string => {
    if (gender === PmsGender.F) return 'Female';
    if (gender === PmsGender.M) return 'Male';
    if (gender === PmsGender.I) return 'Intersex or Indeterminate';
    if (gender === PmsGender.N) return 'Not Stated';
    return '';
  };
  return (
    <div>
      {isDoctor && (
        <>
          <Subtitle>Generate New Letter</Subtitle>
          <ButtonGroupWrapper>
            <StyledButton
              icon={<AudioUploadIcon />}
              block
              onClick={() => {
                if (totalBalance > 0) {
                  setIsAudioUploadModalOpen(true);
                } else {
                  setIsChargeModalOpen(true);
                }
              }}
            >
              Dictation
            </StyledButton>
            <StyledButton
              type={'primary'}
              icon={<NoteUploadIcon />}
              block
              onClick={() => {
                if (totalBalance > 0) {
                  setIsNoteUploadModalOpen(true);
                } else {
                  setIsChargeModalOpen(true);
                }
              }}
            >
              Notes
            </StyledButton>
          </ButtonGroupWrapper>
          <Divider />
        </>
      )}
      <BlockWrapper>
        <Subtitle>
          {'Patient Information '}
          <EditOutlined onClick={() => setIsUpdatePatientModalOpen(true)} />
        </Subtitle>
        <div>
          <strong>First Name: </strong>
          <span>{patient.firstName}</span>
        </div>
        <div>
          <strong>Last Name: </strong>
          <span>{patient.lastName}</span>
        </div>
        <div>
          <strong>DOB: </strong>
          <span>{patient.dateOfBirth}</span>
        </div>
        <div>
          <strong>Gender: </strong>
          <span>{getPatientGender(patient.gender)}</span>
        </div>
        {patient.email && (
          <div>
            <strong>Email: </strong>
            <span>{patient.email}</span>
          </div>
        )}
        {patient.medicareNo && (
          <div>
            <strong>Medicare: </strong>
            <span>{patient.medicareNo}</span>
          </div>
        )}
      </BlockWrapper>

      <NotesUploadModal
        data={patient}
        isOpen={isNoteUploadModalOpen}
        onClose={() => {
          setIsNoteUploadModalOpen(false);
        }}
        afterUpdated={handleAfterUpdate}
      />
      <AudioUploadModal
        data={patient}
        isOpen={isAudioUploadModalOpen}
        onClose={() => {
          setIsAudioUploadModalOpen(false);
        }}
        afterDataUploaded={handleAfterUpdate}
      />
      <CreateOrUpdatePatientModal
        isOpen={isUpdatePatientModalOpen}
        onClose={() => setIsUpdatePatientModalOpen(false)}
        afterUpdated={handleAfterUpdate}
        currentPatient={patient}
        isUpdatePatient={true}
      />
      <ChargeModal
        isOpen={isChargeModalOpen}
        setIsOpen={setIsChargeModalOpen}
        description={'You are running out of balance, please top up first'}
      />
    </div>
  );
};
