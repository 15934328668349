import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ACCESS_TOKEN,
  HAS_LOGGED_IN,
  IS_EXPIRED,
  REFRESH_TOKEN,
  USER_ID,
} from '../../const/localStorageKey';
import { authenticate } from '../../client';

export const Auth: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const authenticateUser = useCallback(
    async (code: string) => {
      localStorage.removeItem(IS_EXPIRED);
      setIsLoading(true);
      const { accessToken, refreshToken, idToken } = await authenticate({
        code,
        redirectUri: process.env.REACT_APP_COGNITO_REDIRECT_URI || '',
        clientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
      });
      if (accessToken && refreshToken) {
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        localStorage.setItem(IS_EXPIRED, 'false');
        localStorage.setItem(HAS_LOGGED_IN, 'true');
        localStorage.setItem(USER_ID, idToken);
        setIsLoading(false);
        navigate('/');
      }
    },
    [authenticate]
  );

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code');
    code && authenticateUser(code);
  }, [authenticateUser, location]);

  return (
    <>
      {isLoading ? (
        <div>Authenticating...</div>
      ) : (
        <div>Authenticate succeed, redirecting...</div>
      )}
    </>
  );
};
