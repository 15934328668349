import { FC, useEffect, useState } from 'react';
import {
  getEngagementMetrics,
  getUsageMetrics,
  getInvitedUsersMetrics,
  getTopTenUsersMetrics,
} from '../../client';
import styled from 'styled-components';
import { Select, Radio, DatePicker, Form } from 'antd';
import {
  adaptInvitedUserData,
  adaptPaidUserData,
  adaptLetterUsageData,
  InvitedUserGraphViewType,
  MetricsType,
  PaidUserGraphViewType,
  StatisticsFrequency,
  UsageGraphViewType,
} from './adaptData';
import {
  Line,
  ComposedChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Bar,
  Legend,
  BarChart,
} from 'recharts';
import {
  GetInvitedUserMetricsResponseType,
  GetLetterUsageMetricsResponseType,
  GetPaidUserMetricResponseType,
  GetTopTenUsersMetricResponseType,
} from '../../models';

const Wrapper = styled.div`
  margin: 24px;
`;
const FilterWrapper = styled.div`
  display: flex;
  column-gap: 40px;
`;
const ChartWrapper = styled.div`
  margin: 24px;
`;

export const Statistics: FC = () => {
  const [metric, setMetric] = useState<MetricsType>();
  const [frequency, setFrequency] = useState<StatisticsFrequency>();
  const [duration, setDuration] = useState<[Date, Date]>();
  const [graphData, setGraphData] = useState<
    UsageGraphViewType | PaidUserGraphViewType | InvitedUserGraphViewType
  >();
  const [topTenUsers, setTopTenUsers] =
    useState<GetTopTenUsersMetricResponseType>();

  const handleDurationChange = (date: unknown, dateStr: [string, string]) => {
    setDuration([new Date(dateStr[0]), new Date(dateStr[1])]);
  };

  useEffect(() => {
    if (metric && frequency && duration?.length === 2) {
      const argument = { frequency, start: duration[0], end: duration[1] };
      if (metric === 'paidUsers') {
        getEngagementMetrics(argument).then(
          (res: GetPaidUserMetricResponseType) => {
            setGraphData(adaptPaidUserData(res, frequency, duration));
          }
        );
        getTopTenUsersMetrics(argument).then(
          (res: GetTopTenUsersMetricResponseType) => {
            setTopTenUsers(res);
          }
        );
      }
      if (metric === 'letterUsage') {
        getUsageMetrics(argument).then(
          (res: GetLetterUsageMetricsResponseType) => {
            setGraphData(adaptLetterUsageData(res, frequency, duration));
          }
        );
      }
      if (metric === 'invitedUsers') {
        getInvitedUsersMetrics(argument).then(
          (res: GetInvitedUserMetricsResponseType) => {
            setGraphData(adaptInvitedUserData(res, frequency, duration));
          }
        );
      }
    }
  }, [metric, frequency, duration]);
  return (
    <Wrapper>
      <Form layout={'vertical'}>
        <FilterWrapper>
          <Form.Item label={'Metric type'}>
            <Select onChange={setMetric} style={{ width: 200 }}>
              <Select.Option value={'letterUsage'}>
                Letter usage Stats
              </Select.Option>
              <Select.Option value={'paidUsers'}>Paid user Stats</Select.Option>
              <Select.Option value={'invitedUsers'}>
                Invited user Stats
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={'Frequency'}>
            <Radio.Group
              onChange={(e) => {
                setFrequency(e.target.value);
              }}
            >
              <Radio value={'DAY'}>Daily</Radio>
              <Radio value={'MONTH'}>Monthly</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label={'Duration'}>
            <DatePicker.RangePicker onChange={handleDurationChange} />
          </Form.Item>
        </FilterWrapper>
      </Form>

      <ChartWrapper>
        <ResponsiveContainer width={'100%'} height={600}>
          <ComposedChart data={graphData}>
            {metric === 'letterUsage' && (
              <>
                <Bar
                  dataKey="gpLetterCount"
                  yAxisId="left"
                  fill="#85FFC7"
                  stackId={'a'}
                />
                <Bar
                  dataKey="referralLetterCount"
                  yAxisId="left"
                  fill="#FFD400"
                  stackId={'a'}
                />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="averageLetterCount"
                  stroke="#ff7300"
                  connectNulls
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="userCount"
                  stroke="black"
                  connectNulls
                />
                <YAxis
                  yAxisId="left"
                  domain={[0, 'dataMax']}
                  label={{
                    value: 'letter count',
                    angle: -90,
                  }}
                />
                <YAxis
                  yAxisId="right"
                  domain={[0, 'dataMax']}
                  orientation="right"
                  label={{
                    value: 'user count',
                    angle: -90,
                  }}
                />
              </>
            )}
            {metric === 'paidUsers' && (
              <>
                <Bar dataKey="paidUserCount" yAxisId="right" fill="#FF9FE5" />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="topUpAmount"
                  stroke="#82DDF0"
                  connectNulls
                />
                <YAxis
                  yAxisId="left"
                  domain={[0, 'dataMax']}
                  label={{
                    value: 'top up amount',
                    angle: -90,
                  }}
                />
                <YAxis
                  yAxisId="right"
                  domain={[0, 'dataMax']}
                  orientation="right"
                  label={{
                    value: 'user count',
                    angle: -90,
                  }}
                />
              </>
            )}

            {metric === 'invitedUsers' && (
              <>
                <YAxis
                  yAxisId="left"
                  domain={[0, 'dataMax']}
                  orientation="left"
                  label={{
                    value: 'user count',
                    angle: -90,
                  }}
                />
                <Bar dataKey="invitedUserCount" yAxisId="left" fill="gray" />
              </>
            )}

            <XAxis dataKey="date" />
            <Tooltip />
            <Legend />
          </ComposedChart>
        </ResponsiveContainer>
        {metric === 'paidUsers' && (
          <>
            <h2>Top 10 users</h2>
            <ResponsiveContainer width={'80%'} height={600}>
              <BarChart data={topTenUsers} layout="vertical">
                <Bar dataKey="amount" fill="gray" />
                <XAxis type="number" />
                <YAxis
                  domain={[0, 'dataMax']}
                  dataKey="userId"
                  type="category"
                />
                <Tooltip />
                <Legend />
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </ChartWrapper>
    </Wrapper>
  );
};
