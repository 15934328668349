import {
  AustraliaState,
  GeneralPractitionerProfileType,
  LettersListResponseType,
  PatientProfileType,
} from '../../../models';
import { LetterDataType } from '../../../view-models';
import { PmsUserResponse } from '../../../models/pms-user.response';
import { simpleDateStrFormat } from '../../../../utils';
import { PmsOrganizationResponse } from '../../../models/pms-organization.response';

export type LetterEditorDataType = LetterDataType & {
  patientFullName: string;
  patientAddress: string;
  patientPhone: string;
  patientEmail: string;
  gpFullName: string;
  specialistAddress: string;
  specialistFirstName: string;
  specialistLastName: string;
  specialistFullName: string;
  specialistTitle: string;
};

const formatAddress = ({
  address1,
  address2,
  suburb,
  postcode,
  state,
}: {
  address1: string;
  address2?: string;
  suburb: string;
  postcode: string;
  state: AustraliaState;
}) =>
  `${address1}${
    address2 ? ' ' + address2 : ''
  }, ${suburb}, ${state} ${postcode}`;

const getGpFullName = (gp: GeneralPractitionerProfileType | undefined) => {
  if (!gp?.firstName && !gp?.firstName) {
    return 'GP full name';
  }
  return `${gp?.firstName} ${gp?.firstName}`;
};

export const adaptData = ({
  patient,
  specialist,
  gp,
  letter,
  organization,
}: {
  patient: PatientProfileType;
  organization: PmsOrganizationResponse;
  specialist?: PmsUserResponse;
  gp?: GeneralPractitionerProfileType;
  letter?: LettersListResponseType;
}): LetterEditorDataType => ({
  patientFullName: `${patient.firstName} ${patient.lastName}`,
  patientFirstName: patient.firstName,
  patientLastName: patient.lastName,
  patientAddress: formatAddress({
    address1: patient.address1,
    address2: patient.address2,
    suburb: patient.suburb,
    postcode: patient.postcode,
    state: patient.state,
  }),
  patientPhone: patient.mobileNumber ?? '',
  patientEmail: patient.email,
  patientDateOfBirth: simpleDateStrFormat(patient.dateOfBirth),
  gpFullName: getGpFullName(gp),
  gpFirstName: gp?.firstName ?? '',
  gpLastName: gp?.lastName ?? '',
  specialistAddress: formatAddress({
    address1: organization.address1,
    address2: organization.address2,
    suburb: organization.suburb,
    postcode: organization.postcode,
    state: organization.state,
  }),
  specialistFirstName: specialist?.firstName ?? '',
  specialistLastName: specialist?.lastName ?? '',
  specialistFullName: `${specialist?.firstName} ${specialist?.lastName}`,
  specialistTitle: specialist?.doctorProfile?.title ?? '',
  generatedLetter: letter?.generatedLetter ?? '',
});
