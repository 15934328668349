import { FC, useState } from 'react';
import { Button, Checkbox } from 'antd';
import { PrivacyPolicy } from '../common/privacy-policy';
import { FooterWrapper } from './styled';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  showBackButton?: boolean;
  goNext: () => void;
  goBack: () => void;
}
export const PrivacyPolicyPage: FC<Props> = ({
  showBackButton = false,
  goNext,
  goBack,
}) => {
  const [checked, setChecked] = useState(false);
  return (
    <Wrapper>
      <PrivacyPolicy />
      <Checkbox
        style={{ marginTop: '12px' }}
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked);
        }}
      >
        I have read and agree to the Privacy Policy.
      </Checkbox>
      <FooterWrapper>
        {showBackButton && (
          <Button size={'large'} onClick={goBack}>
            Back
          </Button>
        )}
        <Button
          size={'large'}
          type={'primary'}
          disabled={!checked}
          onClick={goNext}
        >
          Next
        </Button>
      </FooterWrapper>
    </Wrapper>
  );
};
