import { FC, useState } from 'react';
import { ButtonProps, message, Modal } from 'antd';
import {
  PatientInputType,
  PatientProfileType,
  PmsGender,
} from '../../../models';
import { PatientForm } from '../patient-form';
import { createPatient } from '../../../client';
import { updatePatient } from '../../../client/patients';
import { transformPatientInputData } from './adaptData';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  afterUpdated: () => void;
  isUpdatePatient: boolean;
  currentPatient?: PatientProfileType;
}

const defaultPatientData: PatientInputType = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  gender: PmsGender.M,
  phoneNumber: undefined,
  email: undefined,
  addressLine1: undefined,
  addressLine2: undefined,
  suburb: undefined,
  postcode: undefined,
  state: undefined,
  medicareCardNumber: undefined,
};
const requiredKeys = ['dateOfBirth', 'gender', 'lastName', 'firstName'];
export const CreateOrUpdatePatientModal: FC<Props> = ({
  isOpen,
  onClose,
  afterUpdated,
  isUpdatePatient,
  currentPatient,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [patientData, setPatientData] = useState<PatientInputType>(
    !currentPatient
      ? defaultPatientData
      : {
          firstName: currentPatient.firstName,
          lastName: currentPatient.lastName,
          dateOfBirth: currentPatient.dateOfBirth,
          gender: currentPatient.gender,
          phoneNumber: currentPatient.mobileNumber,
          email: currentPatient.email,
          addressLine1: currentPatient.address1,
          addressLine2: currentPatient.address2,
          suburb: currentPatient.suburb,
          postcode: currentPatient.postcode,
          state: currentPatient.state,
          medicareCardNumber: currentPatient.medicareNo,
          urn: currentPatient.urn,
        }
  );
  const [isOptionalFieldsShown, setIsOptionalFieldsShown] = useState(false);
  const [okButtonProps, setOkButtonProps] = useState<ButtonProps>();
  useState<boolean>(false);
  const handleOk = async () => {
    const isPatientValid = Object.keys(patientData).every((key) => {
      return !requiredKeys.includes(key)
        ? true
        : !!patientData[key as keyof PatientInputType];
    });
    if (isPatientValid) {
      const transformedPatientData = transformPatientInputData(
        patientData,
        isOptionalFieldsShown
      );
      try {
        if (isUpdatePatient) {
          await updatePatient(
            {
              patient: transformedPatientData,
            },
            currentPatient?.id ?? ''
          );
        } else {
          await createPatient({
            patient: transformedPatientData,
          });
          setPatientData(defaultPatientData);
        }
        onClose();
        afterUpdated();
      } catch {
        messageApi.open({
          type: 'error',
          content: 'Something went wrong, please retry or refresh the page.',
        });
      } finally {
        setOkButtonProps({ loading: false });
      }
    } else {
      messageApi.open({
        type: 'error',
        content: 'Please finish input all the information',
      });
    }
  };
  const handlePatientDataChange = (
    value: string,
    key: keyof PatientInputType
  ): void => {
    const updatedPatientData = {
      ...patientData,
      [key]: value,
    };
    setPatientData(updatedPatientData);
  };

  return (
    <Modal
      title={isUpdatePatient ? 'Update Patient' : 'New Patient'}
      open={isOpen}
      onOk={handleOk}
      onCancel={onClose}
      okButtonProps={okButtonProps}
      okText={isUpdatePatient ? 'Update' : 'Create'}
      closable={false}
      keyboard={false}
      maskClosable={false}
      width={900}
      centered
      destroyOnClose
    >
      {contextHolder}
      <PatientForm
        onChange={handlePatientDataChange}
        onExtraFormToggle={setIsOptionalFieldsShown}
        patientData={patientData}
        isUpdatePatient={isUpdatePatient}
      />
    </Modal>
  );
};
