import { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

const Wrapper = styled.div`
  margin-top: 48px;
  background-color: #f0f3f7;
  border-radius: 20px;
  padding: 80px 40px;
`;
const StyledButton = styled(Button).attrs({ type: 'primary' })`
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 80px;
  border-radius: 20px;
`;
const FooterWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: center;
`;
const Title = styled.div`
  font-size: 28px;
  font-weight: bolder;
`;
const Section = styled.p`
  font-size: 20px;
`;

interface Props {
  goNext?: () => void;
}
export const FinalPage: FC<Props> = ({ goNext }) => {
  return (
    <Wrapper>
      <Title>Enjoy 20 letters on us</Title>
      <Section>
        All new users joining AutoTranscribe will receive 20 standard letters
        for free, with 30 days expiry.
      </Section>
      <Section>
        If you have provided a referral code, you will get 10 more letters on
        top of this.
      </Section>
      <FooterWrapper>
        <StyledButton onClick={goNext}>Start now!</StyledButton>
      </FooterWrapper>
    </Wrapper>
  );
};
