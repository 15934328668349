import { FC, useState } from 'react';
import { Input, Modal, Rate } from 'antd';
import { FeedbackItemType } from '../../../models';
import styled from 'styled-components';

const DescriptionWrapper = styled.div`
  margin-top: 24px;
`;

interface Props {
  title?: string;
  description?: string | JSX.Element;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onSubmit: (feedback: FeedbackItemType[], rating?: number) => Promise<void>;
}

const { TextArea } = Input;
export const FeedbackSurveyModal: FC<Props> = ({
  title,
  description,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [feedback, setFeedback] = useState<FeedbackItemType[]>([
    {
      question: 'What did you like about your letter?',
      answer: '',
    },
    {
      question: 'How can we improve?',
      answer: '',
    },
  ]);
  const [rating, setRating] = useState<number>();

  const handleAnswerChange = (
    value: string,
    currentFeedback: FeedbackItemType
  ) => {
    const updatedData = [...feedback];
    const targetItemIndex = updatedData.findIndex(
      (item) => item.question === currentFeedback.question
    );
    updatedData[targetItemIndex].answer = value;
    setFeedback(updatedData);
  };
  const handleOk = async () => {
    await onSubmit(feedback, rating);
    setRating(0);
    feedback.forEach((item) => {
      handleAnswerChange('', item);
    });
  };
  return (
    <Modal
      open={isOpen}
      title={title}
      onCancel={() => {
        onClose(false);
      }}
      width={700}
      okButtonProps={{ disabled: !rating }}
      onOk={handleOk}
    >
      <DescriptionWrapper>{description}</DescriptionWrapper>
      <h3>Rate your letter.</h3>
      <Rate
        onChange={(value: number) => {
          setRating(value);
        }}
        value={rating}
      />
      {feedback.map((item) => (
        <div key={item.question}>
          <h3>{item.question}</h3>
          <TextArea
            value={item.answer}
            onChange={(e) => {
              handleAnswerChange(e.target.value, item);
            }}
          />
        </div>
      ))}
    </Modal>
  );
};
