import { PatientInputType } from '../../../models';

export const transformPatientInputData = (
  patientData: PatientInputType,
  hasExtraFields: boolean
): PatientInputType => {
  if (hasExtraFields) {
    return patientData;
  }
  return {
    firstName: patientData.firstName,
    lastName: patientData.lastName,
    dateOfBirth: patientData.dateOfBirth,
    gender: patientData.gender,
  };
};
