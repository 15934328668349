import styled from 'styled-components';
import { FC } from 'react';
import { ProfileMenu } from '../profile-menu';
import { BalanceWidget } from '../balance-widget';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 0 24px;
  border-bottom: #dae6f2 1.5px solid;
`;
const ToolkitWrapper = styled.div`
  display: flex;
  column-gap: 56px;
`;

export const Header: FC = () => {
  return (
    <Wrapper>
      AutoTranscribe
      <ToolkitWrapper>
        <BalanceWidget />
        <ProfileMenu />
      </ToolkitWrapper>
    </Wrapper>
  );
};
