import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Divider,
  Form,
  Input,
  Menu,
  MenuProps,
  message,
  Select,
} from 'antd';
import {
  QualificationsList,
  QualificationType,
} from '../../components/profile/qualification-list';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../models';
import { useUserProfileContext } from '../../providers/UserProfileProvider';
import { createUser, updateUser } from '../../client/user';
import { ClauseModal } from '../../components/common/clause-modal';
import {
  adaptData,
  ProfilePageFormViewType,
  transformCreateUserInputData,
  transformQualificationsData,
  transformUpdateUserInputData,
} from './adaptData';
import {
  genderOptions,
  Speciality,
  specialtyOptions,
  userRolesOptions,
} from '../../../const/view';

const Wrapper = styled.div`
  display: flex;
  column-gap: 48px;
  height: calc(100vh - 146px);
`;
const SideWrapper = styled.div`
  width: 256px;
  height: 100%;
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;
const FormWrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;
  padding-right: 64px;
`;
const SubmitButton = styled(Button)`
  margin: 16px 0 48px;
  width: 200px;
`;
const OrganizationWrapper = styled.div`
  margin-bottom: 24px;
  font-size: 16px;
`;
const FormSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
`;
const SpecialtySelectorWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const Profile: FC = () => {
  const { userProfile, refreshUserProfile } = useUserProfileContext();
  const isCreatingNewUser = !userProfile;
  const initialData = adaptData(userProfile);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const formRoles = Form.useWatch('roles', form) ?? [];
  const menuItems = useMemo(() => {
    const menuResult = [
      { label: 'Basic information', key: 'basic-information' },
      {
        label: 'Personal information',
        key: 'personal-information',
      },
    ];
    if (formRoles.includes(UserRole.DOCTOR)) {
      menuResult.push({
        label: 'Professional profile',
        key: 'professional-profile',
      });
    }
    if (isCreatingNewUser) {
      menuResult.push({
        label: 'Referral code',
        key: 'referral-code',
      });
    }
    return menuResult;
  }, [isCreatingNewUser, formRoles]);

  const [qualifications, setQualifications] = useState<QualificationType[]>(
    initialData.qualifications
  );
  const [specialty, setSpecialty] = useState<Speciality>(
    initialData.speciality
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const handleMenuItemClick: MenuProps['onClick'] = ({ key }) => {
    const itemDom = document.getElementById(key);
    itemDom && itemDom.scrollIntoView();
  };
  const handleFinish = async (data: ProfilePageFormViewType) => {
    const formattedQualifications = transformQualificationsData(qualifications);
    if (
      formattedQualifications.length > 0 ||
      !data.roles.includes(UserRole.DOCTOR)
    ) {
      setIsLoading(true);
      try {
        if (isCreatingNewUser) {
          const input = transformCreateUserInputData(data, qualifications);
          await createUser(input);
          refreshUserProfile();
          messageApi.success('Submit succeed, redirecting to patients page.');
          navigate('/patients');
        } else {
          const input = transformUpdateUserInputData(data, qualifications);
          await updateUser(userProfile.id, input);
          refreshUserProfile();
          messageApi.success('Submit succeed, your profile is updated.');
        }
      } catch (e) {
        messageApi.error(
          'Something went wrong, please resubmit the survey or refresh the page.'
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      messageApi.error('Please finish your profile.');
    }
  };
  return (
    <Wrapper>
      {contextHolder}
      <SideWrapper>
        <Menu
          mode={'inline'}
          defaultSelectedKeys={['basic-information']}
          items={menuItems}
          style={{ width: 256 }}
          onClick={handleMenuItemClick}
        />
      </SideWrapper>

      <FormWrapper>
        <Form
          layout={'vertical'}
          initialValues={initialData}
          onFinish={handleFinish}
          form={form}
        >
          <SectionWrapper>
            <div>
              <h2 id={'basic-information'}>Basic information</h2>
              <Divider />
              {isCreatingNewUser ? (
                <FormSectionWrapper>
                  <Form.Item
                    label={'Organisation id'}
                    name={'organizationId'}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={'Role'}
                    name={'roles'}
                    rules={[{ required: true }]}
                  >
                    <Select mode={'multiple'} options={userRolesOptions} />
                  </Form.Item>
                </FormSectionWrapper>
              ) : (
                <>
                  <OrganizationWrapper>
                    <strong>Organisation: </strong>
                    {userProfile.organization.name}
                  </OrganizationWrapper>
                  <FormSectionWrapper>
                    <Form.Item
                      label={'Role'}
                      name={'roles'}
                      rules={[{ required: true }]}
                    >
                      <Select
                        mode={'multiple'}
                        options={userRolesOptions}
                        disabled
                      />
                    </Form.Item>
                  </FormSectionWrapper>
                </>
              )}
            </div>
            <div>
              <h2 id={'personal-information'}>Personal information</h2>
              <Divider />
              <FormSectionWrapper>
                <Form.Item
                  label={'Given name'}
                  name={'firstName'}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={'Family name'}
                  name={'lastName'}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={'Gender'}
                  name={'gender'}
                  rules={[{ required: true }]}
                >
                  <Select options={genderOptions}></Select>
                </Form.Item>
                <Form.Item
                  label={'Phone number'}
                  name={'mobileNumber'}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={'Email'}
                  name={'email'}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </FormSectionWrapper>
            </div>
            {formRoles.includes(UserRole.DOCTOR) && (
              <div>
                <h2 id={'professional-profile'}>Professional profile</h2>
                <Divider />
                <FormSectionWrapper>
                  <Form.Item
                    label={'Title'}
                    name={'title'}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <SpecialtySelectorWrapper>
                    <Form.Item
                      label={'Speciality'}
                      name={'speciality'}
                      rules={[{ required: true }]}
                      style={{ flex: 1 }}
                    >
                      <Select
                        options={specialtyOptions}
                        onSelect={setSpecialty}
                      />
                    </Form.Item>
                    {specialty === Speciality.OTHER && (
                      <Form.Item
                        label={'What is your speciality?'}
                        name={'specialityDescription'}
                        rules={[{ required: true }]}
                        style={{ flex: 4 }}
                      >
                        <Input
                          placeholder={
                            "i.e. If you specialise in neurology, please write 'Neurologist'"
                          }
                        />
                      </Form.Item>
                    )}
                  </SpecialtySelectorWrapper>
                  <Form.Item
                    label={'Provider number'}
                    name={'providerId'}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <div />
                  <div>
                    <QualificationsList
                      qualifications={qualifications}
                      onChange={setQualifications}
                    />
                  </div>
                </FormSectionWrapper>
              </div>
            )}
            {isCreatingNewUser && (
              <div>
                <h2 id={'referral-code'}>Referral code</h2>
                <Divider />
                <FormSectionWrapper>
                  <Form.Item label={'Referral code'} name={'referralCode'}>
                    <Input />
                  </Form.Item>
                </FormSectionWrapper>
              </div>
            )}
            <SubmitButton
              loading={isLoading}
              size={'large'}
              type={'primary'}
              htmlType={'submit'}
            >
              Submit
            </SubmitButton>
          </SectionWrapper>
        </Form>
      </FormWrapper>
      <ClauseModal initialState={isCreatingNewUser} />
    </Wrapper>
  );
};
