import { FC } from 'react';
import { Button, Form, Input } from 'antd';
import { FooterWrapper, FormSectionWrapper, PageTitle } from './styled';

interface Props {
  isLoading?: boolean;
  goNext: () => void;
  goBack: () => void;
}
export const ReferralCodePage: FC<Props> = ({
  isLoading = false,
  goNext,
  goBack,
}) => {
  return (
    <div>
      <PageTitle>Provide the referral code</PageTitle>
      <FormSectionWrapper>
        <Form.Item
          label={'Referral code'}
          name={'referralCode'}
          extra={"Leave blank if you don't have one"}
        >
          <Input />
        </Form.Item>
      </FormSectionWrapper>
      <FooterWrapper>
        <Button size={'large'} onClick={goBack}>
          Back
        </Button>
        <Button
          loading={isLoading}
          size={'large'}
          type={'primary'}
          onClick={goNext}
        >
          Finish
        </Button>
      </FooterWrapper>
    </div>
  );
};
