import { createContext, ReactNode, useContext } from 'react';
import { toggleConfig } from './config';

type ToggleValidValue = 'on' | 'off' | 'true' | 'false';
type FeatureToggleItemType = {
  name: string;
  value: string;
};
export type ToggleConfigType = Array<{
  name: string;
  default: ToggleValidValue;
}>;
interface ToggleProviderProps {
  children?: ReactNode;
}
const ToggleContext = createContext<FeatureToggleItemType[]>(
  [] as FeatureToggleItemType[]
);
export const ToggleProvider = ({ children }: ToggleProviderProps) => {
  const getFeatureToggle = (name: string): string | null => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(name);
  };
  const toggleItems: FeatureToggleItemType[] = toggleConfig.map((item) => ({
    name: item.name,
    value: getFeatureToggle(item.name) ?? item.default,
  }));
  return (
    <ToggleContext.Provider value={toggleItems}>
      {children}
    </ToggleContext.Provider>
  );
};

export const useToggle = (toggleName: string) => {
  const toggleItems = useContext(ToggleContext);
  const targetToggleItem = toggleItems.find((item) => item.name === toggleName);
  return targetToggleItem?.value === 'on' || targetToggleItem?.value === 'true';
};
