import axios from 'axios';
import { AUTH } from '../const/apiPath';
import { REFRESH_TOKEN } from '../const/localStorageKey';
import { client } from './client';

import {
  CodeAuthenticationDto,
  CodeAuthenticationResponse,
  ReauthenticationDto,
  ReauthenticationResponse,
} from '../models';

export const authenticate = async (
  codeAuthenticationDto: CodeAuthenticationDto
): Promise<CodeAuthenticationResponse> => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_SPECIALIST_LETTER_API_URL}${AUTH}`,
      codeAuthenticationDto
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const reauthenticate = async (
  reauthenticationDto: ReauthenticationDto
): Promise<ReauthenticationResponse> => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_SPECIALIST_LETTER_API_URL}${AUTH}/refresh`,
      reauthenticationDto
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const logout = (): Promise<unknown> => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return client.post(`${AUTH}/token/revoke`, {
    token: refreshToken,
  });
};

export const verify = () => client.post(`${AUTH}/verify`);
