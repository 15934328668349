import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { routerRoutes } from './routes/routerRoutes';
import { ThemeProvider } from './providers/ThemeProvider';
import { ToggleProvider } from './providers/toggle-provider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider>
    <ToggleProvider>
      <RouterProvider router={routerRoutes} />
    </ToggleProvider>
  </ThemeProvider>
);
