import { client } from './client';
import { ORGANIZATION } from '../../const/apiPath';
import { PmsCreateOrganizationDto } from '../models/pms-create-organization.dto';

export const createOrg = async (
  createOrganizationDto: PmsCreateOrganizationDto
): Promise<string> => {
  try {
    const { data } = await client.post(
      `${ORGANIZATION}`,
      createOrganizationDto
    );
    return data.id;
  } catch (e) {
    return Promise.reject(e);
  }
};
