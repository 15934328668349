import { client } from './client';
import { PDF_SERVICE } from '../../const/apiPath';

const base64ToUint8Array = (base64: string) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes;
};
export const generatePDF = async (id: string): Promise<string> => {
  try {
    const { data: pdfData } = await client.post(
      `${PDF_SERVICE}/specialist-letter/${id}`
    );
    const unit8Array = base64ToUint8Array(pdfData);
    const blob = new Blob([unit8Array], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
  } catch (e) {
    return Promise.reject(e);
  }
};
