import { FC, useState } from 'react';
import { AustraliaState, PatientInputType, PmsGender } from '../../../models';
import styled from 'styled-components';
import { ConfigProvider, DatePicker, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  padding: 20px 20px 0;
`;
const Title = styled.h2`
  font-weight: bolder;
  font-size: 18px;
  margin: 0 0 14px;
`;
const Subtitle = styled.h3`
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 16px;
  font-weight: bolder;
  cursor: pointer;
`;
const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  padding-bottom: 20px;
`;

interface Props {
  onChange: (value: string, key: keyof PatientInputType) => void;
  onExtraFormToggle: (state: boolean) => void;
  patientData: PatientInputType;
  isUpdatePatient: boolean;
}
export const PatientForm: FC<Props> = ({
  onChange,
  onExtraFormToggle,
  patientData,
  isUpdatePatient,
}) => {
  const [isOptionalFieldsShown, setIsOptionalFieldsShown] = useState(false);
  const genderOptions = [
    {
      label: 'Female',
      value: PmsGender.F,
    },
    {
      label: 'Male',
      value: PmsGender.M,
    },
    {
      label: 'Intersex or Indeterminate',
      value: PmsGender.I,
    },
    {
      label: 'Not Stated',
      value: PmsGender.N,
    },
  ];
  const stateOptions = Object.values(AustraliaState).map((stateStr) => ({
    label: stateStr,
    value: stateStr,
  }));
  const handleExtraFormToggle = () => {
    const expectedState = !isOptionalFieldsShown;
    setIsOptionalFieldsShown(expectedState);
    onExtraFormToggle(expectedState);
  };

  return (
    <ConfigProvider theme={{ components: { Form: { itemMarginBottom: 8 } } }}>
      <Wrapper>
        <Form
          layout={'vertical'}
          initialValues={{
            ...patientData,
            dateOfBirth: patientData.dateOfBirth
              ? dayjs(patientData.dateOfBirth, 'YYYYMMDD')
              : null,
          }}
        >
          <Title>Contact Information</Title>
          <FormWrapper>
            <Form.Item
              label={'First Name'}
              name={'firstName'}
              rules={[{ required: true }]}
            >
              <Input
                onChange={(e) => {
                  onChange(e.target.value, 'firstName');
                }}
                disabled={isUpdatePatient}
              />
            </Form.Item>
            <Form.Item
              label={'Last Name'}
              name={'lastName'}
              rules={[{ required: true }]}
            >
              <Input
                onChange={(e) => {
                  onChange(e.target.value, 'lastName');
                }}
                disabled={isUpdatePatient}
              />
            </Form.Item>
            <Form.Item
              label={'Gender'}
              name={'gender'}
              rules={[{ required: true }]}
            >
              <Select
                options={genderOptions}
                onChange={(gender) => {
                  onChange(gender, 'gender');
                }}
              />
            </Form.Item>
            <Form.Item
              label={'Date of Birth'}
              name={'dateOfBirth'}
              rules={[{ required: true }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                onChange={(date) => {
                  const dateStr = date?.format('YYYY-MM-DD') ?? '';
                  onChange(dateStr, 'dateOfBirth');
                }}
                disabledDate={(date) => date.isAfter(dayjs())}
                disabled={isUpdatePatient}
              />
            </Form.Item>
          </FormWrapper>
          <Subtitle onClick={handleExtraFormToggle}>
            More Information{' '}
            {isOptionalFieldsShown ? (
              <CaretDownOutlined />
            ) : (
              <CaretRightOutlined />
            )}
          </Subtitle>
          {isOptionalFieldsShown && (
            <FormWrapper>
              <Form.Item label={'Mobile Number'} name={'phoneNumber'}>
                <Input
                  onChange={(e) => {
                    onChange(e.target.value, 'phoneNumber');
                  }}
                />
              </Form.Item>
              <Form.Item label={'Email'} name={'email'}>
                <Input
                  onChange={(e) => {
                    onChange(e.target.value, 'email');
                  }}
                />
              </Form.Item>
              <Form.Item label={'Residential Address'} name={'addressLine1'}>
                <Input
                  onChange={(e) => {
                    onChange(e.target.value, 'addressLine1');
                  }}
                />
              </Form.Item>
              <Form.Item
                label={'Residential Address line 2'}
                name={'addressLine2'}
              >
                <Input
                  onChange={(e) => {
                    onChange(e.target.value, 'addressLine2');
                  }}
                />
              </Form.Item>
              <Form.Item label={'Suburb'} name={'suburb'}>
                <Input
                  onChange={(e) => {
                    onChange(e.target.value, 'suburb');
                  }}
                />
              </Form.Item>
              <Form.Item label={'Postcode'} name={'postcode'}>
                <Input
                  onChange={(e) => {
                    onChange(e.target.value, 'postcode');
                  }}
                />
              </Form.Item>
              <Form.Item label={'State'} name={'state'}>
                <Select
                  options={stateOptions}
                  onChange={(state) => {
                    onChange(state, 'state');
                  }}
                />
              </Form.Item>
              <Form.Item label={'Urn'} name={'urn'}>
                <Input
                  onChange={(e) => {
                    onChange(e.target.value, 'urn');
                  }}
                />
              </Form.Item>
              <Form.Item
                label={'Medicare Card Number'}
                name={'medicareCardNumber'}
              >
                <Input
                  onChange={(e) => {
                    onChange(e.target.value, 'medicareCardNumber');
                  }}
                />
              </Form.Item>
            </FormWrapper>
          )}
        </Form>
      </Wrapper>
    </ConfigProvider>
  );
};
