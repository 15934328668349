import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Button,
  Form,
  message,
  Modal,
  Select,
  Space,
  Typography,
  Upload,
  UploadFile,
} from 'antd';
import styled from 'styled-components';
const { Text } = Typography;
import { FileItem } from '../../common/file-item';
import { generateAudioJobByPatientId } from '../../../client';
import { LetterGeneratorForm } from '../../common/letter-generator-form';
import {
  AustraliaState,
  GeneralPractitionerInputType,
  GeneralPractitionerResponseType,
  GenerateLetterWithAudioInputDataType,
  LetterType,
  PatientProfileType,
} from '../../../models';

const Container = styled.div`
  display: flex;
  column-gap: 24px;
`;
const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const FilesContainer = styled.div`
  margin-top: 12px;
  height: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const StyledUploader = styled(Upload.Dragger)`
  height: 100%;
`;
const AudioUploadIcon = styled.img.attrs({ src: '/audio-upload-icon.svg' })`
  width: 40px;
  height: 40px;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const StyledTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 8px;
  ${({ theme }) => theme.mediaQueries.small} {
    font-size: 1rem;
  }
`;
interface Props {
  data: PatientProfileType;
  isOpen: boolean;
  onClose: () => void;
  afterDataUploaded: () => void;
}
const optionalKeys = ['addressLine2', 'phoneNumber'];
export const AudioUploadModal: FC<Props> = ({
  data,
  isOpen,
  onClose,
  afterDataUploaded,
}) => {
  const uploadRef = useRef<HTMLDivElement | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalData, setModalData] = useState<
    Partial<GenerateLetterWithAudioInputDataType>
  >({
    file: files[0],
    createGpDto: {
      firstName: '',
      lastName: '',
      clinicName: '',
      addressLine1: '',
      addressLine2: '',
      suburb: '',
      postCode: '',
      state: AustraliaState.VIC,
      email: '',
      phoneNumber: '',
    },
    isSpecifyGp: false,
  });
  useEffect(() => {
    setModalData({ ...modalData, file: files[0] });
  }, [files]);
  const handleUpload = (file: UploadFile) => {
    setFiles([file]);
    return false;
  };
  const handleGpDataChange = (gpData: GeneralPractitionerResponseType) => {
    const updatedData: Partial<GenerateLetterWithAudioInputDataType> = {
      ...modalData,
      gpId: gpData.id,
      createGpDto: {
        firstName: gpData.firstName,
        lastName: gpData.lastName,
        addressLine1: gpData.addressLine1,
        addressLine2: gpData.addressLine2,
        state: gpData.state,
        suburb: gpData.suburb,
        postCode: gpData.postCode,
        clinicName: gpData.clinicName,
        email: gpData.email,
        phoneNumber: gpData.phoneNumber,
      },
    };
    setModalData(updatedData);
  };

  const generateInput = (
    data: GenerateLetterWithAudioInputDataType
  ): GenerateLetterWithAudioInputDataType => {
    if (data.gpId) {
      return {
        letterType: data.letterType,
        file: data.file,
        gpId: data.gpId,
        isSpecifyGp: data.isSpecifyGp,
      };
    } else {
      return {
        letterType: data.letterType,
        file: data.file,
        createGpDto: data.createGpDto,
        isSpecifyGp: data.isSpecifyGp,
      };
    }
  };

  const checkValidation = (
    data: Partial<GenerateLetterWithAudioInputDataType>
  ): boolean => {
    if (!data.file) return false;
    if (!data.letterType) return false;
    if (!data.isSpecifyGp) {
      return true;
    }
    if (!data.createGpDto) return false;
    return Object.keys(data.createGpDto).every((key) => {
      const isValueValid =
        !!data.createGpDto &&
        data.createGpDto[key as keyof GeneralPractitionerInputType];
      return optionalKeys.includes(key) || !!isValueValid;
    });
  };
  const handleAudioJobGenerate = async () => {
    if (checkValidation(modalData)) {
      setIsLoading(true);
      try {
        const input = generateInput(
          modalData as GenerateLetterWithAudioInputDataType
        );
        await generateAudioJobByPatientId(input, data.id);
        setModalData({
          createGpDto: {
            firstName: '',
            lastName: '',
            clinicName: '',
            addressLine1: '',
            addressLine2: '',
            suburb: '',
            postCode: '',
            state: AustraliaState.VIC,
            email: '',
            phoneNumber: '',
          },
          isSpecifyGp: false,
        });
        setFiles([]);
        afterDataUploaded();
      } catch (e) {
        messageApi.open({
          type: 'error',
          content:
            'Somthing wrong, please try to generate the letter again or refresh the page',
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      messageApi.open({
        type: 'error',
        content: 'Please finish input all the information',
      });
    }
  };
  const handleUploadButtonClick = () => {
    uploadRef.current?.click();
  };
  const handleDeleteFile = (file: UploadFile): void => {
    const updatedFiles = files.filter((item) => item.uid !== file.uid);
    setFiles(updatedFiles);
  };

  const handleSpecifyGpChange = (isSpecifyGp: boolean) =>
    setModalData({
      ...modalData,
      isSpecifyGp,
    });
  const handleLetterTypeSelect = (letterType: LetterType) => {
    setModalData({ ...modalData, letterType });
  };
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      onOk={handleAudioJobGenerate}
      okText={'Generate'}
      closable={false}
      width={850}
      centered
      okButtonProps={{ loading: isLoading }}
      destroyOnClose
    >
      {contextHolder}
      <TitleWrapper>
        <StyledTitle>Upload dictation</StyledTitle>
      </TitleWrapper>
      <Container>
        <UploadWrapper>
          <Form.Item label={'Letter type'} required>
            <Select onSelect={handleLetterTypeSelect}>
              <Select.Option value="GP_LETTER">
                Letter to Referrer
              </Select.Option>
              <Select.Option value="REFERRAL_LETTER">
                Referral letter
              </Select.Option>
            </Select>
          </Form.Item>
          <StyledUploader
            beforeUpload={handleUpload}
            multiple={false}
            showUploadList={false}
            openFileDialogOnClick={true}
            accept=".MP3, .MP4, .MPEG, .MPGA, .M4A, .WAV, .WEBM,"
          >
            <div ref={uploadRef}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Space direction="vertical">
                  {/*<AudioUploadIcon />*/}
                  <h3 style={{ margin: '8px 0' }}>Drop your dictation here</h3>
                  <Button
                    type={'primary'}
                    onClick={handleUploadButtonClick}
                    style={{ margin: '8px 0' }}
                  >
                    Upload dictation
                  </Button>
                  <div style={{ width: '300px' }}>
                    <Text type="secondary" style={{ margin: '8px 0' }}>
                      Please ensure that your audio file is in one of these
                      formats: MP3, MP4, MPEG, MPGA, M4A, WAV, or WEBM. If the
                      letter failed to generate, please ensure that your audio
                      file is in a supported format and re-upload again.
                    </Text>
                  </div>
                  <FilesContainer>
                    {files.map((file) => (
                      <FileItem
                        key={file.uid}
                        file={file}
                        onDelete={handleDeleteFile}
                      />
                    ))}
                  </FilesContainer>
                </Space>
              </div>
            </div>
          </StyledUploader>
        </UploadWrapper>

        <LetterGeneratorForm
          onChange={handleGpDataChange}
          handleSpecifyGpChange={handleSpecifyGpChange}
        />
      </Container>
    </Modal>
  );
};
