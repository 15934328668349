import { FC } from 'react';
import { letterResponseType } from '../../../models';
import styled from 'styled-components';
import { Divider } from 'antd';
import { adaptData } from './adaptData';

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-size: 14px;
  font-weight: 500;
  height: 176px;
  strong {
    display: inline-block;
    width: 100px;
    margin-right: 16px;
  }
  span {
    color: #7c81a8;
  }
`;
interface Props {
  letter?: letterResponseType;
}
export const GpInfoCard: FC<Props> = ({ letter }) => {
  const viewData = adaptData(letter);
  return (
    <Wrapper>
      <Divider />
      <Subtitle>Recipient Information</Subtitle>
      {viewData ? (
        <>
          <div>
            <strong>{viewData.title} Name: </strong>
            <span>{viewData.name}</span>
          </div>
          <div>
            <strong>Email: </strong>
            <span>{viewData.email}</span>
          </div>
          <div>
            <strong>Clinic: </strong>
            <span>{viewData.clinicName}</span>
          </div>
          <div>
            <strong>Address: </strong>
            <span>{viewData.address}</span>
          </div>
        </>
      ) : (
        <div>No recipient information provided</div>
      )}
    </Wrapper>
  );
};
