// common
import { Nullable } from '../../common/types';
import { PmsPatientOrganizationResponse } from './pms-patient-organization.response';
import { UploadFile } from 'antd';
import { PmsUserResponse } from './pms-user.response';

export type PaginationInputType = {
  page: number;
  limit: number;
};

export enum AustraliaState {
  NSW = 'NSW',
  VIC = 'VIC',
  QLD = 'QLD',
  SA = 'SA',
  WA = 'WA',
  TAS = 'TAS',
  ACT = 'ACT',
  NT = 'NT',
}

// authenticate
export interface CodeAuthenticationDto {
  code: string;
  clientId: string;
  redirectUri: string;
}

export interface CodeAuthenticationResponse {
  email: string;
  firstName: string;
  familyName: string;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  expiresIn: number;
}

export interface ReauthenticationResponse {
  accessToken: string;
  idToken: string;
  expiresIn: string;
}

export interface ReauthenticationDto {
  clientId: string;
  refreshToken: string;
}

// letter job
export type LetterSourceType = 'VOICE_FILE' | 'TEXT_NOTE';
export type LetterType = 'GP_LETTER' | 'REFERRAL_LETTER';

export type LetterDetailsResponseType = {
  generatedLetter: string;
  gpFirstName: string;
  gpLastName: string;
  id: string;
  patientFirstName: string;
  patientLastName: string;
  patientDateOfBirth: string;
  specialistNote: string;
  name: string;
  type: LetterSourceType;
};

export type GenerateLetterInputType = {
  letterType: LetterType;
  specialistNote: string;
  gpId?: string;
  createGpDto?: GeneralPractitionerInputType;
  isSpecifyGp: boolean;
};
export type GeneralPractitionerInputType = {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  suburb: string;
  postCode: string;
  state: AustraliaState;
  clinicName: string;
  email: string;
  phoneNumber: string;
};

export type GenerateLetterWithAudioInputDataType = {
  letterType: LetterType;
  file?: UploadFile;
  gpId?: string;
  createGpDto?: GeneralPractitionerInputType;
  isSpecifyGp: boolean;
};

export enum LetterGenerateJobStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  TRANSCRIBING = 'TRANSCRIBING',
  FAILED = 'FAILED',
  APPROVED = 'APPROVED',
  FINISHED = 'FINISHED',
  NULL = 'NULL',
}

export type LettersListResponseType = {
  createdDate: string;
  generatedLetter: string;
  id: string;
  notificationSentAt: Nullable<string>;
  prompt: string;
  specialistNote: string;
  status: LetterGenerateJobStatus;
  type: LetterSourceType;
  updatedDate: string;
  userId: string;
  name: string;
  patientFirstName: string;
  patientLastName: string;
  gpFirstName: string;
  gpLastName: string;
  isActive: boolean;
  patientDateOfBirth: string;
  letterType: LetterType;
};

// profile
export type ProfileResponseType = {
  lastName: string;
  firstName: string;
  phoneNumber: string;
  street: string;
  suburb: string;
  state: string;
  postCode: string;
  speciality: string;
  accreditations: string;
  additionalEmails: string[];
  providerNumber: string;
};

export type SubmitProfileInputType = {
  lastName: string;
  firstName: string;
  phoneNumber: string;
  street: string;
  suburb: string;
  state: string;
  postCode: string;
  speciality: string;
  accreditations: string;
  additionalEmails: string[];
  invitationCode?: string;
};

// feedback
export type FeedbackItemType = {
  question: string;
  answer: string;
};

export type SubmitFeedbackInputType = {
  letterGenerateJobId: string;
  feedbackItems: FeedbackItemType[];
  rating?: number;
};
export type FeedbackDataType = {
  id: string;
  specialistNote: string;
  generatedLetter: string;
  name: string;
  createdDate: string;
  letterGenerateJobId: string;
  feedbackItems: FeedbackItemType[];
  rating: Nullable<number>;
  userId: string;
};
export type FeedbackResponseType = {
  currentPage: number;
  count: number;
  data: FeedbackDataType[];
};

// patients
export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export type PatientProfileType = {
  id: string;
  urn: string;
  firstName: string;
  lastName: string;
  gender: PmsGender;
  dateOfBirth: string;
  mobileNumber: string;
  email: string;
  address1: string;
  address2?: string;
  suburb: string;
  postcode: string;
  medicareNo?: string;
  state: AustraliaState;
  organizationRelationship: PmsPatientOrganizationResponse[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
};
export type GeneralPractitionerProfileType = {
  id: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  postCode: string;
  state: AustraliaState;
  clinicName: string;
  email: string;
  phoneNumber: string;
  organizationId: string;
  createdDate: Date;
};
export type LetterSummaryType = {
  count: number;
  latestStatus: LetterGenerateJobStatus;
};
export type PatientResponseType = {
  patient: PatientProfileType;
  gpNames: Array<string>;
  gpClinics: Array<string>;
  letterSummary: LetterSummaryType;
  latestUpdatedAt: string;
};

export interface PatientsResDto {
  patients: Array<PatientResponseType>;
}

export type PatientInputType = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: PmsGender;
  urn?: string;
  phoneNumber?: string;
  email?: string;
  addressLine1?: string;
  addressLine2?: string;
  suburb?: string;
  postcode?: string;
  state?: AustraliaState;
  medicareCardNumber?: string;
};

export type GeneratePatientInputType = {
  patient: PatientInputType;
};

export type letterResponseType = {
  letter: LettersListResponseType;
  gp?: GeneralPractitionerProfileType;
  specialist: PmsUserResponse;
};

export type PatientLetterResType = {
  patient: PatientProfileType;
  letters: Array<letterResponseType>;
};

// general practitioner
export type GeneralPractitionerResponseType = {
  firstName: string;
  lastName: string;
  clinicName: string;
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  postCode: string;
  state: AustraliaState;
  id: string;
  createdDate: string;
  updatedDate: string;
  email: string;
  phoneNumber: string;
};

export type AllGPResponseType = Array<GeneralPractitionerResponseType>;

export type CreateUserInput = UpdateUserInput & {
  organizationId: string;
  roles: UserRole[];
  referralCode?: string;
};

export type UpdateUserInput = {
  firstName: string;
  lastName: string;
  gender: PmsGender;
  mobileNumber: string;
  //move email here
  email: string;
  doctor?: DoctorProfileInput;
  admin?: AdminProfileInput;
};

export type AdminProfileInput = {
  //Don't need to provide id for now
  reportTo?: string;
};
export enum UserRole {
  DOCTOR = 'DOCTOR',
  ADMIN = 'ADMIN',
  SUPERVISOR = 'SUPERVISOR',
}

export type DoctorProfileInput = {
  title: string;
  qualifications: string[];
  speciality: string;
  providerId?: string;
};

export enum Sex {
  M = 'M',
  F = 'F',
}

export enum PmsGender {
  M = 'M',
  F = 'F',
  I = 'I',
  N = 'N',
}

export interface Pagination<T> {
  data: Array<T>;
  count: number;
  currentPage: number;
  nextPage: number;
  prevPage: number;
  lastPage: number;
}

export interface SearchPatientsQueryDto {
  page: number;
  limit: number;
  sortBy?: [string, string];
  letterStatus?: LetterGenerateJobStatus[];
  patientFullName?: string;
}

export interface ReferralDetailResponseType {
  referralCode: string;
  rewardBalance: number;
  referralCount: number;
}

// statistics
type StatisticsFrequency = 'DAY' | 'MONTH';
export type getMetricsCommonInputType = {
  frequency: StatisticsFrequency;
  start: Date;
  end: Date;
};
export type GetLetterUsageResponseType = {
  date: string;
  gpLetterCount: string;
  referralLetterCount: string;
  userCount: string;
};
export type GetLetterUsageMetricsResponseType = GetLetterUsageResponseType[];

export type GetInvitedUserResponseType = {
  date: string;
  userCount: string;
};
export type GetInvitedUserMetricsResponseType = GetInvitedUserResponseType[];

export type GetPaidUserResponseType = {
  date: string;
  topUpAmount: string;
  userCount: string;
};
export type GetPaidUserMetricResponseType = GetPaidUserResponseType[];

export type TopTenUserResponseType = {
  userId: string;
  amount: string;
};
export type GetTopTenUsersMetricResponseType = TopTenUserResponseType[];
