import { client } from './client';
import { PAYMENT } from '../../const/apiPath';
import { ProductResponseType } from '../models/payment';

export const getProduct = async (): Promise<ProductResponseType[]> => {
  try {
    const { data } = await client.get(`${PAYMENT}/products`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createCheckoutSession = async (
  productId: string,
  callbackUrl = ''
) => {
  try {
    const { data } = await client.post(`${PAYMENT}/create-checkout-session`, {
      productId,
      successfulUrl: callbackUrl,
      cancelUrl: callbackUrl,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
