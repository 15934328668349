import {
  AustraliaState,
  CreateUserInput,
  PmsGender,
  UserRole,
} from '../../../models';
import { PmsCreateOrganizationDto } from '../../../models/pms-create-organization.dto';
import { transformQualificationsData } from '../../../pages/profile/adaptData';
import { QualificationType } from '../../profile/qualification-list';
import { Speciality } from '../../../../const/view';

export type PersonalRegisterFormViewType = {
  firstName: string;
  lastName: string;
  roles: UserRole[];
  gender: PmsGender;
  mobileNumber: string;
  email: string;
  title: string;
  speciality: Speciality;
  specialityDescription?: string;
  providerId?: string;
  reportTo?: string;
  referralCode?: string;
  address1: string;
  address2?: string;
  suburb: string;
  state: AustraliaState;
  faxNumber?: string;
  postcode: string;
};

export const transformPersonalOrganizationInputData = (
  data: PersonalRegisterFormViewType
): PmsCreateOrganizationDto => {
  return {
    name: `${data.firstName}.${data.lastName}`,
    email: data.email,
    phoneNumber: data.mobileNumber,
    address1: data.address1,
    address2: data.address2,
    suburb: data.suburb,
    state: data.state,
    postcode: data.postcode,
  };
};

type TransformPersonalUserInputDataType = {
  data: PersonalRegisterFormViewType;
  organizationId: string;
  qualifications?: QualificationType[];
  isAdmin?: boolean;
};

const transformUserRoles = (
  roles: UserRole[],
  isSuperAdmin = false
): UserRole[] => {
  // const result = [...roles];
  const result = [UserRole.DOCTOR];
  if (isSuperAdmin && !result.includes(UserRole.SUPERVISOR)) {
    result.push(UserRole.SUPERVISOR);
  }
  return result;
};
const transformSpeciality = (data: PersonalRegisterFormViewType): string => {
  if (data.speciality !== Speciality.OTHER) {
    return data.speciality;
  }
  return data.specialityDescription ?? '';
};
export const transformPersonalUserInputData = ({
  data,
  organizationId,
  qualifications,
  isAdmin = false,
}: TransformPersonalUserInputDataType): CreateUserInput => {
  const inputData: CreateUserInput = {
    organizationId,
    roles: transformUserRoles(data.roles, isAdmin),
    firstName: data.firstName,
    lastName: data.lastName,
    gender: data.gender,
    mobileNumber: data.mobileNumber,
    email: data.email,
  };
  if (data.referralCode) {
    inputData.referralCode = data.referralCode;
  }
  if (inputData.roles.includes(UserRole.DOCTOR)) {
    inputData.doctor = {
      title: data.title,
      speciality: transformSpeciality(data),
      providerId: data.providerId,
      qualifications: transformQualificationsData(qualifications ?? []),
    };
  }
  if (inputData.roles.includes(UserRole.ADMIN)) {
    inputData.admin = { reportTo: '' };
  }
  return inputData;
};
