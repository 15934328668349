import { PaginationInputType, SubmitFeedbackInputType } from '../models';
import { client } from './client';
import { FEEDBACK } from '../const/apiPath';

export const submitFeedback = async (data: SubmitFeedbackInputType) => {
  try {
    await client.post(FEEDBACK, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllFeedbacks = async (input: PaginationInputType) => {
  try {
    const { data } = await client.get(FEEDBACK, { params: input });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getFeedbacksByLetterJobId = async (
  letterJobId: string,
  pagination: PaginationInputType = { page: 1, limit: 1 }
) => {
  try {
    const { data } = await client.get(`${FEEDBACK}/letter-job/${letterJobId}`, {
      params: pagination,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
