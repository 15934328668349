import { ChangeEvent, FC, useCallback } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { debounce } from 'lodash';

const StyledInput = styled.input`
  border: none;
  background-color: transparent;
  &:focus-visible {
    outline: none;
  }
  margin-left: 4px;
`;

interface Props {
  onSearch: (text: string) => void;
}
export const PatientTableSearch: FC<Props> = ({ onSearch }) => {
  const handleSearch = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      onSearch(e.target?.value);
    }, 300),
    [debounce, onSearch]
  );

  return (
    <div>
      <SearchOutlined />
      <StyledInput type="text" placeholder={'Search'} onChange={handleSearch} />
    </div>
  );
};
