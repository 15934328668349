import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import { letterResponseType, PatientLetterResType } from '../../models';
import { PatientInfoCard } from '../../components/patient-detail/patient-info-card';
import { LetterList } from '../../components/details/letterList';
import { LetterEditor } from '../../components/patient-detail/letter-editor';
import { GpInfoCard } from '../../components/patient-detail/gp-info-card';
import { CommonPageTitle } from '../../components/style/layout';

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 24px;
  background-color: #f0f3f7;
  column-gap: 24px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  display: flex;
  justify-content: space-between;
`;
const InformationBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 630px;
`;
const CardWrapper = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 18px 16px;
`;

export const PatientDetail: FC = () => {
  const revalidator = useRevalidator();
  const { patient, letters } = useLoaderData() as PatientLetterResType;
  const [selectedLetter, setSelectedLetter] = useState<letterResponseType>();

  useEffect(() => {
    if (selectedLetter) {
      const target = letters.find(
        (letterItem) => letterItem.letter.id === selectedLetter.letter.id
      );
      setSelectedLetter(target);
    }
  }, [letters]);

  const handleLetterSelect = async (letter: letterResponseType) => {
    const target = letters.find(
      (letterItem) => letterItem?.letter.id === letter.letter.id
    );

    setSelectedLetter(target);
  };
  return (
    <>
      <CommonPageTitle>
        <Title>
          {patient.firstName} {patient.lastName}
        </Title>
      </CommonPageTitle>
      <Container>
        <InformationBlockWrapper>
          <CardWrapper>
            <PatientInfoCard patient={patient} />
            <GpInfoCard letter={selectedLetter} />
          </CardWrapper>

          <LetterList letters={letters} onSelect={handleLetterSelect} />
        </InformationBlockWrapper>

        <LetterEditor
          data={selectedLetter}
          patient={patient}
          afterDataUpdated={() => {
            revalidator.revalidate();
          }}
        />
      </Container>
    </>
  );
};
