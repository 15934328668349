import { LETTER_GENERATE_JOB } from '../../const/apiPath';
import { client } from './client';
import {
  GenerateLetterInputType,
  GenerateLetterWithAudioInputDataType,
  LetterDetailsResponseType,
  LetterGenerateJobStatus,
} from '../models';
import { LetterDataType } from '../view-models';
import { PatientLetters } from '../models/PatientLetters';

export enum LetterStatus {
  active = 'active',
  archive = 'archive',
}

export const getLetterById = async (
  id?: string
): Promise<LetterDetailsResponseType> => {
  try {
    const { data } = await client.get(`${LETTER_GENERATE_JOB}/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateLetter = async (input: LetterDataType, noteId?: string) => {
  try {
    const { data } = await client.patch(
      `${LETTER_GENERATE_JOB}/${noteId}`,
      input
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const generateAudioJobByPatientId = async (
  data: GenerateLetterWithAudioInputDataType,
  patientId: string
): Promise<unknown> => {
  return client.post(
    `${LETTER_GENERATE_JOB}/patient/${patientId}/audio`,
    data,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
};

export const updateLetterJobStatus = (id: string, status: LetterStatus) => {
  const isActive = status !== LetterStatus.archive;
  return client.patch(`${LETTER_GENERATE_JOB}/${id}/active`, { isActive });
};

export const generateLetterByPatientId = (
  data: GenerateLetterInputType,
  patientId: string
) => {
  return client.post(`${LETTER_GENERATE_JOB}/patient/${patientId}/note`, data);
};

export const getPatientAndLettersByPatientId = async (
  patientId: string,
  organizationId: string
): Promise<PatientLetters> => {
  try {
    const { data } = await client.get(
      `${LETTER_GENERATE_JOB}/patient/${patientId}/list`,
      {
        headers: {
          'x-organization-id': organizationId,
        },
      }
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateLetterStatus = async (
  status: LetterGenerateJobStatus,
  letterId: string,
  patientId: string
) => {
  try {
    const { data } = await client.patch(
      `${LETTER_GENERATE_JOB}/${letterId}/patient/${patientId}/status`,
      { status }
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
