import { AustraliaState, PmsGender, UserRole } from '../telecare/models';

export const stateOptions = Object.values(AustraliaState).map((stateStr) => ({
  label: stateStr,
  value: stateStr,
}));
export const genderOptions = [
  {
    label: 'Female',
    value: PmsGender.F,
  },
  {
    label: 'Male',
    value: PmsGender.M,
  },
  {
    label: 'Intersex or Indeterminate',
    value: PmsGender.I,
  },
  {
    label: 'Not Stated',
    value: PmsGender.N,
  },
];

export enum Speciality {
  ANAESTHETIST = 'Anaesthetist',
  CARDIOLOGIST = 'Cardiologist',
  CARDIOTHORACISSURGEON = 'Cardio-thoracic Surgeon',
  DERMATOLOGIST = 'Dermatologist',
  EMERGENCYMEDICINESPECIALIST = 'Emergency Medicine Specialist',
  ENDOCRINOLOGIST = 'Endocrinologist',
  GASTROENTEROLOGIST = 'Gastroenterologist',
  GENERALPRACTITIONER = 'General Practitioner',
  GENERALSURGEON = 'General Surgeon',
  GERIATRICIAN = 'Geriatrician',
  HAEMATOLOGIST = 'Haematologist',
  IMMUNOLOGIST = 'Immunologist',
  INFECIOUSDISEASESPECIALIST = 'Infectious Disease Specialist',
  NEPHROLOGIST = 'Nephrologist',
  NEUROLOGIST = 'Neurologist',
  NEUROSURGEON = 'Neurosurgeon',
  OBSTERICIANANDGYNAECOLOGIST = 'Obstetrician and Gynaecologist',
  OPHTHALMOLOGIST = 'Ophthalmologist',
  ORALANDMAXILLOFACIALSURGEON = 'Oral and Maxillofacial Surgeon',
  ORTHOPAEDICSURGEON = 'Orthopaedic Surgeon',
  OTHER = 'Other',
}

export const specialtyOptions = [
  { label: Speciality.ANAESTHETIST, value: Speciality.ANAESTHETIST },
  { label: Speciality.CARDIOLOGIST, value: Speciality.CARDIOLOGIST },
  {
    label: Speciality.CARDIOTHORACISSURGEON,
    value: Speciality.CARDIOTHORACISSURGEON,
  },
  { label: Speciality.DERMATOLOGIST, value: Speciality.DERMATOLOGIST },
  {
    label: Speciality.EMERGENCYMEDICINESPECIALIST,
    value: Speciality.EMERGENCYMEDICINESPECIALIST,
  },
  { label: Speciality.ENDOCRINOLOGIST, value: Speciality.ENDOCRINOLOGIST },
  {
    label: Speciality.GASTROENTEROLOGIST,
    value: Speciality.GASTROENTEROLOGIST,
  },
  {
    label: Speciality.GENERALPRACTITIONER,
    value: Speciality.GENERALPRACTITIONER,
  },
  {
    label: Speciality.GENERALSURGEON,
    value: Speciality.GENERALSURGEON,
  },
  {
    label: Speciality.GERIATRICIAN,
    value: Speciality.GERIATRICIAN,
  },
  { label: Speciality.HAEMATOLOGIST, value: Speciality.HAEMATOLOGIST },
  { label: Speciality.IMMUNOLOGIST, value: Speciality.IMMUNOLOGIST },
  {
    label: Speciality.INFECIOUSDISEASESPECIALIST,
    value: Speciality.INFECIOUSDISEASESPECIALIST,
  },
  { label: Speciality.NEPHROLOGIST, value: Speciality.NEPHROLOGIST },
  { label: Speciality.NEUROLOGIST, value: Speciality.NEUROLOGIST },
  { label: Speciality.NEUROSURGEON, value: Speciality.NEUROSURGEON },
  {
    label: Speciality.OBSTERICIANANDGYNAECOLOGIST,
    value: Speciality.OBSTERICIANANDGYNAECOLOGIST,
  },
  { label: Speciality.OPHTHALMOLOGIST, value: Speciality.OPHTHALMOLOGIST },
  {
    label: Speciality.ORALANDMAXILLOFACIALSURGEON,
    value: Speciality.ORALANDMAXILLOFACIALSURGEON,
  },
  {
    label: Speciality.ORTHOPAEDICSURGEON,
    value: Speciality.ORTHOPAEDICSURGEON,
  },
  { label: Speciality.OTHER, value: Speciality.OTHER },
];

export const userRolesOptions = [
  {
    label: 'Doctor',
    value: UserRole.DOCTOR,
  },
  {
    label: 'Admin',
    value: UserRole.ADMIN,
  },
  {
    label: 'Super Admin',
    value: UserRole.SUPERVISOR,
  },
];
