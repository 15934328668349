import { createContext, useContext, useEffect, useState } from 'react';
import { BalanceTokenDataType } from '../../view-models/balanceToken';
import { Outlet, useLoaderData, useRevalidator } from 'react-router-dom';
import { BalanceTokenResponseType } from '../models/balanceToken';

type ContextValueType = {
  balanceToken: BalanceTokenDataType;
  refreshBalance: () => void;
};
const BalanceTokenContext = createContext<ContextValueType>(
  {} as ContextValueType
);

export const TokenBalanceProvider = () => {
  const tokenBalanceResponse = useLoaderData() as BalanceTokenResponseType;
  const { revalidate } = useRevalidator();
  const [balanceToken, setBalanceToken] =
    useState<BalanceTokenDataType>(tokenBalanceResponse);
  useEffect(() => {
    setBalanceToken(tokenBalanceResponse);
  }, [tokenBalanceResponse]);
  const contextValue = {
    balanceToken,
    refreshBalance: revalidate,
  };
  return (
    <BalanceTokenContext.Provider value={contextValue}>
      <Outlet />
    </BalanceTokenContext.Provider>
  );
};

export const useTokenBalanceContext = () => useContext(BalanceTokenContext);
