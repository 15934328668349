import { REFERRAL } from '../../const/apiPath';
import { client } from './client';
import { ReferralDetailResponseType } from '../models';

export const getReferralDetails =
  async (): Promise<ReferralDetailResponseType> => {
    try {
      const { data } = await client.get(`${REFERRAL}`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };
