import { client } from './client';
import { TOKEN } from '../../const/apiPath';
import {
  BalanceTokenResponseType,
  TokenUsageResponseType,
} from '../models/balanceToken';
import { Pagination } from '../models';

export const getBalances = async (): Promise<BalanceTokenResponseType> => {
  try {
    const { data } = await client.get(`${TOKEN}/balances`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUsageHistory = async (
  page = 1,
  limit = 10
): Promise<Pagination<TokenUsageResponseType>> => {
  try {
    const { data } = await client.get(`${TOKEN}/history`, {
      params: { page, limit },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
