import { FC } from 'react';
import { Button, Form, FormInstance, Input, Select } from 'antd';
import { genderOptions, stateOptions } from '../../../const/view';
import { FooterWrapper, FormSectionWrapper, PageTitle } from './styled';

interface Props {
  form: FormInstance<unknown>;
  showExtraInfo?: boolean;
  goNext: () => void;
  goBack: () => void;
}
export const PersonalInfoPage: FC<Props> = ({
  form,
  showExtraInfo,
  goNext,
  goBack,
}) => {
  const handleValidate = async () => {
    try {
      await form.validateFields([
        'firstName',
        'lastName',
        'gender',
        'mobileNumber',
        'email',
        'address1',
        'suburb',
        'state',
        'postcode',
      ]);
      goNext();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <PageTitle>Tell us a bit about yourself</PageTitle>
      <FormSectionWrapper>
        <Form.Item
          label={'First name'}
          name={'firstName'}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Last name'}
          name={'lastName'}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Gender'}
          name={'gender'}
          rules={[{ required: true }]}
        >
          <Select options={genderOptions}></Select>
        </Form.Item>
        <Form.Item
          label={'Phone number'}
          name={'mobileNumber'}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={'Email'} name={'email'} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        {showExtraInfo && (
          <>
            <Form.Item
              label={'Address line 1'}
              name={'address1'}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={'Address line 2'} name={'address2'}>
              <Input />
            </Form.Item>
            <Form.Item
              label={'Suburb'}
              name={'suburb'}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={'State'}
              name={'state'}
              rules={[{ required: true }]}
            >
              <Select options={stateOptions} />
            </Form.Item>
            <Form.Item
              label={'Postcode'}
              name={'postcode'}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </>
        )}
      </FormSectionWrapper>
      <FooterWrapper>
        <Button size={'large'} onClick={goBack}>
          Back
        </Button>
        <Button size={'large'} type={'primary'} onClick={handleValidate}>
          Next
        </Button>
      </FooterWrapper>
    </div>
  );
};
