import React from 'react';
import styled from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const HeaderWrapper = styled.div`
  background-color: #0552f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 24px;
`;
const Wrapper = styled.div`
  h1,
  h2,
  h3,
  h4 {
    display: inline-block;
    width: 100%;
    color: #0552f0;
    font-weight: bolder;
  }
`;
const Container = styled.div`
  background-color: #f3f3f6;
  padding: 24px 10%;
`;
const Title = styled.h4`
  font-size: 22px;
  margin: 0;
`;
const Section = styled.div`
  margin: 16px 0;
  color: #8385a9;
  ol li,
  ul li {
    margin: 16px 0;
  }
`;
const Text = styled.div`
  color: #8385a9;
`;
const BackButtonContentWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;
export const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeaderWrapper>
        <img
          src="/telecare-logo-light.svg"
          alt="Telecare Logo"
          width={150}
          height={'auto'}
        />
      </HeaderWrapper>
      <Button
        type={'link'}
        size={'large'}
        onClick={() => {
          navigate('/list');
        }}
        style={{ padding: 0, marginLeft: '48px' }}
      >
        <BackButtonContentWrapper>
          <LeftOutlined />
          Dashboard
        </BackButtonContentWrapper>
      </Button>
      <Wrapper>
        <h1 style={{ textAlign: 'center' }}>
          VESC HEALTH PTY LTD – PRIVACY POLICY
        </h1>
        <Container>
          <Section>
            VESC Health PTY LTD (ABN 31668744054) (we, us or our), understands
            that protecting your personal information is important. This Privacy
            Policy sets out our commitment to protecting the privacy of personal
            information provided to us, or collected by us, when interacting
            with you.
          </Section>
          <Title>The Services</Title>
          <Section>
            AutoTranscribe is a software as a service platform whereby doctors
            may input notes they have taken during or after a consultation with
            a patient, whether in audio or text format, for the purposes of
            generating a clinical letter or other forms of communication. Any
            notes inputted in the platform must be de-identified in accordance
            with the Privacy Act and Office of the Australian Information
            Commissioner (OAIC) guidelines. The notes should only contain
            information where there is no reasonable likelihood of
            re-identification occurring. Once the letter has been generated
            using our large-language-model API (LLM-API), the patient’s details
            can be inserted before the letter is finalised.
          </Section>
          <Title>The information we collect</Title>
          <Section>
            Personal information: is information or an opinion, whether true or
            not and whether recorded in a material form or not, about an
            individual who is identified or reasonably identifiable.
          </Section>
          <Section>
            The types of personal information we may collect about you include:
            <ul>
              <li>
                dentity Data including your name, age, profession, Australian
                Health Practitioner Regulation Agency Number, the name of your
                clinic and gender.
              </li>
              <li>
                Contact Data including your telephone number, address and email.
              </li>
              <li>
                Financial Data including bank account and payment card details
                (through our third party payment processor, who stores such
                information and we do not have access to that information).
              </li>
              <li>
                Transaction Data including details about payments to you from us
                and from you to us and other details of products and services
                you have purchased from us or we have purchased from you.
              </li>
              <li>
                Technical and Usage Data when you access any of our websites or
                platforms, details about your internet protocol (IP) address,
                login data, browser session and geo-location data, statistics on
                page views and sessions, device and network information,
                acquisition sources, search queries and/or browsing behaviour,
                access and use of our website (including through the use of
                Internet cookies or analytics), and communications with our
                website.
              </li>
              <li>
                Profile Data including your username and password for
                AutoTranscribe, profile picture, purchases or orders you have
                made with us, and support requests you have made.{' '}
              </li>
              <li>
                Marketing and Communications Data including your preferences in
                receiving marketing from us and our third parties and your
                communication preferences.
              </li>
              <li>
                Professional data including where you are a worker of ours or
                applying for a role with us, your professional history such as
                your previous positions and professional experience.
              </li>
              <li>
                ensitive information is a sub-set of personal information that
                is given a higher level of protection. Sensitive information
                means information relating to your racial or ethnic origin,
                political opinions, religion, trade union or other professional
                associations or memberships, philosophical beliefs, sexual
                orientation or practices, criminal records, health information
                or biometric information. The types of sensitive information we
                collect include:
                <ul>
                  <li>Medical history;</li>
                  <li>
                    Medical profile including age, gender, weight, height,
                    exercise history;
                  </li>
                  <li>
                    Treatment information including, differential diagnoses and
                    treatment advice; and
                  </li>
                  <li>
                    Information regarding medicines and contraindications.
                  </li>
                </ul>
              </li>
            </ul>
          </Section>
          <Title>How we collect personal information</Title>
          <Section>
            We collect personal information in a variety of ways, including:
            <ul>
              <li>
                when you provide it directly to us, including on our platform,
                over the phone, over email, or online;
              </li>
              <li>
                when you use any website we operate (including from any
                analytics and cookie providers or marketing providers. See the
                “Cookies” section below for more detail on the use of cookies);
              </li>
              <li>from third parties, such as doctors; or</li>
              <li>from publicly available sources. </li>
            </ul>
          </Section>
          <Title>
            Why we collect, hold, use and disclose personal information
          </Title>
          <Section>
            Personal information: We collect, hold, use and disclose your
            personal information for the following purposes:
            <ul>
              <li>
                where you are a doctor, to enable you to access and use our
                software, including to provide you with a login;
              </li>
              <li>
                where you are a patient, to enable your doctor to use our
                software, including to keep a record of your consultation and
                input that information into the clinical letter once it is
                generated on the software;
              </li>
              <li>
                to contact and communicate with you about our business,
                including in response to any support requests you lodge with us
                or other enquiries you make with us;
              </li>
              <li>
                to contact and communicate with you about any enquiries you make
                with us via any website we operate;
              </li>
              <li>
                for internal record keeping, administrative, invoicing and
                billing purposes;
              </li>
              <li>
                or analytics, market research and business development,
                including to operate and improve our business, associated
                applications and associated social media platforms;
              </li>
              <li>
                for advertising and marketing, including to send you promotional
                information about our events and experiences and information
                that we consider may be of interest to you;
              </li>
              <li>
                if you have applied for employment with us, to consider your
                employment application; and
              </li>
              <li>
                to comply with our legal obligations or if otherwise required or
                authorised by law.
              </li>
            </ul>
          </Section>
          <Section>
            Sensitive information: We only collect, hold, use and disclose
            sensitive information for the following purposes:
            <li>any purposes you consent to;</li>
            <li>
              the primary purpose for which it is collected, to keep a record of
              your details which can be inputted into the clinical letter
              generated by our software;
            </li>
            <li>
              secondary purposes that are directly related to the primary
              purpose for which it was collected, including disclosure to the
              below listed third parties as reasonably necessary to do business
              with you;
            </li>
            <li>
              to contact emergency services, or to speak with your family,
              partner or support person where we reasonably believe there is a
              serious risk to the life, health or safety of you or another
              person and it is impracticable for us to obtain your consent; and
            </li>
            <li>if otherwise required or authorised by law.</li>
          </Section>
          <Title>
            Our disclosures of personal information to third parties
          </Title>
          <Section>
            Personal information: We will only disclose personal information
            (excluding sensitive information) to third parties where it is
            necessary as part of our business, where we have your consent, or
            where permitted by law. This means that we may disclose personal
            information (excluding sensitive information) to:
            <ul>
              <li>our employees, contractors and/or related entities;</li>
              <li>
                IT service providers, data storage, web-hosting and server
                providers;
              </li>
              <li>marketing or advertising providers;</li>
              <li>
                professional advisors, bankers, auditors, our insurers and
                insurance brokers;
              </li>
              <li>payment systems operators or processors;</li>
              <li>our existing or potential agents or business partners;</li>
              <li>
                if we merge with, or are acquired by, another company, or sell
                all or a portion of our assets, your personal information may be
                disclosed to our advisers and any prospective purchaser’s
                advisers and may be among the assets transferred;
              </li>
              <li>
                courts, tribunals and regulatory authorities, in the event you
                fail to pay for goods or services we have provided to you;
              </li>
              <li>
                courts, tribunals, regulatory authorities and law enforcement
                officers, as required or authorised by law, in connection with
                any actual or prospective legal proceedings, or in order to
                establish, exercise or defend our legal rights;
              </li>
              <li>
                third parties to collect and process data, such as analytics
                providers and cookies; and
              </li>
              <li>
                any other third parties as required or permitted by law, such as
                where we receive a subpoena.
              </li>
            </ul>
          </Section>
          <Section>
            Sensitive information: We will only disclose sensitive information
            with your consent or where permitted by law. This means that we may
            disclose sensitive information to:
            <ul>
              <li>our employees, contractors and/or related entities;</li>
              <li>
                IT service providers, data storage, web-hosting and server
                providers;
              </li>
              <li>professional advisors;</li>
              <li>
                if we merge with, or are acquired by, another company, or sell
                all or a portion of our assets, your personal information may be
                disclosed to our advisers and any prospective purchaser’s
                advisers and may be among the assets transferred;
              </li>
              <li>
                courts, tribunals, regulatory authorities and law enforcement
                officers, as required or authorised by law, in connection with
                any actual or prospective legal proceedings, or in order to
                establish, exercise or defend our legal rights;
              </li>
              <li>
                third parties to collect and process data, such as analytics
                providers and cookies; and
              </li>
              <li>
                any other third parties as required or permitted by law, such as
                where we receive a subpoena.
              </li>
            </ul>
          </Section>
          <Section>
            Google Analytics: We have enabled Google Analytics Advertising
            Features. We and third-party vendors may use first-party cookies
            (such as the Google Analytics cookie) or other first-party
            identifiers, and third-party cookies (such as Google advertising
            cookies) or other third-party identifiers together. These cookies
            and identifiers may collect Technical and Usage Data about you.
          </Section>
          <Section>
            You can opt-out of Google Analytics Advertising Features including
            using a Google Analytics Opt-out Browser add-on found{' '}
            <a href="https://tools.google.com/dlpage/gaoptout/">here</a>. To
            opt-out of personalised ad delivery on the Google content network,
            please visit Google’s Ads Preferences Manager{' '}
            <a href="https://adssettings.google.com/anonymous?hl=en">here</a> or
            if you wish to opt-out permanently even when all cookies are deleted
            from your browser you can install their plugin{' '}
            <a href="https://support.google.com/My-Ad-Center-Help/answer/12155656?visit_id=638338429839389681-653384546&rd=1">
              here
            </a>
            . To opt out of interest-based ads on mobile devices, please follow
            these instructions for your mobile device: On android open the
            Google Settings app on your device and select “ads” to control the
            settings. On iOS devices with iOS 6 and above use Apple’s
            advertising identifier. To learn more about limiting ad tracking
            using this identifier, visit the settings menu on your device.
          </Section>
          <Section>
            To find out how Google uses data when you use third party websites
            or applications, please see{' '}
            <a href="https://policies.google.com/technologies/partner-sites">
              here
            </a>
            .
          </Section>
          <Title>Overseas disclosure</Title>
          <Section>
            We store your personal information in Australia. Where we disclose
            your personal information to third parties, those third parties may
            store, transfer or access personal information outside of Australia,
            including but not limited to the United States of America. We will
            only disclose your personal information overseas in accordance with
            the Australian Privacy Principles.
          </Section>
          <Title>Your rights and controlling your personal information</Title>
          <Section>
            Your choice: Please read this Privacy Policy carefully. If you
            provide personal information to us, you understand we will collect,
            hold, use and disclose your personal information in accordance with
            this Privacy Policy. You do not have to provide personal information
            to us, however, if you do not, it may affect our ability to do
            business with you.
          </Section>
          <Section>
            Information from third parties: If we receive personal information
            about you from a third party, we will protect it as set out in this
            Privacy Policy. If you are a third party providing personal
            information about somebody else, you represent and warrant that you
            have such person’s consent to provide the personal information to
            us.
          </Section>
          <Section>
            Restrict and unsubscribe: To object to processing for direct
            marketing/unsubscribe from our email database or opt-out of
            communications (including marketing communications), please contact
            us using the details below or opt-out using the opt-out facilities
            provided in the communication.
          </Section>
          <Section>
            Access: You may request access to the personal information that we
            hold about you. An administrative fee may be payable for the
            provision of such information. Please note, in some situations, we
            may be legally permitted to withhold access to your personal
            information. If we cannot provide access to your information, we
            will advise you as soon as reasonably possible and provide you with
            the reasons for our refusal and any mechanism available to complain
            about the refusal. If we can provide access to your information in
            another form that still meets your needs, then we will take
            reasonable steps to give you such access.
          </Section>
          <Section>
            Correction: If you believe that any information we hold about you is
            inaccurate, out of date, incomplete, irrelevant or misleading,
            please contact us using the details below. We will take reasonable
            steps to promptly correct any information found to be inaccurate,
            out of date, incomplete, irrelevant or misleading. Please note, in
            some situations, we may be legally permitted to not correct your
            personal information. If we cannot correct your information, we will
            advise you as soon as reasonably possible and provide you with the
            reasons for our refusal and any mechanism available to complain
            about the refusal.
          </Section>
          <Section>
            Complaints: If you wish to make a complaint, please contact us using
            the details below and provide us with full details of the complaint.
            We will promptly investigate your complaint and respond to you, in
            writing, setting out the outcome of our investigation and the steps
            we will take in response to your complaint. If you are not satisfied
            with our response, you may contact the Office of the Australian
            Information Commissioner.
          </Section>
          <Title>Storage and security</Title>
          <Section>
            We are committed to ensuring that the personal information we
            collect is secure. In order to prevent unauthorised access or
            disclosure, we have put in place suitable physical, electronic and
            managerial procedures, to safeguard and secure personal information
            and protect it from misuse, interference, loss and unauthorised
            access, modification and disclosure.
          </Section>
          <Section>
            While we are committed to security, we cannot guarantee the security
            of any information that is transmitted to or by us over the
            Internet. The transmission and exchange of information is carried
            out at your own risk.
          </Section>
          <Title>Cookies</Title>
          <Section>
            We may use cookies on our website from time to time. Cookies are
            text files placed in your computer's browser to store your
            preferences. Cookies, by themselves, do not tell us your email
            address or other personally identifiable information. However, they
            do recognise you when you return to our online website and allow
            third parties to cause our advertisements to appear on your social
            media and online media feeds as part of our retargeting campaigns.
            If and when you choose to provide our online website with personal
            information, this information may be linked to the data stored in
            the cookie.
          </Section>
          <Section>
            You can block cookies by activating the setting on your browser that
            allows you to refuse the setting of all or some cookies. However, if
            you use your browser settings to block all cookies (including
            essential cookies) you may not be able to access all or parts of our
            website.{' '}
          </Section>
          <Title>Links to other websites</Title>
          <Section>
            Our website may contain links to other party’s websites. We do not
            have any control over those websites and we are not responsible for
            the protection and privacy of any personal information which you
            provide whilst visiting those websites. Those websites are not
            governed by this Privacy Policy.
          </Section>
          <Title>Amendments</Title>
          <Section style={{ marginBottom: '24px' }}>
            We may, at any time and at our discretion, vary this Privacy Policy
            by publishing the amended Privacy Policy on our website. We
            recommend you check our website regularly to ensure you are aware of
            our current Privacy Policy.
          </Section>
          <Text>For any questions or notices, please contact us at:</Text>
          <Text>VESC Health Pty Ltd (ABN 31 668 744 054)</Text>
          <Text style={{ marginBottom: '24px' }}>
            Email: jason.liu@telecare.com.au
          </Text>
          <Section>
            ©<a href="https://legalvision.com.au/">LegalVision ILP Pty Ltd </a>
          </Section>
        </Container>
      </Wrapper>
    </>
  );
};
