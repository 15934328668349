import { FC, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button, Popover } from 'antd';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../client';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../../../const/localStorageKey';
import { useUserProfileContext } from '../../../providers/UserProfileProvider';

const UserButton = styled.div`
  border: 1px solid #dae6f2;
  border-radius: 24px 8px 8px 24px;
  background-color: #f0f3f7;
  padding: 0 16px 0 8px;
  height: 40px;
  display: flex;
  column-gap: 8px;
  align-items: center;
  cursor: pointer;
`;
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const MenuButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;
const MenuButton = styled(Button).attrs({ type: 'link', block: true })`
  color: black;
`;
export const ProfileMenu: FC = () => {
  const [isMenuShown, setIsMenuShown] = useState(false);
  const {
    userProfile,
    userProfile: { firstName },
  } = useUserProfileContext();

  const UserMenu: FC = () => {
    const navigate = useNavigate();
    const handleLogout = async () => {
      await logout();
      localStorage.setItem(ACCESS_TOKEN, '');
      localStorage.setItem(REFRESH_TOKEN, '');
      navigate('/signIn');
    };
    return (
      <MenuWrapper>
        <MenuButtonsWrapper>
          {!!userProfile && (
            <MenuButton
              onClick={() => {
                setIsMenuShown(false);
                navigate('/user');
              }}
            >
              My Profile
            </MenuButton>
          )}
          <MenuButton
            onClick={() => {
              setIsMenuShown(false);
              navigate('/reward');
            }}
          >
            My Rewards
          </MenuButton>
          <MenuButton onClick={handleLogout}>Log out</MenuButton>
        </MenuButtonsWrapper>
      </MenuWrapper>
    );
  };

  return (
    <Popover
      open={isMenuShown}
      onOpenChange={setIsMenuShown}
      content={<UserMenu />}
      placement={'bottomRight'}
      trigger="click"
      arrow={false}
    >
      <UserButton>
        <UserOutlined style={{ color: '#0552F0' }} />
        {firstName}
      </UserButton>
    </Popover>
  );
};
