import {
  GetLetterUsageMetricsResponseType,
  GetInvitedUserMetricsResponseType,
  GetPaidUserMetricResponseType,
} from '../../models';
import { format } from 'date-fns';

export type MetricsType = 'letterUsage' | 'paidUsers' | 'invitedUsers';

export type StatisticsFrequency = 'MONTH' | 'DAY';

export type UsageGraphViewType = Array<{
  date: string;
  gpLetterCount: number | null;
  referralLetterCount: number | null;
  averageLetterCount: number | null;
  userCount: number | null;
}>;

const getDaysBetweenDates = ([startDate, endDate]: [Date, Date]): Date[] => {
  const daysArray = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    daysArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return daysArray;
};
const getMonthsBetweenDates = ([startDate, endDate]: [Date, Date]): Date[] => {
  const monthsArray = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    monthsArray.push(new Date(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return monthsArray;
};
export const generateDates = (
  duration?: [Date, Date],
  frequency?: StatisticsFrequency
): string[] => {
  if (duration && frequency) {
    const formatStr = frequency === 'DAY' ? 'dd-MM-yyyy' : 'MM-yyyy';
    const getDatesFunction =
      frequency === 'DAY' ? getDaysBetweenDates : getMonthsBetweenDates;
    return getDatesFunction(duration).map((date) => format(date, formatStr));
  }
  return [];
};

const getDate = (date: string, frequency: StatisticsFrequency): string => {
  if (frequency === 'DAY') return format(new Date(date), 'dd-MM-yyyy');
  else {
    const year = parseInt(date.substring(0, 4), 10);
    const month = parseInt(date.substring(4, 6), 10);

    const dateObject = new Date(year, month - 1, 1);
    return format(dateObject, 'MM-yyyy');
  }
};

export const adaptLetterUsageData = (
  data: GetLetterUsageMetricsResponseType,
  frequency: StatisticsFrequency,
  duration: [Date, Date]
): UsageGraphViewType => {
  const formattedData = data.map((item) => ({
    date: getDate(item.date, frequency),
    gpLetterCount: Number(item.gpLetterCount),
    referralLetterCount: Number(item.referralLetterCount),
    userCount: Number(item.userCount),
    averageLetterCount:
      (Number(item.referralLetterCount) + Number(item.gpLetterCount)) /
      Number(item.userCount),
  }));
  const allDates = generateDates(duration, frequency);
  return allDates.map((date) => {
    const targetDataItem = formattedData.find(
      (dataItem) => dataItem.date === date
    );
    if (targetDataItem) {
      return targetDataItem;
    } else {
      return {
        date,
        gpLetterCount: null,
        referralLetterCount: null,
        averageLetterCount: null,
        userCount: null,
      };
    }
  });
};

export type PaidUserGraphViewType = Array<{
  date: string;
  topUpAmount: number | null;
  paidUserCount: number | null;
}>;
export const adaptPaidUserData = (
  data: GetPaidUserMetricResponseType,
  frequency: StatisticsFrequency,
  duration: [Date, Date]
): PaidUserGraphViewType => {
  const formattedData = data.map((item) => ({
    ...item,
    date: getDate(item.date, frequency),
  }));
  const allDates = generateDates(duration, frequency);
  return allDates.map((date) => {
    const targetDataItem = formattedData.find(
      (dataItem) => dataItem.date === date
    );
    if (targetDataItem) {
      return {
        ...targetDataItem,
        topUpAmount: Number(targetDataItem.topUpAmount),
        paidUserCount: Number(targetDataItem.userCount),
      };
    } else {
      return {
        date,
        topUpAmount: null,
        paidUserCount: null,
      };
    }
  });
};

export type InvitedUserGraphViewType = Array<{
  date: string;
  invitedUserCount: number | null;
}>;
export const adaptInvitedUserData = (
  data: GetInvitedUserMetricsResponseType,
  frequency: StatisticsFrequency,
  duration: [Date, Date]
): InvitedUserGraphViewType => {
  const formattedData = data.map((item) => ({
    ...item,
    date: getDate(item.date, frequency),
  }));
  const allDates = generateDates(duration, frequency);
  return allDates.map((date) => {
    const targetDataItem = formattedData.find(
      (dataItem) => dataItem.date === date
    );
    if (targetDataItem) {
      return {
        ...targetDataItem,
        invitedUserCount: Number(targetDataItem.userCount),
      };
    } else {
      return {
        date,
        invitedUserCount: null,
      };
    }
  });
};
