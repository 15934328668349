import { CreateUserInput, PmsGender, UserRole } from '../../../models';
import { QualificationType } from '../../profile/qualification-list';

type PracticeRegisterUserFormViewType = {
  roles: UserRole[];
  firstName: string;
  lastName: string;
  gender: PmsGender;
  mobileNumber: string;
  email: string;
  title: string;
  speciality: string;
  providerId: string;
  referralCode?: string;
};
type TransformCreateUserInputDataType = {
  data: PracticeRegisterUserFormViewType;
  qualifications: QualificationType[];
  organizationId: string;
  referralCode?: string;
};

export const transformQualificationsData = (
  qualifications: QualificationType[]
): string[] => {
  const validQualifications = qualifications.filter(
    (qualification) => !!qualification.value
  );
  return validQualifications.map((qualification) => qualification.value);
};

const transformUserRoles = (roles: UserRole[]): UserRole[] => {
  const result = [...roles];
  if (!result.includes(UserRole.SUPERVISOR)) {
    result.push(UserRole.SUPERVISOR);
  }
  return result;
};
export const transformCreateUserInputData = ({
  data,
  qualifications,
  organizationId,
}: TransformCreateUserInputDataType): CreateUserInput => {
  const inputData: CreateUserInput = {
    organizationId,
    // roles: transformUserRoles(data.roles),
    roles: [UserRole.SUPERVISOR, UserRole.DOCTOR],
    firstName: data.firstName,
    lastName: data.lastName,
    gender: data.gender,
    mobileNumber: data.mobileNumber,
    email: data.email,
  };
  if (data.referralCode) {
    inputData.referralCode = data.referralCode;
  }
  if (inputData.roles.includes(UserRole.DOCTOR)) {
    inputData.doctor = {
      title: data.title,
      qualifications: transformQualificationsData(qualifications),
      speciality: data.speciality,
      providerId: data.providerId,
    };
  }
  if (inputData.roles.includes(UserRole.ADMIN)) {
    inputData.admin = { reportTo: '' };
  }
  return inputData;
};
