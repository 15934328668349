import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ConfigProvider, FloatButton, Input, message } from 'antd';
import { FileTextTwoTone, QuestionCircleOutlined } from '@ant-design/icons';
import {
  FeedbackItemType,
  LetterGenerateJobStatus,
  letterResponseType,
  PatientProfileType,
} from '../../../models';
import { adaptData, LetterEditorDataType } from './adaptData';
import { generatePDF, submitFeedback, updateLetter } from '../../../client';
import { useUserProfileContext } from '../../../providers/UserProfileProvider';
import { dateFormat } from '../../../../utils';
import { PDFModal } from '../pdf-modal';
import { FeedbackSurveyModal } from '../../common/feedback-survey-modal';
import { getFeedbacksByLetterJobId } from '../../../../client';
import { updateLetterStatus } from '../../../client/letter';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: white;
  border-radius: 16px;
`;
const Container = styled.div`
  display: flex;
  flex: 1;
`;
const InputWrapper = styled.div`
  flex: 1;
  display: flex;
`;
const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
`;

const EditorWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #f0f3f7;
  width: 100%;
`;

const PlaceholderText = styled.div`
  width: 191px;
  margin-top: 24px;
  text-align: center;
  h4 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    margin-top: 0;
  }
  span {
    font-size: 14px;
    color: #7c81a8;
  }
`;

const EditableInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  background-color: #f0f3f7;
  padding: 16px;
  width: 100%;
  flex: 1;
  border-radius: 16px 16px 0 0;
`;

const EditableInputHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 700;
`;

const patientNameReg = /\[Patient Name]/gi;
const patientDOBReg = /\[Patient DOB]/gi;
const patientAddressReg = /\[Patient Address]/gi;
const patientPhoneReg = /\[Patient Phone]/gi;
const patientEmailReg = /\[Patient Email]/gi;
const fullNameReg = /\[Specialist Name]/gi;
const addressReg = /\[Specialist Address]/gi;
const titleReg = /\[Specialist Title]/gi;
const dateReg = /\[Date]/gi;
const doctorNameReg = /\[[Doctor Name]]/gi;

interface Props {
  data?: letterResponseType;
  patient: PatientProfileType;
  afterDataUpdated: () => void;
}

const unfinishedStatus = [
  LetterGenerateJobStatus.FAILED,
  LetterGenerateJobStatus.PENDING,
  LetterGenerateJobStatus.TRANSCRIBING,
];
export const LetterEditor: FC<Props> = ({
  data,
  patient,
  afterDataUpdated,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { userProfile } = useUserProfileContext();
  const [pageData, setPageData] = useState<LetterEditorDataType>(() =>
    adaptData({
      patient,
      letter: data?.letter,
      gp: data?.gp,
      specialist: data?.specialist,
      organization: userProfile.organization,
    })
  );
  useEffect(() => {
    setPageData(
      adaptData({
        patient,
        letter: data?.letter,
        gp: data?.gp,
        specialist: data?.specialist,
        organization: userProfile.organization,
      })
    );
  }, [data, patient, userProfile]);
  const { TextArea } = Input;
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [PDFData, setPDFData] = useState<string | null>(null);
  const [isFeedbackExist, setIsFeedbackExist] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  useEffect(() => {
    if (data) {
      getFeedbacksByLetterJobId(data.letter.id).then((res) => {
        setIsFeedbackExist(!!res);
      });
    }
  }, [data]);

  const handleAutoFill = () => {
    const formattedLetter = pageData.generatedLetter
      .replace(patientNameReg, pageData.patientFullName)
      .replace(patientDOBReg, pageData.patientDateOfBirth)
      .replace(patientAddressReg, pageData.patientAddress ?? 'patient address')
      .replace(patientPhoneReg, pageData.patientPhone ?? 'patient phone')
      .replace(patientEmailReg, pageData.patientEmail ?? 'patient email')
      .replace(titleReg, pageData.specialistTitle)
      .replace(fullNameReg, pageData.specialistFullName)
      .replace(addressReg, pageData.specialistAddress)
      .replace(dateReg, dateFormat(Date()))
      .replace(doctorNameReg, pageData.gpFullName);
    setPageData({ ...pageData, generatedLetter: formattedLetter });
  };

  const copyLetterText = () => {
    navigator.clipboard.writeText(pageData.generatedLetter);
    messageApi.open({
      type: 'success',
      content: 'Content has been successfully copied to the clipboard.',
    });
  };
  const handleCopy = () => {
    copyLetterText();
  };
  const handleSave = async () => {
    setIsSaving(true);
    try {
      await updateLetter(pageData, data?.letter.id);
      setIsEditing(false);
      afterDataUpdated();
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };
  const handlePDFModalOpen = async (): Promise<void> => {
    if (data) {
      setIsPDFModalOpen(true);
      const PDFData = await generatePDF(data.letter.id);
      setPDFData(PDFData);
    }
  };
  const updateStatus = (status: LetterGenerateJobStatus) => async () => {
    if (data) {
      setIsUpdatingStatus(true);
      try {
        await updateLetterStatus(
          status,
          data.letter.id,
          'c1069640-8b0a-44c3-a2fd-400b930d16e4'
        );
        afterDataUpdated();
      } finally {
        setIsUpdatingStatus(false);
      }
    }
  };
  const handleFeedbackSubmit = async (
    feedback: FeedbackItemType[],
    rating?: number
  ) => {
    if (data) {
      try {
        await submitFeedback({
          letterGenerateJobId: data.letter.id,
          feedbackItems: feedback,
          rating,
        });
        setIsFeedbackModalOpen(false);
        setIsFeedbackExist(true);
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleCancel = () => {
    const { generatedLetter } = adaptData({
      patient,
      letter: data?.letter,
      gp: data?.gp,
      specialist: data?.specialist,
      organization: userProfile.organization,
    });
    setPageData({ ...pageData, generatedLetter });
    setIsEditing(false);
  };
  const FeedbackModalTitle = () => (
    <div>
      <p>
        Your input plays a central role in the ongoing journey of advancement
        and refinement of AutoTranscribe.
      </p>
      <p>
        Each piece of feedback you provide contributes significantly to
        identifying areas for improvement, introducing features, and ensuring
        the user experience is continuously enriched.
      </p>
    </div>
  );
  const renderFooterButtonGroup = (
    isEditing: boolean,
    status?: LetterGenerateJobStatus
  ): JSX.Element => {
    return (
      <Footer>
        {isEditing && (
          <Button
            block
            disabled={!data}
            onClick={handleAutoFill}
            size={'large'}
          >
            Auto fill
          </Button>
        )}
        {isEditing && (
          <Button
            block
            danger
            disabled={!data}
            onClick={handleCancel}
            size={'large'}
          >
            Cancel
          </Button>
        )}
        {isEditing && (
          <Button
            block
            type={'primary'}
            disabled={!data}
            onClick={handleSave}
            size={'large'}
          >
            Save
          </Button>
        )}
        {!isEditing && status && !unfinishedStatus.includes(status) && (
          <>
            <Button
              block
              disabled={!data}
              type={'primary'}
              onClick={updateStatus(LetterGenerateJobStatus.APPROVED)}
              size={'large'}
              loading={isUpdatingStatus}
            >
              Approve
            </Button>
            <Button
              block
              disabled={!data}
              type={'primary'}
              onClick={updateStatus(LetterGenerateJobStatus.FINISHED)}
              size={'large'}
              loading={isUpdatingStatus}
            >
              Finalise
            </Button>
          </>
        )}
        {!isEditing && (
          <Button block disabled={!data} size={'large'} onClick={handleCopy}>
            Copy
          </Button>
        )}
        {!isEditing && (
          <Button
            block
            disabled={!data}
            onClick={handlePDFModalOpen}
            size={'large'}
          >
            Download as PDF
          </Button>
        )}
        {!isEditing && (
          <Button
            block
            disabled={!data}
            loading={isSaving}
            onClick={() => {
              setIsEditing(true);
            }}
            size={'large'}
          >
            Edit
          </Button>
        )}
      </Footer>
    );
  };
  return (
    <Wrapper>
      {contextHolder}
      <Container>
        <EditorWrapper>
          <InputWrapper>
            {data ? (
              <EditableInputWrapper>
                <EditableInputHeader>
                  Generated Item
                  <div>
                    <Button
                      type={'link'}
                      href={'/privacy-policy'}
                      target={'_blank'}
                    >
                      Privacy Policy
                    </Button>
                    <Button
                      type={'link'}
                      href={'/terms-and-conditions'}
                      target={'_blank'}
                    >
                      Terms and Conditions
                    </Button>
                  </div>
                </EditableInputHeader>
                <ConfigProvider
                  theme={{
                    token: {
                      colorBgContainerDisabled: '#f0f3f7',
                      colorTextDisabled: '#171D4D',
                    },
                  }}
                >
                  <TextArea
                    style={{
                      resize: 'none',
                      border: 'none',
                      boxShadow: 'none',
                      height: '100%',
                    }}
                    disabled={!isEditing}
                    value={pageData.generatedLetter}
                    onChange={(e) => {
                      setPageData({
                        ...pageData,
                        generatedLetter: e.target.value,
                      });
                    }}
                    onCopy={(e) => {
                      !isFeedbackExist && e.preventDefault();
                    }}
                  />
                </ConfigProvider>
              </EditableInputWrapper>
            ) : (
              <PlaceholderWrapper>
                <FileTextTwoTone style={{ fontSize: '72px' }} />
                <PlaceholderText>
                  <h4>Letter view empty</h4>
                </PlaceholderText>
              </PlaceholderWrapper>
            )}
          </InputWrapper>
          {renderFooterButtonGroup(isEditing, data?.letter.status)}
        </EditorWrapper>
      </Container>
      <PDFModal
        data={data?.letter}
        pdfPath={PDFData}
        isOpen={isPDFModalOpen}
        setIsOpen={setIsPDFModalOpen}
      />
      <FeedbackSurveyModal
        description={<FeedbackModalTitle />}
        isOpen={isFeedbackModalOpen}
        onClose={setIsFeedbackModalOpen}
        onSubmit={handleFeedbackSubmit}
      />
      <FloatButton
        icon={<QuestionCircleOutlined />}
        type={'primary'}
        style={{ left: '90px', bottom: '32px' }}
        onClick={() => {
          setIsFeedbackModalOpen(true);
        }}
      />
    </Wrapper>
  );
};
