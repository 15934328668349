import { LETTER_GENERATE_JOB } from '../const/apiPath';
import { client } from './client';
import {
  GenerateLetterInputType,
  GenerateLetterWithAudioInputDataType,
  LetterDetailsResponseType,
  LettersListResponseType,
} from '../models';
import { LetterDataType } from '../view-models';

export enum LetterStatus {
  active = 'active',
  archive = 'archive',
}
export const getLettersList = async (
  status?: LetterStatus
): Promise<LettersListResponseType[]> => {
  try {
    const { data } = await client.get(`${LETTER_GENERATE_JOB}/list`, {
      params: { isActive: status !== LetterStatus.archive },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const generateLetter = (data: GenerateLetterInputType) => {
  return client.post(`${LETTER_GENERATE_JOB}`, {
    ...data,
  });
};

export const getLetterById = async (
  id?: string
): Promise<LetterDetailsResponseType> => {
  try {
    const { data } = await client.get(`${LETTER_GENERATE_JOB}/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateLetter = async (input: LetterDataType, noteId?: string) => {
  try {
    const { data } = await client.patch(
      `${LETTER_GENERATE_JOB}/${noteId}`,
      input
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const generateAudioJob = async (
  data: GenerateLetterWithAudioInputDataType
): Promise<unknown> => {
  return client.post(`${LETTER_GENERATE_JOB}/audio`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateLetterJobStatus = (id: string, status: LetterStatus) => {
  const isActive = status !== LetterStatus.archive;
  return client.patch(`${LETTER_GENERATE_JOB}/${id}/active`, { isActive });
};
