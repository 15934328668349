import { FC, useState } from 'react';
import styled from 'styled-components';
import { CustomizedRadio } from '../../components/register/customized-radio';
import { Button } from 'antd';
import { PersonRegisterForm } from '../../components/register/person-register-form';
import { PracticeRegisterForm } from '../../components/register/practice-register-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClauseModal } from '../../components/common/clause-modal';
import { ApartmentOutlined, UserOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  width: 700px;
  margin: 80px auto 0;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-top: 12px;
`;
const RadioWrapper = styled.div`
  flex: 1;
  display: flex;
  column-gap: 24px;
  align-items: center;
`;
const Description = styled.div`
  color: dimgray;
`;
const RadioContentWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  font-size: 18px;
  justify-content: center;
`;
export const Register: FC = () => {
  const location = useLocation();
  const organizationId = new URLSearchParams(location.search).get(
    'organization-id'
  );
  const navigate = useNavigate();
  const [type, setType] = useState<'person' | 'practice'>();
  const [showForm, setShowForm] = useState<boolean>(!!organizationId);

  const handleRegisterSucceed = () => {
    navigate('/');
  };
  const renderForm = () =>
    type !== 'practice' ? (
      <PersonRegisterForm
        organizationId={organizationId}
        afterSubmit={handleRegisterSucceed}
        onExit={() => {
          setShowForm(false);
        }}
      />
    ) : (
      <PracticeRegisterForm
        afterSubmit={handleRegisterSucceed}
        onExit={() => {
          setShowForm(false);
        }}
      />
    );
  const showRegisterForm = (type: 'person' | 'practice') => {
    setType(type);
    setShowForm(true);
  };

  /*return (
    <>
      <ClauseModal initialState={false} />
      {!showForm ? (
        <Wrapper>
          <h1 style={{ fontSize: '48px' }}>Who are you signing up for?</h1>
          <ContentWrapper>
            <RadioWrapper>
              <CustomizedRadio
                onClick={() => {
                  showRegisterForm('person');
                }}
              >
                <RadioContentWrapper>
                  <UserOutlined />
                  Myself
                </RadioContentWrapper>
              </CustomizedRadio>
              <Description>
                I am a solo practitioner / prefer to sign up as an individual
                user
              </Description>
            </RadioWrapper>

            <RadioWrapper>
              <CustomizedRadio
                onClick={() => {
                  showRegisterForm('practice');
                }}
              >
                <RadioContentWrapper>
                  <ApartmentOutlined />
                  My Practice
                </RadioContentWrapper>
              </CustomizedRadio>
              <Description>
                I am a solo practitioner / prefer to sign up as an individual
                user
              </Description>
            </RadioWrapper>
          </ContentWrapper>
        </Wrapper>
      ) : (
        renderForm()
      )}
    </>
  );*/
  return renderForm();
};
