import { createContext, useContext, useEffect, useState } from 'react';
import {
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
  useRevalidator,
} from 'react-router-dom';
import { PmsUserResponse } from '../models/pms-user.response';
import { deleteClientHeader, updateClientHeader } from '../client/client';

type ContextValueType = {
  userProfile: PmsUserResponse;
  refreshUserProfile: () => void;
};

const UserProfileContext = createContext<ContextValueType>(
  {} as ContextValueType
);
export const UserProfileProvider = () => {
  const userProfile = useLoaderData() as PmsUserResponse;
  const { revalidate } = useRevalidator();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [shouldShowChildren, setShouldShowChildren] = useState(false);

  useEffect(() => {
    const organizationId = userProfile?.organization?.id;
    if (organizationId) {
      updateClientHeader({
        key: 'x-organization-id',
        value: organizationId,
      });
    } else {
      deleteClientHeader('x-organization-id');
    }
  }, [userProfile]);
  useEffect(() => {
    setShouldShowChildren(true);
    if (pathname !== '/register') {
      !userProfile && navigate('/register');
    } else {
      userProfile && navigate('/');
    }
  }, [pathname, userProfile]);
  const contextValue = {
    userProfile,
    refreshUserProfile: revalidate,
  };
  return (
    <UserProfileContext.Provider value={contextValue}>
      {shouldShowChildren && <Outlet />}
    </UserProfileContext.Provider>
  );
};

export const useUserProfileContext = () => useContext(UserProfileContext);
