export const LETTER_GENERATE_JOB = `/letter-generate-job`;
export const MEDICAL_AUDIO = '/medical-audio';
export const AUTH = `/auth`;
export const PROFILE = '/profile';

export const FEEDBACK = '/feedback';
export const PDF_SERVICE = '/pdf';

export const PATIENTS = '/patient';

export const GP = '/general-practitioner';

export const USER = '/user';

export const ORGANIZATION = '/organization';

export const PAYMENT = '/payment';

export const TOKEN = '/token';

export const REFERRAL = '/referral-detail';

export const STATISTICS = '/statistics';
