import { FC, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const ItemWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;
const AddButton = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
  cursor: pointer;
`;

const AddButtonWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: flex-end;
`;

export type QualificationType = {
  id: number;
  value: string;
};
interface Props {
  qualifications: QualificationType[];
  onChange: (emails: QualificationType[]) => void;
}
export const QualificationsList: FC<Props> = ({ qualifications, onChange }) => {
  const [internalQualifications, setInternalQualifications] =
    useState<QualificationType[]>(qualifications);
  useEffect(() => {
    onChange(internalQualifications);
  }, [internalQualifications]);
  const handleAddQualification = () => {
    const lastId = internalQualifications[internalQualifications.length - 1].id;
    setInternalQualifications([
      ...internalQualifications,
      { id: lastId + 1, value: '' },
    ]);
  };
  const handleDeleteQualification = (id: number) => {
    const updatedQualifications = [...internalQualifications].filter(
      (qualification) => qualification.id !== id
    );
    setInternalQualifications(updatedQualifications);
  };
  const handleQualificationChange = (qualification: string, id: number) => {
    const qualificationsCopy = [...internalQualifications];
    const targetIndex = qualificationsCopy.findIndex(
      (qualification) => qualification.id === id
    );
    qualificationsCopy[targetIndex].value = qualification;
    setInternalQualifications(qualificationsCopy);
  };
  return (
    <>
      {internalQualifications.map(({ id, value }, index) => (
        <Form.Item
          label={index === 0 ? 'Qualification' : ''}
          name={`qualification${id}`}
          key={id}
          rules={[{ required: true, message: 'Please enter Qualification' }]}
        >
          <ItemWrapper>
            <Input
              value={value}
              onChange={(e) => {
                handleQualificationChange(e.target.value, id);
              }}
            />
            {index !== 0 && (
              <MinusCircleOutlined
                style={{ position: 'absolute', right: '-24px' }}
                onClick={() => {
                  handleDeleteQualification(id);
                }}
              />
            )}
          </ItemWrapper>
        </Form.Item>
      ))}
      <AddButtonWrapper>
        <AddButton onClick={handleAddQualification}>
          <PlusCircleOutlined /> Add more
        </AddButton>
      </AddButtonWrapper>
    </>
  );
};
