import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';

// Legacy pages - can be removed after new version completed
import { SignIn } from '../pages/sign-in';
import { Auth } from '../pages/auth';
import { TermsAndConditions } from '../pages/terms-and-conditions';
import { PrivacyPolicy } from '../pages/privacy-policy';

// New pages
import { PatientDetail } from '../telecare/pages/patient-details';
import { Error as Error2 } from '../telecare/pages/error';
import { Profile as Profile2 } from '../telecare/pages/profile';
import { FeedbackOverview as FeedbackOverview2 } from '../telecare/pages/feedback-overview';
import { Layout as Layout2 } from '../telecare/layout';
import { Patients } from '../telecare/pages/patients';
import {
  getBalances,
  getPatientAndLettersByPatientId,
  getReferralDetails,
  getProduct,
  getUser,
} from '../telecare/client';
import { UserProfileProvider as UserProfileProvider2 } from '../telecare/providers/UserProfileProvider';
import { TermsAndConditions as TermsAndConditions2 } from '../telecare/pages/terms-and-conditions';
import { PrivacyPolicy as PrivacyPolicy2 } from '../telecare/pages/privacy-policy/intex';
import { Organization } from '../telecare/pages/organization';
import { StartUp } from '../telecare/pages/start-up';
import { ErrorBoundary } from '../telecare/components/common/error-boundary';
import { TokenBalanceProvider } from '../telecare/providers/TokenBalanceProvider';
import { Balance } from '../telecare/pages/balance';
import { Reward } from '../telecare/pages/reward';
import { RouteGuard } from './RouteGuard';
import { Register } from '../telecare/pages/register';
import { Statistics } from '../telecare/pages/statistics';

const routesOnEnv = (
  <Route path={'/'} errorElement={<ErrorBoundary />}>
    <Route element={<RouteGuard />}>
      <Route index element={<Navigate to={'/patients'} />} />
      <Route element={<UserProfileProvider2 />} loader={getUser}>
        <Route element={<TokenBalanceProvider />} loader={getBalances}>
          <Route element={<Layout2 />}>
            <Route
              index
              path={'patients'}
              element={<Patients />}
              errorElement={<Error2 />}
            />
            <Route
              path={'patient-detail/:patientId'}
              element={<PatientDetail />}
              loader={({ params }) =>
                getPatientAndLettersByPatientId(params.patientId ?? '', '')
              }
              errorElement={<Error2 />}
            />
            <Route
              path={'user'}
              element={<Profile2 />}
              errorElement={<Error2 />}
            />
            <Route
              path={'register'}
              element={<Register />}
              errorElement={<Error2 />}
            />
            <Route
              path={'reward'}
              loader={getReferralDetails}
              element={<Reward />}
              errorElement={<Error2 />}
            />
            <Route path={'/privacy-policy'} element={<PrivacyPolicy2 />} />
            <Route
              path={'/terms-and-conditions'}
              element={<TermsAndConditions2 />}
            />
            <Route
              path={'feedbackOverview'}
              element={<FeedbackOverview2 />}
              errorElement={<Error2 />}
            />
            <Route
              path={'organisation'}
              element={<Organization />}
              errorElement={<Error2 />}
            />
            <Route
              path={'start-up'}
              element={<StartUp />}
              errorElement={<Error2 />}
            />
            <Route
              path={'balance'}
              loader={getProduct}
              element={<Balance />}
              errorElement={<Error2 />}
            />
            <Route
              path={'statistics'}
              element={<Statistics />}
              errorElement={<Error2 />}
            />
          </Route>
        </Route>
      </Route>

      <Route path={'/signIn'} element={<SignIn />} />
      <Route path={'/auth'} element={<Auth />} />
      <Route path={'/privacyPolicy'} element={<PrivacyPolicy />} />
      <Route path={'/termsAndConditions'} element={<TermsAndConditions />} />
    </Route>
  </Route>
);

export const routerRoutes = createBrowserRouter(
  createRoutesFromElements(routesOnEnv)
);
