import { FC } from 'react';
import { Alert } from 'antd';
import styled from 'styled-components';

const AlertWrapper = styled.div`
  position: absolute;
  top: 48px;
  left: calc(50% - 200px);
`;

interface Props {
  initialState: boolean;
}
export const TopUpAlert: FC<Props> = ({ initialState }) => {
  return (
    <AlertWrapper>
      {initialState && (
        <Alert
          showIcon
          closable
          message={'You are running out of balance, please top up first'}
          type={'warning'}
        />
      )}
    </AlertWrapper>
  );
};
