import { client } from './client';
import { CreateUserInput, UpdateUserInput } from '../models';
import { USER } from '../../const/apiPath';
import { PmsUserResponse } from '../models/pms-user.response';

export const getUser = async (): Promise<PmsUserResponse> => {
  try {
    const { data } = await client.get(`${USER}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createUser = async (
  createUserInput: CreateUserInput
): Promise<PmsUserResponse> => {
  try {
    const { data } = await client.post(`${USER}`, createUserInput);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateUser = async (
  id: string,
  updateUserInput: UpdateUserInput
): Promise<PmsUserResponse> => {
  try {
    const { data } = await client.patch(`${USER}/${id}`, updateUserInput);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
