import React, { FC, useEffect, useState } from 'react';
import { ProductsDataType } from '../../../../view-models/payment';
import { Modal } from 'antd';
import styled from 'styled-components';
import { getProduct } from '../../../client';
import { ProductCard } from './ProductCard';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  description?: string;
}

const ProductsWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  column-gap: 32px;
  justify-content: center;
  padding: 24px;
`;
const DescriptionWrapper = styled.div`
  margin: 16px auto;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

// const adaptData = (data: ProductResponseType[]) => {};

export const ChargeModal: FC<Props> = ({ isOpen, setIsOpen, description }) => {
  const [products, setProducts] = useState<ProductsDataType>([]);
  useEffect(() => {
    getProduct().then((productsResponse) => {
      setProducts(productsResponse);
    });
  }, []);
  return (
    <Modal
      width={900}
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
      }}
      footer={null}
      destroyOnClose
    >
      <DescriptionWrapper>{description}</DescriptionWrapper>
      <ProductsWrapper>
        {products.map((product) => (
          <ProductCard key={product.id} data={product} />
        ))}
      </ProductsWrapper>
    </Modal>
  );
};
