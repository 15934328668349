import styled from 'styled-components';

export const FormPageWrapper = styled.div`
  padding: 32px;
  width: 1200px;
  margin: 32px auto;
`;

interface StatusSelectorProps {
  selected?: boolean;
}
export const StatusSelector = styled.div<StatusSelectorProps>`
  width: 300px;
  border-radius: 12px;
  padding: 14px;
  cursor: pointer;
  text-align: center;
  border: 2px dashed;
  font-size: 18px;
  background-color: ${({ selected = false }) => (selected ? 'blue' : 'white')};
  color: ${({ selected = false }) => (selected ? 'white' : 'inherit')};
  border: ${({ selected = false }) =>
    selected ? '2px solid blue' : '2px dash'};
  &:hover {
    background-color: blue;
    color: white;
    border: 2px solid blue;
  }
`;

interface VisibilityWrapperPropos {
  isVisible: boolean;
}
export const VisibilityWrapper = styled.div<VisibilityWrapperPropos>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

export const FormSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
`;

export const PageTitle = styled.div`
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 700;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  column-gap: 18px;
  position: relative;
  left: 80%;
`;
