import { FC, MouseEventHandler, useState } from 'react';
import { Button, Modal } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { LetterDetailsResponseType } from '../../../models';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { PDFDocumentProxy } from 'pdfjs-dist';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Wrapper = styled.div`
  height: 700px;
  overflow-y: auto;
`;
interface FloatingWrapperProps {
  isVisible?: boolean;
}
const FloatingWrapper = styled.div<FloatingWrapperProps>`
  position: absolute;
  left: 50%;
  bottom: 10%;
  z-index: 10;
  visibility: ${({ isVisible = false }) => (isVisible ? 'visible' : 'hidden')};
`;

const ButtonGroupWrapper = styled.div`
  position: relative;
  right: 50%;
  display: flex;
  column-gap: 8px;
  align-items: center;
  background-color: white;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-radius: 8px;
`;

interface Props {
  data?: LetterDetailsResponseType;
  pdfPath: string | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
export const PDFModal: FC<Props> = ({ data, pdfPath, isOpen, setIsOpen }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showPagination, setShowPagination] = useState(false);
  const handleDownloadClick: MouseEventHandler<HTMLAnchorElement> = (
    e
  ): void => {
    if (!pdfPath) {
      e.preventDefault();
    }
  };
  const handleDocumentLoadSuccess = ({
    numPages: nextNumPages,
  }: PDFDocumentProxy) => {
    setPageNumber(nextNumPages);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
      }}
      width={1200}
      title={'PDF review'}
      footer={
        <a
          href={pdfPath ?? ''}
          download={`${data?.name}.pdf`}
          onClick={handleDownloadClick}
        >
          <Button type={'primary'} disabled={!pdfPath}>
            Download
          </Button>
        </a>
      }
    >
      <Wrapper>
        {pdfPath ? (
          <div
            onMouseOver={() => {
              setShowPagination(true);
            }}
            onMouseLeave={() => {
              setShowPagination(false);
            }}
          >
            <Document file={pdfPath} onLoadSuccess={handleDocumentLoadSuccess}>
              <Page
                key={`page_${currentPage + 1}`}
                pageNumber={currentPage + 1}
                width={600}
                scale={1.5}
              />
            </Document>
            <FloatingWrapper isVisible={showPagination}>
              <ButtonGroupWrapper>
                <Button
                  type={'text'}
                  size={'large'}
                  disabled={currentPage === 0}
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                >
                  Prev
                </Button>
                <strong>
                  {currentPage + 1} / {pageNumber}
                </strong>
                <Button
                  type={'text'}
                  size={'large'}
                  disabled={currentPage === pageNumber - 1}
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                >
                  Next
                </Button>
              </ButtonGroupWrapper>
            </FloatingWrapper>
          </div>
        ) : (
          <div>loading...</div>
        )}
      </Wrapper>
    </Modal>
  );
};
