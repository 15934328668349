import styled from 'styled-components';

const SubSection = styled.p`
  padding-left: 16px;
`;
const BoldText = styled.span`
  font-weight: 700;
`;
export const ReferralCodeGuide = () => (
  <div>
    <p>Welcome to your AutoTranscribe Rewards dashboard.</p>
    <p>Here's how it works:</p>
    <SubSection>
      1.{' '}
      <BoldText>
        Share your referral code with your friends, colleagues or network.
      </BoldText>
    </SubSection>
    <SubSection>
      2.{' '}
      <BoldText>
        Once they sign up to AutoTranscribe with your referral code, you will
        receive 20 Letters and your referee will receive 10 on top of their free
        trial.
      </BoldText>
    </SubSection>
    <SubSection>
      3.{' '}
      <BoldText>
        Letters earned through the referral program will expire after 1 month.
      </BoldText>
    </SubSection>
  </div>
);
