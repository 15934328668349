import { FC, useState } from 'react';
import { Button, Checkbox, Modal } from 'antd';
import { PrivacyPolicy } from '../privacy-policy';
import styled from 'styled-components';
import { TermsAndConditions } from '../terms-and-conditions';

const FooterWrapper = styled.div`
  margin: 16px auto;
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
`;
interface Props {
  initialState: boolean;
}
export const ClauseModal: FC<Props> = ({ initialState }) => {
  const [page, setPage] = useState<number>(1);
  const [isOpen, setIsOpen] = useState<boolean>(initialState);
  const [checked, setChecked] = useState<boolean>(false);
  const renderContent = (): JSX.Element => {
    if (page === 1) return <PrivacyPolicy />;
    if (page === 2) return <TermsAndConditions />;
    return <div />;
  };
  const renderFooter = (): JSX.Element => {
    const checkboxContent =
      page === 1
        ? 'I have read and agree to the Privacy Policy.'
        : 'I have read and accept the Terms and Conditions.';
    const buttonContent = page === 1 ? 'Continue' : 'Finish';
    return (
      <FooterWrapper>
        <Checkbox
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
        >
          {checkboxContent}
        </Checkbox>
        <Button
          type={'primary'}
          disabled={!checked}
          onClick={handleConfirmClick}
        >
          {buttonContent}
        </Button>
      </FooterWrapper>
    );
  };
  const handleConfirmClick = () => {
    if (page === 1) {
      setPage(2);
    }
    if (page === 2) {
      setIsOpen(false);
    }
    setChecked(false);
    document.getElementById('clause-modal-content-top')?.scrollIntoView();
  };
  return (
    <Modal
      open={isOpen}
      closable={false}
      maskClosable={false}
      width={1200}
      footer={null}
    >
      <div id={'clause-modal-content-top'}></div>
      {renderContent()}
      {renderFooter()}
    </Modal>
  );
};
