import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AutoComplete, ConfigProvider, Form, Switch } from 'antd';
import {
  AllGPResponseType,
  AustraliaState,
  GeneralPractitionerResponseType,
} from '../../../models';
import { getAllGp } from '../../../client';
import { GeneralPractitionerForm } from './GeneralPractitionerForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  min-width: 350px;
`;

interface Props {
  onChange: (gpData: GeneralPractitionerResponseType) => void;
  handleSpecifyGpChange: (isSpecifyGp: boolean) => void;
}
type GpPotionType = {
  label: string;
  value: string;
};
export const LetterGeneratorForm: FC<Props> = ({
  onChange,
  handleSpecifyGpChange,
}) => {
  const [gpOptions, setGpOptions] = useState<AllGPResponseType>([]);
  const [filterGpOptions, setFilterGpOptions] = useState<GpPotionType[]>([]);
  const [selectedGpName, setSelectedGpName] = useState<string>();
  const [isFormLocked, setIsFormLocked] = useState<boolean>(false);
  const [selectedGp, setSelectedGp] = useState<GeneralPractitionerResponseType>(
    {
      firstName: '',
      lastName: '',
      clinicName: '',
      addressLine1: '',
      addressLine2: '',
      suburb: '',
      postCode: '',
      state: AustraliaState.VIC,
      email: '',
      phoneNumber: '',
      id: '',
      createdDate: '',
      updatedDate: '',
    }
  );
  const [isSpecifyGp, setIsSpecifyGp] = useState<boolean>(false);
  useEffect(() => {
    onChange(selectedGp);
  }, [selectedGp]);
  useEffect(() => {
    const getGpOptions = async () => {
      const response = await getAllGp();
      const options = response.map((gp) => ({
        label: gp.firstName + ' ' + gp.lastName,
        value: gp.id,
      }));
      setGpOptions(response);
      setFilterGpOptions(options);
    };
    getGpOptions();
  }, []);
  const handleSelect = (value: string, option: GpPotionType) => {
    const selectedGpInformation = gpOptions.find((gp) => gp.id === value);
    if (selectedGpInformation) {
      setSelectedGpName(option.label);
      setSelectedGp(selectedGpInformation);
      setIsFormLocked(true);
    }
  };
  const handleSearch = (text: string) => {
    const filterOptions = gpOptions.filter(
      (option) =>
        option.firstName.toLowerCase().includes(text.toLowerCase()) ||
        option.lastName.toLowerCase().includes(text.toLowerCase())
    );
    const formattedFilterOptions = filterOptions.map((gp) => ({
      label: gp.firstName + ' ' + gp.lastName,
      value: gp.id,
    }));
    setFilterGpOptions(formattedFilterOptions);
  };
  const handleGpDataChange = (
    value: string,
    key: keyof GeneralPractitionerResponseType
  ): void => {
    const updatedGpData: GeneralPractitionerResponseType = {
      ...selectedGp,
      [key]: value,
      id: '',
    };
    setSelectedGp(updatedGpData);
  };
  const handleSelectedGpChange = (name: string) => {
    !name && setIsFormLocked(false);
    setSelectedGpName(name);
  };
  const handleClear = () => {
    setSelectedGp({ ...selectedGp, id: '' });
    setIsFormLocked(false);
  };

  const onSpecifyGpChange = (e: boolean) => {
    setIsSpecifyGp(e);
    handleSpecifyGpChange(e);
  };

  return (
    <ConfigProvider theme={{ components: { Form: { itemMarginBottom: 8 } } }}>
      <Wrapper>
        <Form.Item label="Provide recipient information">
          <Switch
            checked={isSpecifyGp}
            onChange={(e) => onSpecifyGpChange(e)}
            unCheckedChildren={'No'}
            checkedChildren={'Yes'}
          />
        </Form.Item>
        {isSpecifyGp && (
          <>
            <Form.Item label={'Practitioner lookup'}>
              <AutoComplete
                value={selectedGpName}
                options={filterGpOptions}
                onChange={handleSelectedGpChange}
                onSelect={handleSelect}
                onSearch={handleSearch}
                onClear={handleClear}
                allowClear
              />
            </Form.Item>

            <GeneralPractitionerForm
              data={selectedGp}
              onChange={handleGpDataChange}
              disabled={isFormLocked}
            />
          </>
        )}
      </Wrapper>
    </ConfigProvider>
  );
};
