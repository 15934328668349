import { FC } from 'react';
import { GeneralPractitionerInputType, AustraliaState } from '../../../models';
import { ConfigProvider, Form, Input, Select } from 'antd';

interface Props {
  data: GeneralPractitionerInputType;
  onChange: (value: string, key: keyof GeneralPractitionerInputType) => void;
  disabled?: boolean;
}
export const GeneralPractitionerForm: FC<Props> = ({
  data,
  onChange,
  disabled = false,
}) => {
  const stateOptions = Object.values(AustraliaState).map((stateStr) => ({
    label: stateStr,
    value: stateStr,
  }));

  return (
    <ConfigProvider theme={{ components: { Form: { itemMarginBottom: 8 } } }}>
      <Form layout={'vertical'}>
        <Form.Item label={'First Name'} required>
          <Input
            value={data.firstName}
            onChange={(e) => {
              onChange(e.target.value, 'firstName');
            }}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label={'Last Name'} required>
          <Input
            value={data.lastName}
            onChange={(e) => {
              onChange(e.target.value, 'lastName');
            }}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label={'Clinic Name'} required>
          <Input
            value={data.clinicName}
            onChange={(e) => {
              onChange(e.target.value, 'clinicName');
            }}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label={'Address Line 1'} required>
          <Input
            value={data.addressLine1}
            onChange={(e) => {
              onChange(e.target.value, 'addressLine1');
            }}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label={'Address Line 2'}>
          <Input
            value={data.addressLine2}
            onChange={(e) => {
              onChange(e.target.value, 'addressLine2');
            }}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label={'Suburb'} required>
          <Input
            value={data.suburb}
            onChange={(e) => {
              onChange(e.target.value, 'suburb');
            }}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label={'Postcode'} required>
          <Input
            value={data.postCode}
            onChange={(e) => {
              onChange(e.target.value, 'postCode');
            }}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label={'State'} required>
          <Select
            value={data.state}
            options={stateOptions}
            onChange={(state) => {
              onChange(state, 'state');
            }}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label={'Phone Number'}>
          <Input
            value={data.phoneNumber}
            onChange={(e) => {
              onChange(e.target.value, 'phoneNumber');
            }}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label={'Email'} required>
          <Input
            value={data.email}
            onChange={(e) => {
              onChange(e.target.value, 'email');
            }}
            disabled={disabled}
          />
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
};
