import { ReactNode } from 'react';
import { ThemeProvider as DefaultThemeProvider } from 'styled-components';

interface ThemeProviderProps {
  children: ReactNode;
}

const screenSize = {
  sm: '640px',
  md: '768px',
};
const theme = {
  mediaQueries: {
    small: `@media screen and (max-width: ${screenSize.sm})`,
    medium: `@media screen and (max-width: ${screenSize.md})`,
  },
};

export const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <DefaultThemeProvider theme={theme}>{children}</DefaultThemeProvider>
);
