import { FC } from 'react';

import { ConfigProvider, Table } from 'antd';

import styled from 'styled-components';
import {
  LetterGenerateJobStatus,
  letterResponseType,
  LettersListResponseType,
  LetterType,
} from '../../../models';
import {
  dateFormat,
  getLetterStatusView,
  getLetterTypeView,
  StatusProps,
} from '../../../../utils';

type CustomizedTagProps = Omit<StatusProps, 'displayValue'>;
const CustomizedTag = styled.div<CustomizedTagProps>`
  display: inline-block;
  border-radius: 5px;
  padding: 0 5px;
  color: ${({ fontColor }) => fontColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
const Wrapper = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 16px;
`;
const Container = styled.div`
  height: 220px;
  overflow-y: auto;
`;

const Row = styled.tr`
  &.pointer-row {
    cursor: pointer;
  }
`;
const EmptyTextWrapper = styled.div`
  height: 272px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const LetterNameTd = styled.div`
  max-width: 145px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  letters: Array<letterResponseType>;
  onSelect: (letter: letterResponseType) => void;
}

export const LetterList: FC<Props> = ({ letters, onSelect }) => {
  const EmptyText = () => <EmptyTextWrapper>No data</EmptyTextWrapper>;
  const successfulState = [
    LetterGenerateJobStatus.COMPLETED,
    LetterGenerateJobStatus.FINISHED,
    LetterGenerateJobStatus.APPROVED,
  ];
  const handleRowClick = (record: LettersListResponseType) => ({
    onClick: () => {
      if (successfulState.includes(record.status)) {
        const data = letters.find((letter) => letter.letter === record);
        data && onSelect(data);
      }
    },
  });

  const getRowClassName = (record: LettersListResponseType) =>
    successfulState.includes(record.status) ? 'pointer-row' : '';

  const components = {
    body: {
      row: Row,
    },
  };

  const statusColumn = {
    dataIndex: 'status',
    render: (status: LetterGenerateJobStatus) => {
      const { displayValue, backgroundColor, fontColor } =
        getLetterStatusView(status);
      return (
        <CustomizedTag backgroundColor={backgroundColor} fontColor={fontColor}>
          {displayValue}
        </CustomizedTag>
      );
    },
  };
  const LetterTypeColumn = {
    dataIndex: 'letterType',
    render: (type: LetterType) => {
      const { displayValue, backgroundColor, fontColor } =
        getLetterTypeView(type);
      return (
        <CustomizedTag backgroundColor={backgroundColor} fontColor={fontColor}>
          {displayValue}
        </CustomizedTag>
      );
    },
  };

  const basicColumns = [
    {
      dataIndex: 'name',
      render: (letterName: string) => (
        <LetterNameTd title={letterName}>{letterName}</LetterNameTd>
      ),
    },
    LetterTypeColumn,
    {
      dataIndex: 'createdDate',
      render: (dateStr: string) => {
        return dateFormat(dateStr, true);
      },
    },
    statusColumn,
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: '#F0F3F7',
        },
        components: {
          Table: { borderColor: 'white' },
        },
      }}
    >
      <Wrapper>
        <Container>
          <Table
            showHeader={false}
            dataSource={letters.map((letter) => letter.letter) ?? []}
            columns={basicColumns}
            rowKey={(record) => record.id}
            rowClassName={getRowClassName}
            onRow={handleRowClick}
            components={components}
            locale={{
              emptyText: <EmptyText />,
            }}
            pagination={false}
          />
        </Container>
      </Wrapper>
    </ConfigProvider>
  );
};
