import React, { FC, useEffect } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { ACCESS_TOKEN } from '../../const/localStorageKey';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;
const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 4;
  background: linear-gradient(
      0deg,
      rgba(5, 82, 240, 0.08) 0%,
      rgba(5, 82, 240, 0.08) 100%
    ),
    #fff;
`;
const RightSection = styled.div`
  flex: 6;
  background-color: #0552f0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  row-gap: 24px;
`;
const ContentWrapper = styled.div`
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Description = styled.span`
  font-size: 18px;
  color: #7b7b7b;
`;
const StyledButton = styled(Button)`
  width: 224px;
  height: 50px;
  border-radius: 16px;
  background-color: #0552f0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  span {
    font-size: 24px;
    color: white;
    border-bottom: none;
  }
`;
const StyledLink = styled.a`
  color: #0552f0;
  text-decoration: none;
`;
export const SignIn: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      navigate('/');
    }
  }, []);
  const signInUrl = `${process.env.REACT_APP_COGNITO_AUTH_URI}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`;
  const signUpUrl = `${process.env.REACT_APP_COGNITO_AUTH_URI}/signup?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`;
  return (
    <Wrapper>
      <LeftSection>
        <img
          src="/telecare-logo-dark.svg"
          alt="Telecare Logo"
          width={'80%'}
          height={'auto'}
        />
        <ContentWrapper>
          <ButtonContainer>
            <StyledButton href={signInUrl}>Sign in</StyledButton>
            <Description>
              Don't have an account?{' '}
              <StyledLink href={signUpUrl}>Sign up here</StyledLink>
            </Description>
          </ButtonContainer>
        </ContentWrapper>
      </LeftSection>
      <RightSection>
        <img
          src="/homepage-screenshot.jpg"
          width={1000}
          height={'auto'}
          style={{ borderRadius: '20px' }}
        />
      </RightSection>
    </Wrapper>
  );
};
