import { FC, useState } from 'react';
import { Form, message, Steps } from 'antd';
import { UserRole } from '../../../models';
import { QualificationType } from '../../profile/qualification-list';
import {
  PersonalRegisterFormViewType,
  transformPersonalOrganizationInputData,
  transformPersonalUserInputData,
} from './adaptData';
import { createOrg } from '../../../client';
import { createUser } from '../../../client/user';
import { FormPageWrapper, VisibilityWrapper } from '../styled';
import { RoleSelectionPage } from '../RoleSelectionPage';
import { PersonalInfoPage } from '../PersonalInfoPage';
import { ProfessionalInfoPage } from '../ProfessionalPageInfo';
import { ReferralCodePage } from '../ReferralCodePage';
import { PrivacyPolicyPage } from '../PrivacyPolicyPage';
import { TermsAndConditionsPage } from '../TermsAndConditionsPage';
import { FinalPage } from '../FinalPage';

const basicStepItems = [
  { title: 'Privacy Agreement' },
  { title: 'T&Cs' },
  { title: 'Role Selection' },
  { title: 'Personal Info' },
  { title: 'Referral Code' },
];
const wholeStepItems = [
  { title: 'Privacy Agreement' },
  { title: 'T&Cs' },
  { title: 'Personal Info' },
  { title: 'Professional Info' },
  { title: 'Referral Code' },
];
interface Props {
  organizationId?: string | null;
  afterSubmit?: () => void;
  onExit: () => void;
}
export const PersonRegisterForm: FC<Props> = ({
  organizationId,
  afterSubmit,
  onExit,
}) => {
  const isRootUser = !organizationId;
  const [step, setStep] = useState<number>(0);
  const [showLastPage, setShowLastPage] = useState<boolean>(false);
  const [formStep, setFormStep] = useState<number>(0);
  const [stepItems, setStepItems] = useState(wholeStepItems);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const formRoles = Form.useWatch('roles', form) ?? [];
  const [qualifications, setQualifications] = useState<QualificationType[]>([
    { id: 0, value: '' },
  ]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const generateStepItemsByRole = (role: UserRole) => {
    const updatedStepItems =
      role === UserRole.DOCTOR ? wholeStepItems : basicStepItems;
    setStepItems(updatedStepItems);
  };
  const registerAsRootUser = async (data: PersonalRegisterFormViewType) => {
    const createOrgInputData = transformPersonalOrganizationInputData(data);
    const id = await createOrg(createOrgInputData);
    const createUserInputData = transformPersonalUserInputData({
      data,
      organizationId: id,
      qualifications,
      isAdmin: true,
    });
    await createUser(createUserInputData);
  };
  const registerAsInvitedUser = async (
    data: PersonalRegisterFormViewType,
    organizationId: string
  ) => {
    const createUserInputData = transformPersonalUserInputData({
      data,
      organizationId,
      qualifications,
    });
    await createUser(createUserInputData);
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    const data = form.getFieldsValue(true);
    if (!isRootUser) {
      registerAsInvitedUser(data, organizationId)
        .then(() => {
          setStep(5);
        })
        .catch(() => {
          messageApi.error(
            'Something went wrong, please resubmit the survey or refresh the page.'
          );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      registerAsRootUser(data)
        .then(() => {
          setStep(5);
        })
        .catch(() => {
          messageApi.error(
            'Something went wrong, please resubmit the survey or refresh the page.'
          );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };
  return (
    <FormPageWrapper>
      {contextHolder}
      <Steps current={step} items={stepItems} labelPlacement={'vertical'} />

      <Form layout={'vertical'} onFinish={handleSubmit} form={form}>
        <VisibilityWrapper isVisible={step === 0}>
          <PrivacyPolicyPage
            showBackButton={isRootUser}
            goNext={() => {
              setStep((currentStep) => currentStep + 1);
            }}
            goBack={onExit}
          />
        </VisibilityWrapper>
        <VisibilityWrapper isVisible={step === 1}>
          <TermsAndConditionsPage
            goNext={() => {
              setStep((currentStep) => currentStep + 1);
            }}
            goBack={() => {
              setStep((currentStep) => currentStep - 1);
            }}
          />
        </VisibilityWrapper>
        {/*<VisibilityWrapper isVisible={formStep === 2}>
          <RoleSelectionPage
            form={form}
            isRootUser={isRootUser}
            goNext={() => {
              setStep((currentStep) => currentStep + 1);
              setFormStep(3);
            }}
            goBack={() => {
              setStep((currentStep) => currentStep - 1);
              setFormStep(1);
            }}
            onRoleChange={generateStepItemsByRole}
          />
        </VisibilityWrapper>*/}
        <VisibilityWrapper isVisible={step === 2}>
          <PersonalInfoPage
            form={form}
            showExtraInfo
            goNext={() => {
              setStep((currentStep) => currentStep + 1);
            }}
            goBack={() => {
              setStep((currentStep) => currentStep - 1);
            }}
          />
        </VisibilityWrapper>
        <VisibilityWrapper isVisible={step === 3}>
          <ProfessionalInfoPage
            form={form}
            qualifications={qualifications}
            onQualificationChange={setQualifications}
            goNext={() => {
              setStep((currentStep) => currentStep + 1);
            }}
            goBack={() => {
              setStep((currentStep) => currentStep - 1);
            }}
          />
        </VisibilityWrapper>
        <VisibilityWrapper isVisible={step === 4}>
          <ReferralCodePage
            isLoading={isSubmitting}
            goNext={handleSubmit}
            goBack={() => {
              setStep((currentStep) => currentStep - 1);
            }}
          />
        </VisibilityWrapper>
        <VisibilityWrapper isVisible={step === 5}>
          <FinalPage goNext={afterSubmit} />
        </VisibilityWrapper>
      </Form>
    </FormPageWrapper>
  );
};
