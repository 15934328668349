import { FC, useEffect, useState } from 'react';
import {
  LetterGenerateJobStatus,
  PatientResponseType,
  SearchPatientsQueryDto,
} from '../../models';
import { Button, ConfigProvider, Table, TablePaginationConfig } from 'antd';
import styled from 'styled-components';
import { PatientTableSearch } from '../../components/patients/patient-table-search';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { CreateOrUpdatePatientModal } from '../../components/patients/create-or-update-patient-modal';
import { searchPatients } from '../../client';
import { format } from 'date-fns';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { getLetterStatusView } from '../../../utils';
import { CommonPageTitle } from '../../components/style/layout';

const Row = styled.tr`
  &.pointer-row {
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  display: flex;
  justify-content: space-between;
`;
const ButtonGroupWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

const StatusWrapper = styled.div<{ status: LetterGenerateJobStatus }>`
  display: inline-block;
  border-radius: 5px;
  padding: 0 5px;
  color: ${({ status }) => getLetterStatusView(status).fontColor};
  background-color: ${({ status }) =>
    getLetterStatusView(status).backgroundColor};
`;
const CustomTableWrapper = styled.div`
  flex: 1;
  padding: 24px;
  background-color: #f0f3f7;
  tbody {
    tr {
      background-color: white;
      border-radius: 8px;
      cursor: pointer;
    }
  }
`;

type PatientDataType = {
  id: string;
  name: string;
  latestUpdates: string;
  status: LetterGenerateJobStatus;
  referringGp: string;
  gpClinic: string;
  lettersCount: number;
};
export type PatientsTableDataType = Array<PatientDataType>;

const getGpNames = (names: string[]): string => {
  if (names.length) {
    const nameList = names.slice(0, 2).join(', ');
    return names.length > 2 ? `${nameList} ...` : nameList;
  }
  return 'N/A';
};
const adaptData = (res: PatientResponseType[]): PatientsTableDataType => {
  return res.map((item) => ({
    name: item.patient.firstName + ' ' + item.patient.lastName,
    latestUpdates: item.latestUpdatedAt,
    status: item.letterSummary.latestStatus ?? LetterGenerateJobStatus.NULL,
    referringGp: getGpNames(item.gpNames),
    gpClinic: item.gpClinics.length ? item.gpClinics.join(' ') : 'N/A',
    id: item.patient.id,
    lettersCount: item.letterSummary.count,
  }));
};

export const Patients: FC = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<PatientsTableDataType>();
  const [total, setTotal] = useState<number>(0);
  const [patientsResponse, setPatientsResponse] =
    useState<Array<PatientResponseType>>();
  const pageSize = 10;
  const [patientQuery, setPatientQuery] = useState<SearchPatientsQueryDto>({
    page: 1,
    limit: 10,
    sortBy: ['updatedAt', 'DESC'],
  });
  const reload = () => {
    searchPatients(patientQuery).then((res) => {
      setPatientsResponse(res.data);
      setTotal(res.count);
      setTableData(adaptData(res.data));
    });
  };

  useEffect(() => {
    reload();
  }, [patientQuery]);

  const [isCreatePatientModalOpen, setIsCreatePatientModalOpen] =
    useState<boolean>(false);
  /*const [isFileUploadModalOpen, setIsFileUploadModalOpen] =
    useState<boolean>(false);*/
  const handleSearch = (text: string): void => {
    if (patientsResponse) {
      setPatientQuery((pre) => ({ ...pre, patientFullName: text }));
    }
  };

  const basicColumns = [
    {
      title: <PatientTableSearch onSearch={handleSearch} />,
      dataIndex: 'name',
    },
    {
      title: 'Latest Updates',
      dataIndex: 'latestUpdates',
      sorter: true,
      render: (text: string) => format(new Date(text), 'dd/MM/yyyy'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: LetterGenerateJobStatus) => (
        <StatusWrapper status={status}>
          {getLetterStatusView(status).displayValue}
        </StatusWrapper>
      ),
      filters: [
        {
          text: getLetterStatusView(LetterGenerateJobStatus.PENDING)
            .displayValue,
          value: LetterGenerateJobStatus.PENDING,
        },
        {
          text: getLetterStatusView(LetterGenerateJobStatus.COMPLETED)
            .displayValue,
          value: LetterGenerateJobStatus.COMPLETED,
        },
        {
          text: getLetterStatusView(LetterGenerateJobStatus.FAILED)
            .displayValue,
          value: LetterGenerateJobStatus.FAILED,
        },
        {
          text: getLetterStatusView(LetterGenerateJobStatus.APPROVED)
            .displayValue,
          value: LetterGenerateJobStatus.APPROVED,
        },
        {
          text: getLetterStatusView(LetterGenerateJobStatus.FINISHED)
            .displayValue,
          value: LetterGenerateJobStatus.FINISHED,
        },
      ],
    },
    {
      title: 'Referring GP',
      dataIndex: 'referringGp',
    },
    {
      title: 'GP Clinic',
      dataIndex: 'gpClinic',
    },
    {
      title: 'Letters Count',
      dataIndex: 'lettersCount',
    },
  ];

  const components = {
    body: {
      row: Row,
    },
  };

  const handleRow = (record: PatientDataType) => ({
    onClick: () => {
      navigate(`/patient-detail/${record.id}`);
    },
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    const { current } = pagination;
    const { order } = sorter as unknown as SorterResult<any>;
    const { status } = filters;
    const formattedOrder = order === 'ascend' ? 'ASC' : 'DESC';
    const formatterStatus = status?.includes(LetterGenerateJobStatus.PENDING)
      ? [...status, LetterGenerateJobStatus.TRANSCRIBING]
      : status;
    setPatientQuery((pre) => ({
      ...pre,
      page: current || 1,
      sortBy: ['updatedAt', formattedOrder],
      letterStatus: formatterStatus as unknown as LetterGenerateJobStatus[],
    }));
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: { headerBg: '#F0F3F7' },
        },
      }}
    >
      <CommonPageTitle>
        <Title>
          Patients
          <ButtonGroupWrapper>
            {/*<Button icon={<CloudUploadOutlined />}>Import Patient</Button>*/}
            <Button
              type={'primary'}
              icon={<PlusOutlined />}
              onClick={() => {
                setIsCreatePatientModalOpen(true);
              }}
            >
              New Patient
            </Button>
          </ButtonGroupWrapper>
        </Title>
      </CommonPageTitle>
      <CustomTableWrapper>
        <Table
          dataSource={tableData}
          loading={false}
          columns={basicColumns}
          onRow={handleRow}
          rowKey={(record) => record.id}
          components={components}
          pagination={{
            defaultCurrent: 1,
            total,
            pageSize,
          }}
          onChange={(pagination, filters, sorter) => {
            handleTableChange(pagination, filters, sorter);
          }}
        />
      </CustomTableWrapper>
      <CreateOrUpdatePatientModal
        isOpen={isCreatePatientModalOpen}
        onClose={() => {
          setIsCreatePatientModalOpen(false);
        }}
        afterUpdated={reload}
        isUpdatePatient={false}
      />
      {/*<FileUploadModal
        isOpen={isFileUploadModalOpen}
        onClose={() => {
          setIsFileUploadModalOpen(false);
        }}
        afterDataUploaded={reload}
      />*/}
    </ConfigProvider>
  );
};
