import { ChangeEvent, FC, useMemo, useState } from 'react';
import {
  ButtonProps,
  Form,
  Input,
  message,
  Modal,
  Select,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
  AustraliaState,
  GeneralPractitionerInputType,
  GeneralPractitionerResponseType,
  GenerateLetterInputType,
  LetterType,
  PatientProfileType,
} from '../../../models';
import { generateLetterByPatientId } from '../../../client';
import { LetterGeneratorForm } from '../../common/letter-generator-form';

const Container = styled.div`
  display: flex;
  column-gap: 24px;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const StyledTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 8px;
  ${({ theme }) => theme.mediaQueries.small} {
    font-size: 1rem;
  }
`;

const FormWrapper = styled.div`
  overflow-y: auto;
`;

const NoteEditorWrapper = styled.div`
  flex: 1;
`;
const optionalKeys = ['addressLine2', 'phoneNumber'];
const defaultModalData: Partial<GenerateLetterInputType> = {
  specialistNote: '',
  createGpDto: {
    phoneNumber: '',
    firstName: '',
    lastName: '',
    clinicName: '',
    addressLine1: '',
    addressLine2: '',
    suburb: '',
    postCode: '',
    state: AustraliaState.VIC,
    email: '',
  },
  isSpecifyGp: false,
};
interface Props {
  data: PatientProfileType;
  isOpen: boolean;
  onClose: () => void;
  afterUpdated: () => void;
}
export const NotesUploadModal: FC<Props> = ({
  data,
  isOpen,
  onClose,
  afterUpdated,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const gpLetterPlaceHolder =
    'Input Tips\n' +
    'Include the following headings\n' +
    '- Patient Name\n' +
    '- GP Name\n' +
    '- Reason for referral\n' +
    '- Social History\n' +
    '- Past Medical History\n' +
    '- Medications\n' +
    '- Issues Discussed\n' +
    '- Investigations\n' +
    '- Assessment\n' +
    '- Plan';
  const referralLetterPlaceholder =
    'Input Tips\n' +
    'Include the following headings\n' +
    '- Reasons for referral\n' +
    '- Relevant medical history\n' +
    '- Allergies\n' +
    '- Current medications\n' +
    '- Previous adverse drug reactions\n';
  const letterGeneratorPromptTooltips =
    'Input Tips: please include the following headings,' +
    ' Patient Name, GP or Specialist Name, Reason for referral, Social History, Past Medical History, Medications, Issues Discussed, Investigations, Assessment and Plan';

  const [modalData, setModalData] =
    useState<Partial<GenerateLetterInputType>>(defaultModalData);
  const placeholder = useMemo(
    () =>
      modalData.letterType === 'GP_LETTER'
        ? gpLetterPlaceHolder
        : modalData.letterType === 'REFERRAL_LETTER'
        ? referralLetterPlaceholder
        : '',
    [modalData]
  );
  const [okButtonProps, setOkButtonProps] = useState<ButtonProps>();
  const [cancelButtonProps, setCancelButtonProps] = useState<ButtonProps>();
  const [isTextInputDisabled, setIsTextInputDisabled] =
    useState<boolean>(false);
  const checkValidation = (data: Partial<GenerateLetterInputType>): boolean => {
    if (!data.specialistNote) return false;
    if (!data.letterType) return false;
    if (!data.isSpecifyGp) {
      return true;
    }
    if (!data.createGpDto) return false;
    return Object.keys(data.createGpDto).every((key) => {
      const isValueValid =
        !!data.createGpDto &&
        data.createGpDto[key as keyof GeneralPractitionerInputType];
      return optionalKeys.includes(key) || !!isValueValid;
    });
  };

  const generateInput = (
    data: GenerateLetterInputType
  ): GenerateLetterInputType => {
    if (data.gpId) {
      return {
        letterType: data.letterType,
        specialistNote: data.specialistNote,
        gpId: data.gpId,
        isSpecifyGp: data.isSpecifyGp,
      };
    } else {
      return {
        letterType: data.letterType,
        specialistNote: data.specialistNote,
        createGpDto: data.createGpDto,
        isSpecifyGp: data.isSpecifyGp,
      };
    }
  };
  const handleOk = async () => {
    if (checkValidation(modalData)) {
      setOkButtonProps({ loading: true });
      setCancelButtonProps({ disabled: true });
      setIsTextInputDisabled(true);
      const input = generateInput(modalData as GenerateLetterInputType);
      try {
        await generateLetterByPatientId(input, data.id);
        afterUpdated();
        setModalData(defaultModalData);
      } catch {
        messageApi.open({
          type: 'error',
          content:
            'Somthing wrong, please try to generate the letter again or refresh the page',
        });
      } finally {
        setOkButtonProps({ loading: false });
        setCancelButtonProps({ disabled: false });
        setIsTextInputDisabled(false);
      }
    } else {
      messageApi.open({
        type: 'error',
        content: 'Please finish input all the information',
      });
    }
  };
  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setModalData({ ...modalData, specialistNote: e.target.value });
  };
  const handleGpDataChange = (gpData: GeneralPractitionerResponseType) => {
    const updatedData: Partial<GenerateLetterInputType> = {
      ...modalData,
      gpId: gpData.id,
      createGpDto: {
        firstName: gpData.firstName,
        lastName: gpData.lastName,
        addressLine1: gpData.addressLine1,
        addressLine2: gpData.addressLine2,
        state: gpData.state,
        suburb: gpData.suburb,
        postCode: gpData.postCode,
        clinicName: gpData.clinicName,
        email: gpData.email,
        phoneNumber: gpData.phoneNumber,
      },
    };
    setModalData(updatedData);
  };

  const handleSpecifyGpChange = (isSpecifyGp: boolean) =>
    setModalData({
      ...modalData,
      isSpecifyGp,
    });
  const handleLetterTypeSelect = (letterType: LetterType) => {
    setModalData({ ...modalData, letterType });
  };
  return (
    <Modal
      open={isOpen}
      onOk={handleOk}
      onCancel={onClose}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      okText={'Generate'}
      closable={false}
      keyboard={false}
      maskClosable={false}
      width={1000}
      centered
      destroyOnClose
    >
      {contextHolder}
      <TitleWrapper>
        <StyledTitle>Upload notes</StyledTitle>
        <Tooltip title={letterGeneratorPromptTooltips}>
          <InfoCircleOutlined />
        </Tooltip>
      </TitleWrapper>
      <Container>
        <NoteEditorWrapper>
          <Form.Item label={'Letter type'} required>
            <Select onSelect={handleLetterTypeSelect}>
              <Select.Option value="GP_LETTER">
                Letter to Referrer
              </Select.Option>
              <Select.Option value="REFERRAL_LETTER">
                Referral letter
              </Select.Option>
            </Select>
          </Form.Item>
          <Input.TextArea
            disabled={isTextInputDisabled}
            rows={8}
            placeholder={placeholder}
            onChange={handleNoteChange}
            style={{ height: 736, resize: 'none' }}
          ></Input.TextArea>
        </NoteEditorWrapper>

        <FormWrapper>
          <LetterGeneratorForm
            onChange={handleGpDataChange}
            handleSpecifyGpChange={handleSpecifyGpChange}
          />
        </FormWrapper>
      </Container>
    </Modal>
  );
};
