import { format } from 'date-fns';
import { LetterGenerateJobStatus, LetterType } from '../telecare/models';

export const simpleDateStrFormat = (dateStr: string): string => {
  const year = parseInt(dateStr.slice(0, 4), 10);
  const month = parseInt(dateStr.slice(4, 6), 10);
  const day = parseInt(dateStr.slice(6, 8), 10);
  return `${day}-${month}-${year}`;
};
export const dateFormat = (dateStr: string, needTime = false): string => {
  const formatSchema = needTime ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy';
  const date = new Date(dateStr);
  return format(date, formatSchema);
};

export const isAllFieldsFilled = (object: Record<string, unknown>): boolean => {
  return Object.keys(object).every((key) => {
    return (
      object[key] !== null && object[key] !== undefined && object[key] !== ''
    );
  });
};

export type StatusProps = {
  backgroundColor: string;
  displayValue: string;
  fontColor: string;
};
export const getLetterStatusView = (
  status: LetterGenerateJobStatus
): StatusProps => {
  switch (status) {
    case LetterGenerateJobStatus.NULL:
      return {
        backgroundColor: 'unset',
        displayValue: 'N/A',
        fontColor: '#171d4d',
      };
    case LetterGenerateJobStatus.PENDING:
      return {
        backgroundColor: '#FF8A2C',
        displayValue: 'PENDING',
        fontColor: 'white',
      };
    case LetterGenerateJobStatus.TRANSCRIBING:
      return {
        backgroundColor: '#FF8A2C',
        displayValue: 'PENDING',
        fontColor: 'white',
      };
    case LetterGenerateJobStatus.FAILED:
      return {
        backgroundColor: '#ff4d4f',
        displayValue: 'FAILED',
        fontColor: 'white',
      };
    case LetterGenerateJobStatus.COMPLETED:
      return {
        backgroundColor: '#0552F0',
        displayValue: 'REVIEW',
        fontColor: 'white',
      };
    case LetterGenerateJobStatus.APPROVED:
      return {
        backgroundColor: '#18AA00',
        displayValue: 'APPROVED',
        fontColor: 'white',
      };
    case LetterGenerateJobStatus.FINISHED:
      return {
        backgroundColor: '#006400',
        displayValue: 'FINALISED',
        fontColor: 'white',
      };
    default:
      return {
        backgroundColor: 'unset',
        displayValue: 'N/A',
        fontColor: '#171d4d',
      };
  }
};

export const getLetterTypeView = (letterType: LetterType): StatusProps => {
  switch (letterType) {
    case 'GP_LETTER':
      return {
        backgroundColor: '#BEB8EB',
        fontColor: 'white',
        displayValue: 'Letter to referrer',
      };
    case 'REFERRAL_LETTER':
      return {
        backgroundColor: '#06D6A0',
        fontColor: 'white',
        displayValue: 'Referral Letter',
      };
    default:
      return {
        fontColor: '#171d4d',
        backgroundColor: 'white',
        displayValue: '',
      };
  }
};
