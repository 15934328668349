import { FC, useEffect, useMemo, useState } from 'react';
import { FeedbackDataType, FeedbackResponseType } from '../../models';
import { getAllFeedbacks } from '../../client';
import styled from 'styled-components';
import { Divider, Menu, MenuProps, Pagination, Rate } from 'antd';

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  display: flex;
  column-gap: 24px;
  padding-top: 16px;
`;
const FeedbackListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 256px;
  height: calc(100vh - 80px);
`;
const LetterDetailsWrapper = styled.div`
  max-width: 800px;
  width: 800px;
`;
const ConversationListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;
const ConversationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const FeedbackOverview: FC = () => {
  const [feedbackResponse, setFeedbackResponse] =
    useState<FeedbackResponseType | null>(null);
  const [currentFeedback, setCurrentFeedback] =
    useState<FeedbackDataType | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getAllFeedbacks({ page: 1, limit: 15 })
      .then((data) => {
        setFeedbackResponse(data);
        setCurrentFeedback(data.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const feedbackItems = useMemo(() => {
    const feedbackData = feedbackResponse?.data;
    return feedbackData
      ? feedbackData.map((feedback) => ({
          label: feedback.name,
          key: feedback.id,
        }))
      : [];
  }, [feedbackResponse]);
  const handleFeedbackSelect: MenuProps['onClick'] = ({ key }) => {
    const targetFeedback = feedbackResponse?.data.find(
      (item) => item.id === key
    );
    targetFeedback && setCurrentFeedback(targetFeedback);
  };
  const handlePaginationChange = (page: number) => {
    getAllFeedbacks({ page, limit: 15 })
      .then((data) => {
        setFeedbackResponse(data);
        setCurrentPage(page);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <Wrapper>
      <FeedbackListWrapper>
        <h3>Feedback list</h3>
        <Menu
          style={{ marginBottom: '16px', flex: 1 }}
          mode={'inline'}
          defaultSelectedKeys={['personal-information']}
          items={feedbackItems}
          onClick={handleFeedbackSelect}
        />
        <Pagination
          style={{ paddingBottom: '24px' }}
          size={'small'}
          current={currentPage}
          total={feedbackResponse?.count ?? 0}
          pageSize={15}
          showSizeChanger={false}
          simple
          hideOnSinglePage
          onChange={handlePaginationChange}
        />
      </FeedbackListWrapper>
      <LetterDetailsWrapper>
        <h3>Specialist Note</h3>
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {currentFeedback?.specialistNote}
        </div>
        <Divider />
        <h3>Generated Letter</h3>
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {currentFeedback?.generatedLetter}
        </div>
      </LetterDetailsWrapper>
      <ConversationListWrapper>
        {currentFeedback && (
          <>
            {currentFeedback.feedbackItems.map((conversation, index) => (
              <ConversationWrapper key={index}>
                <h4>Q: {conversation.question}</h4>
                <span>A: {conversation.answer}</span>
              </ConversationWrapper>
            ))}
            <Rate value={currentFeedback?.rating || undefined} disabled />
            <div>doctor user Id: {currentFeedback.userId}</div>
          </>
        )}
      </ConversationListWrapper>
    </Wrapper>
  );
};
