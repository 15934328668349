import { FC } from 'react';
import { Header } from '../components/common/header';
import { Outlet, useNavigate } from 'react-router-dom';
import { Button, ConfigProvider } from 'antd';
import styled from 'styled-components';
import { useUserProfileContext } from '../providers/UserProfileProvider';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
`;
const Navigation = styled.div`
  width: 40px;
  padding: 16px;
  border-right: #dae6f2 1.5px solid;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: calc(100vw - 72px);
`;
const LinkWrapper = styled.div`
  font-size: 10px;
  padding: 24px;
`;

const LinkButton = styled(Button).attrs({ type: 'link', size: 'small' })`
  font-size: 10px !important;
`;

const AntdThemeToken = {
  fontFamily: 'Inter',
  colorInfo: '#0552F0',
  colorLink: '#0552F0',
  colorPrimary: '#0552F0',
  colorTextBase: '#171D4D',
};

export const Layout: FC = () => {
  const navigate = useNavigate();
  const { userProfile } = useUserProfileContext();

  return (
    <ConfigProvider
      theme={{
        token: AntdThemeToken,
      }}
    >
      <Wrapper>
        <Navigation>
          <img
            src="/AT_Logo_Only_Coloured.svg"
            width={40}
            alt="Telecare Logo"
          />
        </Navigation>
        <ContentWrapper>
          <Header />
          {userProfile && (
            <LinkWrapper>
              <LinkButton
                onClick={() => {
                  navigate('/patients');
                }}
              >
                HOME | AutoTranscribe
              </LinkButton>
            </LinkWrapper>
          )}
          <Outlet />
        </ContentWrapper>
      </Wrapper>
    </ConfigProvider>
  );
};
