import { client } from './client';
import { PATIENTS } from '../../const/apiPath';
import {
  GeneratePatientInputType,
  Pagination,
  PatientResponseType,
  PatientsResDto,
  SearchPatientsQueryDto,
} from '../models';

export const getPatients = async (): Promise<PatientsResDto> => {
  try {
    const { data } = await client.get(`${PATIENTS}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createPatient = async (data: GeneratePatientInputType) => {
  try {
    await client.post(`${PATIENTS}`, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updatePatient = async (
  data: GeneratePatientInputType,
  patientId: string
) => {
  try {
    await client.patch(`${PATIENTS}/${patientId}`, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const searchPatients = async (
  searchPatientsQueryDto: SearchPatientsQueryDto
): Promise<Pagination<PatientResponseType>> => {
  try {
    const { data } = await client.post(
      `${PATIENTS}/search`,
      searchPatientsQueryDto
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
