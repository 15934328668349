import { PmsUserResponse } from '../../models/pms-user.response';
import {
  CreateUserInput,
  PmsGender,
  UpdateUserInput,
  UserRole,
} from '../../models';
import { QualificationType } from '../../components/profile/qualification-list';
import { Speciality } from '../../../const/view';

export type ProfilePageFormViewType = {
  firstName: string;
  lastName: string;
  gender: PmsGender;
  mobileNumber: string;
  //move email here
  email: string;
  organizationId: string;
  roles: UserRole[];
  referralCode?: string;
  title: string;
  qualifications: string[];
  speciality: Speciality;
  specialityDescription?: string;
  providerId?: string;
  reportTo?: string;
};
export type ProfilePageViewType = Omit<
  ProfilePageFormViewType,
  'gender' | 'qualifications'
> & {
  gender?: PmsGender;
  qualifications: QualificationType[];
};

const getQualifications = (
  qualifications?: Array<string>
): QualificationType[] => {
  if (qualifications && qualifications.length > 0) {
    return qualifications.map((qualification, index) => ({
      id: index,
      value: qualification,
    }));
  } else {
    return [{ id: 0, value: '' }];
  }
};
const getSpeciality = (data: PmsUserResponse | null): Speciality => {
  const speciality = data?.doctorProfile?.speciality;
  if (speciality) {
    return Object.values(Speciality).includes(speciality as Speciality)
      ? (data?.doctorProfile?.speciality as Speciality)
      : Speciality.OTHER;
  }
  return Speciality.OTHER;
};
export const adaptData = (
  data: PmsUserResponse | null
): ProfilePageViewType => {
  const viewData: ProfilePageViewType = {
    organizationId: data?.id ?? '',
    lastName: data?.lastName ?? '',
    firstName: data?.firstName ?? '',
    gender: data?.gender,
    mobileNumber: data?.mobileNumber ?? '',
    email: data?.email ?? '',
    roles: data?.roles ?? [],
    title: data?.doctorProfile?.title ?? '',
    speciality: getSpeciality(data),
    providerId: data?.doctorProfile?.providerId,
    qualifications: getQualifications(data?.doctorProfile?.qualifications),
  };
  if (viewData.speciality === Speciality.OTHER) {
    viewData.specialityDescription = data?.doctorProfile?.speciality;
  }
  viewData.qualifications.forEach(({ id, value }) => {
    Object.defineProperty(viewData, `qualification${id}`, { value });
  });
  return viewData;
};

export const transformQualificationsData = (
  qualifications: QualificationType[]
): string[] => {
  const validQualifications = qualifications.filter(
    (qualification) => !!qualification.value
  );
  return validQualifications.map((qualification) => qualification.value);
};
export const transformCreateUserInputData = (
  data: ProfilePageFormViewType,
  qualification: QualificationType[]
): CreateUserInput => {
  const inputData: Partial<CreateUserInput> = transformUpdateUserInputData(
    data,
    qualification
  );
  inputData.organizationId = data.organizationId;
  inputData.roles = data.roles;
  !!data.referralCode && (inputData.referralCode = data.referralCode);
  return inputData as CreateUserInput;
};
const transformSpeclialityInputData = (
  data: ProfilePageFormViewType
): string => {
  if (data.speciality !== Speciality.OTHER) {
    return data.speciality;
  }
  return data.specialityDescription ?? '';
};

export const transformUpdateUserInputData = (
  data: ProfilePageFormViewType,
  qualification: QualificationType[]
): UpdateUserInput => {
  const inputData: UpdateUserInput = {
    firstName: data.firstName,
    lastName: data.lastName,
    gender: data.gender,
    mobileNumber: data.mobileNumber,
    email: data.email,
  };
  if (data.roles.includes(UserRole.DOCTOR)) {
    inputData.doctor = {
      title: data.title,
      speciality: transformSpeclialityInputData(data),
      providerId: data.providerId,
      qualifications: transformQualificationsData(qualification),
    };
  }
  if (data.roles.includes(UserRole.ADMIN)) {
    inputData.admin = { reportTo: '' };
  }
  return inputData;
};
