import { FC } from 'react';
import { useTokenBalanceContext } from '../../../providers/TokenBalanceProvider';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Statistic } from 'antd';
import { useUserProfileContext } from '../../../providers/UserProfileProvider';

const Wrapper = styled.div`
  border-radius: 12px;
  padding: 4px 18px 4px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f0f3f7;
  }
`;
const IconImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;
export const BalanceWidget: FC = () => {
  const navigate = useNavigate();
  const { userProfile } = useUserProfileContext();
  const {
    balanceToken: { totalBalance },
  } = useTokenBalanceContext();
  const handleClick = () => {
    navigate('/balance');
  };
  return userProfile ? (
    <Wrapper onClick={handleClick}>
      <IconImage src={'/tokenIcon.svg'} />
      <Statistic value={totalBalance} />
    </Wrapper>
  ) : (
    <></>
  );
};
