import { FC } from 'react';
import styled from 'styled-components';
import { Card, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useLoaderData } from 'react-router-dom';
import { ReferralDetailResponseType } from '../../models';
import { ReferralCodeGuide } from '../../components/reward/referral-code-guide';

const Wrapper = styled.div`
  padding: 0 24px;
`;
const RewardCardsWrapper = styled.div`
  display: flex;
  column-gap: 24px;
  margin-bottom: 24px;
`;
const StyledCard = styled(Card)`
  flex: 1;
  text-align: center;
`;
const CardContentText = styled.div`
  font-size: 24px;
  font-weight: bolder;
  margin-bottom: 12px;
`;
const CopyIcon = styled(CopyOutlined)`
  cursor: pointer;
  margin-top: 12px;
`;
const DisclaimerWrapper = styled.div`
  margin-top: 20px;
`;
export const Reward: FC = () => {
  const { referralCode, referralCount, rewardBalance } =
    useLoaderData() as ReferralDetailResponseType;

  const [messageApi, contextHolder] = message.useMessage();
  const handleCopyClick = () => {
    navigator.clipboard.writeText(referralCode);
    messageApi.open({
      type: 'success',
      content: 'Copy succeed.',
    });
  };
  return (
    <Wrapper>
      {contextHolder}
      <RewardCardsWrapper>
        <StyledCard>
          <h3>My referral code</h3>
          <CardContentText>{referralCode}</CardContentText>
          <CopyIcon onClick={handleCopyClick} />
        </StyledCard>
        <StyledCard>
          <h3>My rewards</h3>
          <CardContentText>{rewardBalance}</CardContentText>
        </StyledCard>
        <StyledCard>
          <h3>Referrals made</h3>
          <CardContentText>{referralCount}</CardContentText>
        </StyledCard>
      </RewardCardsWrapper>
      <Card>
        <ReferralCodeGuide />
      </Card>
      <DisclaimerWrapper>
        <strong>Disclaimer:</strong> Telecare reserves the right to modify,
        suspend, or terminate the rewards program at any time and for any reason
        without prior notice. We may also adjust the terms and conditions of the
        program, inlcuding reward amounts and eligibility criteria, as deemed
        necessary.
      </DisclaimerWrapper>
    </Wrapper>
  );
};
